import React from 'react';
import ReactTooltip from "react-tooltip";
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { cloneDeep } from 'lodash';
import { isDiagramNode, defaultComposition, defaultAssocEntity, sortNodesByType, serializeEntityData } from '../util';
import { filterDataList } from '../../../util/util.js';
import { BasicConfirm } from '../../../dialog/BasicConfirm';

import {
    DiagramNodeModel,
    DiagramNodeWidget,
    CompositionPortModel,

    NodeContainer,
    NodeHalo,
    NodeHeader,
    NodeInput,
    NodeOuterPorts,
    AttributeTable,
    AttributeRow,
    AttributeHeaderRow,
    AttributeCell,
    AttributeCellFixedSize,
    TopToolBar,
    AssociationError,
    SelectionOverlay,

    Hexagon,
    HexagonRight,
    HexagonLeft,
    DropZone,

    EntityAttribute,
} from '../index';




/*
    FORMAT OF AN ENTITY NODE:
    nodeData: {
        name: string,
        description: string,
        xmiType: string,
        parent: string,
        specializedBy: array,
        specializes: string,
        diagramId: string || null,
        diagramNodeLoaded: boolean,
        isUncommitted: boolean,
        children: [
            {
                rolename: string,
                description: string,
                xmiType: string,
                type: string,
                specializes: string || null,
                pathPairs: null || [
                    {

                    }
                ],
                path: string || null,
                lowerBound: string (represented as "1"),
                upperBound: string (represented as "1"),
                sourceLowerBound: string (represented as "1"),
                sourceUpperBound: string (represented as "1"),
                diagramId: string,
                isUncommitted: boolean,
            }
        ]
    }

 */

export class EntityNodeFactory extends AbstractReactFactory {
    constructor() {
        super("entity-node");
    }

    generateModel(event) {
        return new EntityNodeModel();
    }

    generateReactWidget(event) {
        return <EntityNodeWidget engine={this.engine} node={event.model}/>;
    }
}


export class EntityNodeModel extends DiagramNodeModel {
    constructor(options = {}, $app) {
        super({
              ...options,
              type: "entity-node",
          }, $app);

        this.initializePorts();
        this.setSettings("isStencil", this.originalData.isStencil)
    }

    initializePorts() {
        this.addPort(new CompositionPortModel({
            name: "create-asso",
            in: false,
        }));
        this.addPort(new CompositionPortModel({
            name: "create-comp",
            in: false,
        }));
    }

    /*
     * Saving and Loading Data
     */
	  serialize() {
        // const nodeData = this.getNodeData();
        // const serializeData = serializeCompositionData()

        // const serializeData = {
        //     guid: nodeData.guid,
        //     name: nodeData.name,
        //     xmiType: nodeData.xmiType,
        //     description: nodeData.description,
        //     children: nodeData.children.map(child => ({
        //         guid: child.guid,
        //         rolename: child.rolename,
        //         xmiType: child.xmiType,
        //         description: child.description,
        //         type: child.type,
        //         sourceLowerBound: child.sourceLowerBound,
        //         sourceUpperBound: child.sourceUpperBound,
        //         lowerBound: child.lowerBound,
        //         upperBound: child.upperBound,
        //         parent: nodeData.guid,
        //         tag: child.tag,
        //     })),
        // }

        return {
            ...super.serialize(),
            nodeData: serializeEntityData(this.getNodeData()),
            inPortMap: this.inPortMap,
            outPortMap: this.outPortMap,
        };
    }

    deserialize(event) {
        // reassign $app
        this.$app = event.engine.$app;
        const guid = event.data.nodeData.guid;
        let nodeData = this.$app.getOptionNode(guid);

        this.options.nodeData = nodeData || event.data.nodeData;
        this.originalData = cloneDeep(nodeData || event.data.nodeData);
        this.inPortMap = event.data.inPortMap;
        this.outPortMap = event.data.outPortMap;

        super.deserialize(event);
    }

    // override
    forceWidgetToUpdate = (refreshOnly=true) => {
        if(refreshOnly) {
            this.$widget.forceUpdate();
        } else {
            this.sortChildrenByType();
            this.validateAsso();
            this.markUncommittedStatus();
            this.$widget.forceUpdate();
        }
    }

    // override
    sortChildrenByType = () => {
      this.getNodeData().children.sort((node1, node2) => {
        if (node1.xmiType !== node2.xmiType) {
          if (node1.xmiType === "conceptual:Composition") return -1;
          if (node1.xmiType !== "conceptual:Composition") return 1;
        } else {
          if (!node1.specializes && node2.specializes) return -1;
          if (node1.specializes && !node2.specializes) return 1;
        }
        return 0;
      });
    }

    validateAsso = () => {
        const countAE = this.countAE();
        const nodeData = this.getNodeData();
        let xmiType = nodeData.xmiType;

        // set AE error for stencil nodes
        if (this.settings.isStencil === true && nodeData.xmiType === "conceptual:Association") {
            this.updateProp("aeError", countAE < 2);
            return;
        }

        // change xmi type to entity or association by AE count
        if (countAE > 0 && xmiType === "conceptual:Entity") {
            xmiType = "conceptual:Association";
            this.updateProp("xmiType", "conceptual:Association");
            
        } else if (countAE < 1 && xmiType === "conceptual:Association") {
            xmiType = "conceptual:Entity";
            this.updateProp("xmiType", "conceptual:Entity");
        }

        // set AE error for non-stencil nodes
        if (xmiType === "conceptual:Association") {
            this.updateProp("aeError", countAE < 2);
        }
    };

    countAE = () => {
        const children = this.getNodeData().children;
        return children.reduce((total, child) => (child.xmiType === "conceptual:AssociatedEntity" && !child.deleteMe && !child.deprecateMe && child.deprecated !== "true" ? total + 1 : total), 0);
    }
}




class EntityNodeWidget extends DiagramNodeWidget {
    constructor(props) {
        super(props);
        this.state.hideType = false;
        this.placeholder = this.createAttrNode("", true);
        this.editNameOnMount = isDiagramNode(this.nodeModel.getNodeData().guid);
        this.typeOptions = Object.values(this.$app.typeOptions).sort(sortNodesByType);

        if(this.nodeModel.getNodeData().xmiType === "conceptual:Association") {
          this.nodeModel.updateProp("aeError", this.nodeModel.countAE() < 2)
        }

        // this.compositions = [];
        // this.specializes = [];
        // this.assocEntities = [];
        // this.hidden = [];

        this.nodeModel.sortChildrenByType();

        this.nodeModel.registerListener({
            selectionChanged: (e) => {
                if(this.widgetRef !== undefined) {
                    if(e.isSelected) {
                        this.widgetRef.parentElement.style.zIndex = "1";
                    } else {
                        this.widgetRef.parentElement.style.zIndex = "0";
                    }
                }
            }
        });
    }

    // override
    componentDidUpdate(prevProps, prevState) {
        if(prevState.isEditing !== this.state.isEditing) {
            ReactTooltip.hide();
        }

        if(this.typeOptions.length !== Object.keys(this.$app.typeOptions).length) {
            this.typeOptions = Object.values(this.$app.typeOptions).sort(sortNodesByType);
            // this.specializeOptions = this.typeOptions.filter(t => t.xmiType !== "conceptual:Observable");
        }

        // used to resize Association Hexagon shape
        if(this.domHeight !== this.widgetRef.offsetHeight) {
          this.domHeight = this.widgetRef.offsetHeight;
          this.forceUpdate();
        }

        if(this.editNameOnMount && this.nameRef) {
            this.nameRef.focus();
            this.nameRef.select();
            this.editNameOnMount = false;
        }
    }

    // This is triggered when exiting Edit Mode
    // when creating a new attr, it will stay on the bottom of the list until this is triggered
    // sortChildren = () => {
    //     const comps = [];
    //     const specializes = [];
    //     const ae = [];
    //     const hidden = [];

    //     this.nodeModel.getNodeData().children.forEach(child => {
    //         if(this.state.hideAttr.has(child.guid)) {
    //             hidden.push(child);
    //         }

    //         if(child.xmiType === "conceptual:AssociatedEntity") {
    //             ae.push( child );

    //         } else if(!!child.specializes) {
    //             specializes.push( child );

    //         } else {
    //             comps.push( child );
    //         }
    //     })

    //     this.compositions = comps;
    //     this.specializes = specializes;
    //     this.assocEntities = ae;
    //     this.hidden = hidden;
    // }




    /*
     * Actions
     */
    createAttrNode = (rolename="", isPlaceholder=false) => {
        const node = cloneDeep(defaultComposition);
            node.guid = this.$app.props.manager.createDiagramId();
            node.rolename = rolename;
            node.parent = this.nodeModel.getNodeData().guid;
            node.isPlaceholder = isPlaceholder;

        return node;
    }

    createNewComposition = (dstData) => {
        let newName = "";

        if(dstData) {
            newName = dstData.name[0].toLowerCase() + dstData.name.substring(1);
            let countDupName = this.nodeModel.getNodeData().children.reduce((total, curr) => curr.rolename && curr.rolename.startsWith(newName) ? total + 1 : total, 0);
            if(countDupName > 0) newName = newName + "_" + countDupName;
        }

        const srcData = this.createAttrNode(newName);
              srcData.type = dstData ? dstData.guid : "";

        this.nodeModel.getNodeData().children.push(srcData);
        this.forceUpdate();
        return srcData;
    }

    createNewAssociatedEntity = (dstData, addToChildren=true) => {
        let newName = "";

        if(dstData) {
            newName = dstData.name[0].toLowerCase() + dstData.name.substring(1);
            let countDupName = this.nodeModel.getNodeData().children.reduce((total, curr) => curr.rolename && curr.rolename.startsWith(newName) ? total + 1 : total, 0);
            if(countDupName > 0) newName = newName + "_" + countDupName;
        }

        const srcData = cloneDeep(defaultAssocEntity);
            srcData.guid = this.$app.props.manager.createDiagramId();
            srcData.rolename = newName;
            srcData.parent = this.nodeModel.getNodeData().guid;
            srcData.type = dstData ? dstData.guid : "";

        if(addToChildren) {
            this.nodeModel.getNodeData().children.push(srcData);
            this.forceUpdate();
        }
        return srcData;
    }

    createAttrFromTree = (types=[]) => {
        types.forEach(typeNode => {
            this.createNewComposition(typeNode);
        })

        this.nodeModel.sortChildrenByType();
        this.forceUpdate();
    }

    openAssociationModal = (attr, callback) => {
        const parentTypeGuid = typeof attr.type === "string" ? attr.type : attr.type.guid;
        const parentType = this.$app.getOptionNode(parentTypeGuid);

        // Clear selection - while modal is displayed, the delete key will try to delete the node.
        this.$app.engine.getModel().clearSelection();

        this.$app.props.manager.assoModalRef.show(attr, parentType, (newChildData) => {
            Object.entries(newChildData).forEach(([key, val]) => {
                this.updateAttr(attr, key, val);
            })

            if(callback) {
                callback();
            } else {
                this.nodeModel.markUncommittedStatus();
                this.nodeModel.validateAsso();
                this.nodeModel.forceNodeToUpdateByMoving();
            }
        });
    }

    dragStart = (toolType) => {
        // move svg layer behind model layer (css)
        this.$app.domModelLayerOnTop();

        const cleanUp = (e) => {
            window.removeEventListener("mouseup", cleanUp);
            this.$app.domSvgLayerOnTop();

            const entities = document.querySelectorAll('div[data-type="entity-node"]');
            for (const entity of entities) {
                entity.classList.remove("tool-drag");
                entity.removeEventListener("mouseup", createCompLink, false);
                entity.removeEventListener("mouseup", cleanUp, false);
            }
        }

        const createCompLink = (e) => {
            const placement = e.target.dataset.placement;
            const target = this.props.engine.getMouseElement(e);
            const dstNode = target.ports ? target : target.parent;  // possible to retrieve a port element
            const dstData = dstNode.getNodeData();

            if(toolType === "newAE") {
                var srcData = this.createNewAssociatedEntity(dstData, false);
            } else if(toolType === "newComp") {
                var srcData = this.createNewComposition(dstData);
            }

            const establishLink = () => {
                this.nodeModel.establishLink(srcData, dstNode, placement);
                this.nodeModel.markUncommittedStatus();
                this.nodeModel.validateAsso();
                this.nodeModel.sortChildrenByType();
                this.forceUpdate();
            }

            if(srcData.xmiType === "conceptual:AssociatedEntity") {
                // add to children if user select confirm
                this.openAssociationModal(srcData, () => {
                    this.nodeModel.getNodeData().children.push(srcData);
                    establishLink();
                });

            // establish link for compositions
            } else {
                establishLink();
            }
        };

        window.addEventListener("mouseup", cleanUp);

        const entities = document.querySelectorAll('div[data-type="entity-node"]');
        for (const entity of entities) {
            // prevent node from pointing to itself
            if(entity.parentElement.dataset.nodeid === this.nodeModel.options.id) continue;
            // const dropzone = entity.querySelector('.drop-zone');
            entity.classList.add("tool-drag");
            entity.addEventListener("mouseup", createCompLink);
            entity.addEventListener("mouseup", cleanUp);
        }
    }



    // Children actions
    actionUpdateChildName = (e, child) => {
        this.updateAttr(child, "rolename", e.target.value);

        if(e.target.value) {
            this.typeOptions = this.typeOptions.slice().sort((node1, node2) => filterDataList(node1, node2, e.target.value));
        } else {
            this.typeOptions = this.typeOptions.slice().sort(sortNodesByType);
        }
    }

    actionUpdateType = (typeGuid, child) => {
        const typeNode =  this.$app.getOptionNode(typeGuid);

        if(!child.isPlaceholder) {
            const outPorts = Object.keys(this.nodeModel.getOutPorts()).filter(key => key.startsWith(child.guid));
            const inPorts = Object.keys(this.nodeModel.getInPorts()).filter(key => key.startsWith(child.guid));

            [outPorts, inPorts].forEach(ports => {
                ports.forEach(portGuid => {
                    this.nodeModel.removeDiagramLink(portGuid);
                })
            });
        }

        if(!child.rolename){
            let newName = typeNode.name[0].toLowerCase() + typeNode.name.substring(1);
            let countDupName = this.nodeModel.getNodeData().children.reduce((total, curr) => curr.rolename && curr.rolename.startsWith(newName) ? total + 1 : total, 0);
            if(countDupName > 0) newName = newName + "_" + countDupName;
            child["rolename"] = newName;
        }

        this.updateAttr(child, "type", typeGuid);
    }

    actionSelectProjChar = (e) => {
        e.preventDefault();

        this.$app.state.modeAction === "select-a-projChar" &&
            this.$app.selectProjChar(this.nodeModel.getNodeData().guid);
    }

    actionLockNode = () => {
        this.nodeModel.setLocked(true);
    }

    actionUnlockNode = () => {
        this.nodeModel.setLocked(false);
    }

    renderChildren = () => {
      const nodeData = this.nodeModel.getNodeData();
      const { hiddenAttributeGuids, showHiddenAttributes, showCompositionType } = this.nodeModel.getSettings();
      const { isEditing, hideAttr } = this.state;
      const appState = this.$app.state;
      const selectingHop = appState.selectingHop;

      const threeDots = !hiddenAttributeGuids.size ? null :
                            <AttributeRow node={{}}
                                          style={{cursor:"pointer", paddingLeft:5, zIndex:1}}
                                          onClick={() => this.nodeModel.toggleShowHiddenAttributes()}>
                                              ...</AttributeRow>;

      if(isEditing) {
        var attrList = [nodeData.children, [this.placeholder]];
      } else {
        var attrList = [nodeData.children, ["threeDots"], nodeData.children];
      }

      return attrList.map((items, idx) => (
        items.map((c, jdx) => {
          if(c === "threeDots") return threeDots;

          // Not edit mode and attribute is hidden:
          if (!isEditing) {
            if ((idx === 0 && hiddenAttributeGuids.has(c.guid)) ||                                // idx 0 (main section) and attr is hidden, then return null
                (idx === 2 && (!showHiddenAttributes || !hiddenAttributeGuids.has(c.guid)))) {    // idx 2 (hidden section) and attr is not hidden, then return null
                  return null;
            }
          }

          // Not edit mode and attribute is not hidden:
          // if (!isEditing && idx === 2 && !hiddenAttrGuids.has(c.guid) && !showHiddenAttrs) return null;
          

          // Hide the node in non-edit mode
          // if(!isEditing && hideAttr.has(c.guid) && idx !== 4) return null;
          // if(idx === 4 && !showHiddenAttrs) return null;

          const typeNode = this.$app.getOptionNode(c.type) || {};
          const isUncommitted = appState.showUncommitted && c.isUncommitted;
          const hasOutPort = appState.mode === "modelling" && c.guid in this.nodeModel.getOutPorts();

          let dataTypedFor = c.xmiType === "conceptual:AssociatedEntity" ? this.$app.getAeEffectiveType(c) : "";
          let isSelectable = nodeData.guid === selectingHop || (c.xmiType === "conceptual:AssociatedEntity" && dataTypedFor === selectingHop);
          let domId = c.isPlaceholder ? "aecomp-placeholder" : 
                            idx === 2 ? `aecomp-hidden-${jdx}` : `aecomp-${jdx}`;

          return <EntityAttribute key={`entity-node-${c.guid}`}
                                  id={this.phenomId.genPageId(domId)}
                                  attr={c}
                                  $app={this.$app}
                                  typeNode={typeNode}
                                  typeOptions={this.typeOptions}
                                  parentData={nodeData}
                                  parentModel={this.nodeModel}
                                  hasOutPort={hasOutPort}
                                  isEditing={isEditing}
                                  isHidden={hiddenAttributeGuids.has(c.guid)}
                                  isTypeHidden={!showCompositionType}
                                  isUncommitted={isUncommitted}
                                  isSelectable={isSelectable}
                                  isDeletable={!c.specializes && !c.isPlaceholder}
                                  actionToggle={this.nodeModel.toggleHiddenAttributeGuid}
                                  actionUpdateChildName={this.actionUpdateChildName}
                                  actionToggleEditing={this.toggleEditMode}
                                  actionUpdateType={this.actionUpdateType}
                                  actionExpandType={this.expandTypeNode}
                                  ref={el => this.childrenRef[c.guid] = el} />
        })
      ))
    }

    render() {
        const nodeData = this.nodeModel.getNodeData();
        const { hiddenAttributeGuids, showCompositionType } = this.nodeModel.getSettings();
        const appState = this.$app.state;
        const isUncommitted = appState.showUncommitted && nodeData.isUncommitted;
        const isNewNode = appState.showUncommitted && isDiagramNode(nodeData.guid);

        const showOverlay = appState.modeAction === "select-a-projChar" || appState.modeAction === "select-a-hop";
        const selectableOverlay = appState.modeAction === "select-a-projChar";

        return (<>
            <NodeContainer id={this.phenomId.genPageId("-entity-container")}
                            className="entity-node"
                            xmiType={nodeData.xmiType}
                            data-id={nodeData.guid}
                            data-type="entity-node"
                            style={{width: this.nodeModel.width || null}}
                            onDoubleClick={this.handleDoubleClick}
                            ref={(el) => this.widgetRef = el}
                            onKeyPress={(e) => {
                                if(e.charCode === 13) this.toggleEditMode(false)
                            }}
                            onClick={(e) => {
                                if(this.nodeModel.isSelected()) {
                                    this.$app.sidePanelRef.current.load(this.nodeModel);
                                }      
                            }}>

                <DropZone data-drop-zone="entity-node" />

                <SelectionOverlay show={showOverlay}
                                  selectable={selectableOverlay}
                                  data-drop-zone="entity-node"
                                  onClick={this.actionSelectProjChar} />

                <NodeHalo selected={this.nodeModel.options.selected}
                          onMouseDown={this.actionUnlockNode} />

                <NodeHeader id={this.phenomId.genPageId("header")}
                            xmiType={nodeData.xmiType}
                            uncommitted={isNewNode}
                            data-tip={nodeData.description} data-for="diagramTip">
                        <AssociationError id={this.phenomId.genPageId("ae-error")} visible={nodeData.aeError} data-tip="Need at least two participants" data-for="errTip" />
                    {this.state.isEditing ?
                        <NodeInput ref={el => this.nameRef = el}
                                    type="text"
                                    style={{padding:"0 10px"}}
                                    id={this.phenomId.genPageId("name-input")}
                                    placeholder="Name"
                                    value={nodeData.name}
                                    autoComplete="off"
                                    onChange={((e) => {
                                                this.nodeModel.updateProp("name", e.target.value);
                                                this.forceUpdate();
                                            })}
                                    onFocus={this.actionLockNode} /> :
                        `${nodeData.name}`
                    }
                </NodeHeader>

                <TopToolBar visible={this.nodeModel.options.selected && !this.state.isEditing}>
                    <PortWidget engine={this.props.engine} port={this.nodeModel.getPort("create-asso")}>
                        <span id={this.phenomId.genPageId("drag-association")} className="k-icon k-i-sort-asc-sm" data-tip="Drag to Create Association" data-for="diagramTip"
                            onMouseDown={() => this.dragStart("newAE")}/>
                    </PortWidget>
                    <PortWidget engine={this.props.engine} port={this.nodeModel.getPort("create-comp")}>
                        <span id={this.phenomId.genPageId("drag-composition")} className="k-icon k-i-copy" data-tip="Drag to Create Composition" data-for="diagramTip"
                            onMouseDown={() => this.dragStart("newComp")}/>
                    </PortWidget>
                </TopToolBar>

                <AttributeTable xmiType={nodeData.xmiType}
                                uncommitted={isUncommitted}>
                    <AttributeHeaderRow hide={!this.state.isEditing}>
                        <AttributeCellFixedSize checkbox header>
                            <input id={this.phenomId.genPageId("edit-attrs-hide-all-toggle")}
                                   type="checkbox"
                                   tabIndex="-1"
                                   style={{margin:0}}
                                   checked={hiddenAttributeGuids.size === 0}
                                   onChange={() => this.nodeModel.toggleHiddenAttributeGuids()}/></AttributeCellFixedSize>

                            <AttributeCell header>Rolename</AttributeCell>

                            <AttributeCell header>
                                <input id={this.phenomId.genPageId("edit-types-hide-all-toggle")}
                                       type="checkbox"
                                       tabIndex="-1"
                                       style={{margin:0, marginRight:3}}
                                       checked={showCompositionType}
                                       onChange={() => this.nodeModel.toggleShowCompositionType()}/>
                                Type</AttributeCell>

                    </AttributeHeaderRow>

                    {this.renderChildren(nodeData.children)}
                </AttributeTable>

                <NodeOuterPorts>
                    {Object.entries(this.nodeModel.getOutPorts()).map(([portGuid, portName]) => {
                        const port = this.nodeModel.getPort(portName);
                        if(!port) return null;

                        const isPathPort = port.options.type === 'path-port';
                        const compGuid = portGuid.split("--")[0];
                        const leftSide = !port.options.in && port.options.pathData && port.options.pathData.goingUp;

                        const { absX, absY, pathX, pathY } = this.getNewPortPosition(port, {searchChildGuid: compGuid, displayOnLeftSide: leftSide});

                        if(isPathPort && pathX && pathY) {
                            port.setPosition(pathX, pathY);
                        }

                        return (
                            <PortWidget engine={this.props.engine}
                                    key={port.options.id}
                                    port={port}
                                    style={{
                                        position: "absolute",
                                        left: absX,
                                        top: absY
                                    }} />
                        )
                    })}

                    {Object.entries(this.nodeModel.getInPorts()).map(([portGuid, portName]) => {
                        const port = this.nodeModel.getPort(portName);
                        if(!port) return null;

                        const isPathPort = port.options.type === 'path-port';

                        const { absX, absY, pathX, pathY } = this.getNewPortPosition(port, {searchChildGuid: port.options.targetAttrGuid});

                        if(isPathPort && pathX && pathY) {
                            port.setPosition(pathX, pathY);
                        }

                        return (
                            <PortWidget engine={this.props.engine}
                                    key={port.options.id}
                                    port={port}
                                    style={{
                                        position: "absolute",
                                        left: absX,
                                        top: absY
                                    }} />
                        )
                    })}
                </NodeOuterPorts>

                {/* <BottomToolBar show={this.state.isEditing}>
                    <span onClick={async () => {
                        const newChild = await this.createNewComposition()
                        const input = this.widgetRef.querySelector(`div[data-id="${newChild.guid}"] input[type="text"]`);
                              input.focus();
                              input.select();
                    }}>Add attribute</span>
                    <span onClick={() => this.toggleEditing(false)}>Done</span>
                </BottomToolBar> */}

                <div>
                    <div id={this.phenomId.genPageId("resize-left-bar")} className="resize-left" onMouseDown={this.startResize} />
                    <div id={this.phenomId.genPageId("resize-right-bar")} className="resize-right" onMouseDown={this.startResize} />
                </div>

                {nodeData.xmiType === "conceptual:Association" && <>
                    <Hexagon id={this.phenomId.genPageId("hexagon-shape-wrapper")}>
                        <HexagonRight height={this.domHeight} />
                        <HexagonLeft height={this.domHeight} />
                    </Hexagon>
                </>}
            </NodeContainer>
        </>);
    }
}
