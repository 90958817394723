import React from 'react'
import NodeLeaf from './leaf/NodeLeaf';
import { NavTreeRoot } from './NavTreeLeaf';
import TreeFilter from './sections/TreeFilter';
import TreeSearch from './sections/TreeSearch';
import { ResizeBarHorizontal } from '../util/stateless';
import * as actionCreators from "../../requests/actionCreators"
import { diagramColors } from '../navigate/diagrams/util';
import { ContextMenu, createGhostElement, createTreeDragElement } from '../util/util';
import { createNodeUrl } from '../../requests/type-to-path';
import { isModelPublished } from '../../requests/actionCreators';
import DeletionConfirm2 from "../dialog/DeletionConfirm2";

import AttrMove from "../dialog/attr_move";
import TagsEditor from '../dialog/TagsEditor';
import PhenomId from "../../requests/phenom-id";


/**
 * XmiType is used to determine whether onDrop is valid or not
 */
 const validDropArea = {
  "#": new Set([
      "datamodel:DataModel",
      "face:UoPModel",
      "skayl:MessageDataModel",
      "skayl:DeploymentModel",
      "skayl:IntegrationModel",
  ]),
  "datamodel:DataModel": new Set([
    "face:ConceptualDataModel",
    "face:LogicalDataModel",
    "face:PlatformDataModel",
    "skayl:DiagramModel",
    "skayl:ObjectModel",
]),
  "face:ConceptualDataModel": new Set([
      "face:ConceptualDataModel",
      "conceptual:Observable",
      "conceptual:Entity",
      "conceptual:Association",
      "conceptual:Generalization",
  ]),
  "face:LogicalDataModel": new Set([
      "face:LogicalDataModel",
      "logical:Measurement",
      "logical:MeasurementAxis",
      "logical:MeasurementSystem",
      "logical:MeasurementSystemAxis",
      "logical:MeasurementSystemConversion",
      "logical:StandardMeasurementSystem",
      "logical:CoordinateSystem",
      "logical:CoordinateSystemAxis",
      "logical:Landmark",
      "logical:ValueTypeUnit",
      "logical:Unit",
      "logical:Enumerated",
      "logical:Boolean",
      "logical:Character",
      "logical:Integer",
      "logical:Natural",
      "logical:NonNegativeReal",
      "logical:Real",
      "logical:String",
  ]),
  "face:PlatformDataModel": new Set([
      "face:PlatformDataModel",
      "platform:View",
      "platform:Enumeration",
      "platform:Long",
      "platform:LongLong",
      "platform:Double",
      "platform:String",
      "platform:Short",
      "platform:UShort",
      "platform:ULong",
      "platform:ULongLong",
      "platform:Float",
      "platform:Octet",
      "platform:Char",
      "platform:Boolean",
      "platform:BoundedString",
      "platform:BoundedWString",
      "platform:CharArray",
      "platform:Fixed",
      "platform:IDLArray",
      "platform:IDLSequence",
      "platform:LongDouble",
      "platform:WChar",
      "platform:WCharArray",
      "platform:WString",
      "platform:RegularExpressionConstraint",
      "platform:RealRangeConstraint",
      "platform:IntegerRangeConstraint",
  ]),
  "face:UoPModel": new Set([
      "face:UoPModel",
      "uop:PortableComponent",
      "uop:PlatformSpecificComponent",
      "platform:View",
  ]),
  "skayl:MessageDataModel": new Set([
      "skayl:MessageDataModel",
      "message:Type"
  ]),
  "skayl:IntegrationModel": new Set([
      "skayl:IntegrationModel",
      "im:IntegrationContext",
      "im:Equation",
      "pedm:ProcessingElement",
  ]),
  "skayl:DiagramModel": new Set([
      "skayl:DiagramModel",
      "skayl:DiagramContext",
  ]),
  "skayl:DeploymentModel": new Set([
      "skayl:DeploymentModel",
      "pedm:ProcessingElement",
  ]),
  "skayl:ObjectModel": new Set([
      "skayl:ObjectModel"
  ]),
  "platform:View": new Set([
      "platform:CharacteristicProjection"
  ])
};


class SmallNavTree extends React.Component {
  state = {
    countSearchMatches: 0,
    searchText: "",
    showFilter: false,
    showSearchResultOnly: false,
    isSortByName: false,
    allTags: [],
    filter: {
      useAndTags: true,           // filter tags by AND or by OR
      includeTags: new Set(),
      excludeTags: new Set(),
      includeXmiTypes: new Set(),
      excludeGuids: new Set(),
      pageWhitelist: {},
      pageBlacklist: {},
    },
  }

  // Nav Tree
  prevSearchLeaf = null;
  prevSelectedLeaf = null;
  autoSelectedLeaf = null;
  selectedLeaves = new Set();
  highlightedLeaves = new Set();


  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter !== this.state.filter ||
        prevProps.nodeIndex !== this.props.nodeIndex) {
          this.applyFilter();
    } 
  }

  saveSessionStorage = () => {
    const { isSortByName, filter } = this.state;
    const { nodeIndex } = this.props;

    // Save Leaf state
    const sessionConfigs = {};
    for (let guid in nodeIndex) {
      const leaf = nodeIndex[guid];
      if (leaf.getGuid() === "root") continue;
      if (leaf.isExpanded()) sessionConfigs[guid] =  leaf.serializeConfig();
    }

    // Convert Sets into Array (stringify prefers array over set)
    const sessionFilter = {};
    for (let key in filter) {
      // this will be reapplied on load - issue occurred with Generate's filter dropdown
      if (key === "pageWhitelist" || key === "pageBlacklist") {
        continue;
      }

      if (filter[key] instanceof Set) {
        sessionFilter[key] = [...filter[key]];
      } else {
        sessionFilter[key] = filter[key];
      }
    }

    // Save Navtree state
    return {
      isSortByName,
      sessionFilter,
      sessionConfigs,
    }
  }

  loadSessionStorage = (session) => {
    if (!session) return;

    const { isSortByName=false, sessionFilter={}, sessionConfigs={} } = session;
    const { nodeIndex } = this.props;

    // reapply leaf state
    for (let guid in nodeIndex) {
      const leaf = nodeIndex[guid];
      if (!leaf || guid === "root") continue;

      // when changing modes, the tree remembers which nodes were expanded
      //  -> if the hash does not contain the node then assume it was collapsed (expanded false)
      const config = sessionConfigs[guid];
      if (config) {
        leaf.setConfig(config);
      } else {
        leaf.setConfig({ expanded: false });
      }
    }

    // Convert Array back to Set (stringify prefers array over set)
    const filter = {};
    for (let key in sessionFilter) {
      if (sessionFilter[key] instanceof Array) {
        filter[key] = new Set(sessionFilter[key]);
      } else {
        filter[key] = sessionFilter[key];
      }
    }
    
    this.setState((prevState) => ({
      isSortByName,
      filter: {
        ...prevState.filter,
        ...filter,
      }
    }))
  }


  getCheckedLeaves = () => {
    let checked = [];
    for (let guid in this.props.nodeIndex) {
      if (guid === "root") continue;
      const leaf = this.props.nodeIndex[guid];
      leaf.isChecked() && checked.push(leaf);
    }

    return checked;
  }

  getFilters = () => {
    return this.state.filter;
  }


  // ==========================================================================================================
  // FILTER
  // ==========================================================================================================
  setTagFilters = (includeTags=[], excludeTags=[], useAndTags=true) => {
    this.setState((prevState) =>({
      filter: {
        ...prevState.filter,
        includeTags: new Set(includeTags),
        excludeTags: new Set(excludeTags),
        useAndTags,
      }
    }))
    return this;
  }

  setPageFilters = (pageWhitelist={}, pageBlacklist={}) => {
    // change type and name arrays into sets for faster search
    pageWhitelist.type = new Set(pageWhitelist.type);
    pageBlacklist.type = new Set(pageBlacklist.type);
    pageBlacklist.name = new Set(pageBlacklist.name);

    this.setState((prevState) =>({
      filter: {
        ...prevState.filter,
        pageWhitelist: pageWhitelist,
        pageBlacklist: pageBlacklist,
      }
    }))
    return this;
  }

  setXmiTypeFilters = (includeXmiTypes=[]) => {
    this.setState((prevState) =>({
      filter: {
        ...prevState.filter,
        includeXmiTypes: new Set(includeXmiTypes),
      }
    }))
    return this;
  }

  setGuidFilters = (excludeGuids=[]) => {
    this.setState((prevState) =>({
      filter: {
        ...prevState.filter,
        excludeGuids: new Set(excludeGuids),
      }
    }))
    return this;
  }

  applyFilter = () => {
    this.clearSelectedLeaves();
    const { nodeIndex } = this.props;

    const { useAndTags, includeTags, excludeTags, includeXmiTypes, excludeGuids, pageWhitelist, pageBlacklist } = this.state.filter;
    const stack = [ nodeIndex["root"] ];
    const includeList = [...includeTags]; // convert Set to Array
    const excludeList = [...excludeTags]; // convert Set to Array

    // special case:
    // if a parent node is excluded (filtered out) but its child is not excluded, then the child will force the parent to show
    const excludedLeaves = new Set();

    while (stack.length) {
      let leaf = stack.pop();
      leaf.getChildrenLeaves().forEach(c => stack.push(c))

      // ignore root
      if (leaf.getGuid() === "root") continue;

      // Hide node if xmiType or name is blacklisted
      // Hide node if guid is excluded
      // Hide node if page whitelist exist and xmiType is not included
      // Hide node if xmiType whitelist exist and xmiType is not included
      if (pageWhitelist.type?.size && !pageWhitelist.type.has(leaf.getXmiType()) ||
          (pageBlacklist.type?.size && pageBlacklist.type.has(leaf.getXmiType())) || 
          (pageBlacklist.name?.size && pageBlacklist.name.has(leaf.getName())) || 
          excludeGuids.has(leaf.getGuid()) || 
          includeXmiTypes.size && !includeXmiTypes.has(leaf.getXmiType())) {
            leaf.hide();

      } else if (includeTags.size || excludeTags.size) {
        const leafTagSet = new Set(leaf.getTags());  // convert Array to Set

        if (excludeList.some(t => leafTagSet.has(t))) {
          // OR Exclude case: hide if at least one leaf tag is included in filter
          excludedLeaves.add(leaf);

        } else if (useAndTags && !!includeList.length && includeList.every(t => leafTagSet.has(t))) {
          // AND Include case: show if all leaf tags are included in filter
          // note: doing .every on an empty list result in true. need to check if the array is populated
          leaf.show();

        } else if (!useAndTags && includeList.some(t => leafTagSet.has(t))) {
          // OR Include case: show if at least one leaf tag is included in filter
          leaf.show();

        } else if (excludeTags.size && !includeTags.size) {
          // node's tag(s) did not meet filter criteria
          // show node if Exclude filter is present but not Include
          leaf.show();

        } else if (includeTags.size && !excludeTags.size) {
          // node's tag(s) did not meet filter criteria
          // hide node if Include filter is present but not Exclude
          leaf.hide();

        } else {
          // node's tag(s) did not meet any filter criteria
          leaf.hide();
        }
        
      } else {
        leaf.show();
      }
    }

    excludedLeaves.forEach(leaf => leaf.hide());
    this.applySearchFilter();
  }

  applySearchFilter = () => {
    const { nodeIndex } = this.props;
    const { showSearchResultOnly } = this.state;
    const searchText = this.state.searchText.trim();
    const stack = [ nodeIndex["root"] ];
    let countSearchMatches = 0;
    
    this.clearHighlightedLeaves();

    // 0) Exit if search does not exist
    if (!searchText) {
      this.prevSearchLeaf = null;
      return this.setState({ showFilter: false, countSearchMatches, searchText: "" });
    }

    // 1) applyFilter trigger first to determine which nodes are hidden
    //      because depth first search depends on the hidden attribute
    this.highlightedLeaves = this.findMatchDepthFirstSearch(nodeIndex["root"], searchText);
    this.highlightedLeaves.forEach(leaf => leaf.setHighlighted(true));

    if (showSearchResultOnly) {
      // only show matching nodes, hide all others
      while (stack.length) {
        let leaf = stack.pop();
        leaf.getChildrenLeaves().forEach(c => stack.push(c));
        if (leaf.getGuid() === "root") continue;

        // highlight and show
        if (this.highlightedLeaves.has(leaf)) {
          leaf.show();
        } else {
          leaf.hide();
        }
      }
    }

    // Save a few variables for searchLeafByName
    let [firstLeaf] = this.highlightedLeaves;
    if (firstLeaf) {
      this.selectLeaf(firstLeaf);
      this.prevSearchLeaf = firstLeaf;
    }

    this.setState({ showFilter: false, countSearchMatches: this.highlightedLeaves.size }, () => {
      if (firstLeaf) this.scrollToLeaf(firstLeaf);
    });
  }

  isAllNodesFilteredOut = () => {
    const root = this.props.nodeIndex["root"];
    return this.props.main_page !== "scratchpad" && root.getChildrenLeaves().length && root.isChildrenFilteredOut();
  }


  // ==========================================================================================================
  // DELETE LEAF
  // ==========================================================================================================
  // removeLeaves = (guids=[]) => {
  //   guids.forEach(guid => this.removeLeaf(guid));
  //   this.forceUpdate();
  // }

  // removeLeaf = (guid) => {
  //   const leaf = this.nodeIndex[guid];
  //   if (!leaf) return;
  //   leaf.remove();
  //   delete this.nodeIndex[guid];
  // }

  removeDiagramLeaf = (guid) => {
    const diagramLeaf = this.diagramIndex[guid];
    if (!diagramLeaf) return;
    diagramLeaf.remove();
    delete this.diagramIndex[guid];
    this.forceUpdate();
  }

  // ==========================================================================================================
  // SEARCH
  // ==========================================================================================================
  handleSearch = (text) => {
    if (typeof text !== 'string') return;
    if (this.props.nodeIndex[text]) return this.searchLeafByGuid(text);
    this.searchLeafByName(text);
  }

  handleSearchToggle = () => {
    this.setState((prevState) => ({ showSearchResultOnly: !prevState.showSearchResultOnly }), 
      this.applyFilter)
  }

  scrollToLeaf = (leaf) => {
    if (leaf instanceof NodeLeaf === false || !this.props.id) return;

    // this tree is used by NavTree and ProjectFilter - need id to differentiate the two
    const domID = `#${this.props.id}-node-leaf-${leaf.getGuid()}`;
    const domLeaf = document.querySelector(domID);
    // note: "inline" is an optional argument and is still in the browser's experimental phase
    domLeaf && domLeaf.scrollIntoView({ inline: "start" });
  }

  searchLeafByGuid = (guid) => {
    this.clearSelectedLeaves();
    this.clearHighlightedLeaves();
    const leaf = this.props.nodeIndex[guid];
    
    // match found
    if (leaf) {
      const parentLeaf = leaf.getParentLeaf();
      parentLeaf && parentLeaf.setExpanded(true);
      this.selectLeaf(leaf);
      this.scrollToLeaf(leaf);
    }
    this.forceUpdate();
  }

  searchLeafByName = (text="") => {
    text = text.trim();
    this.clearSelectedLeaves();

    // 1) User entered the same search parameters - scroll to next leaf
    if (text && text === this.state.searchText) {
      const highlighted = [...this.highlightedLeaves];
      const currIndex = highlighted.findIndex(leaf => leaf === this.prevSearchLeaf);
      const nextLeaf = highlighted[(currIndex + 1) % highlighted.length];
      
      if (nextLeaf) {
        this.selectLeaf(nextLeaf);
        this.prevSearchLeaf = nextLeaf;
        this.scrollToLeaf(nextLeaf);
      }
      return this.forceUpdate();
    }

    this.setState({ searchText: text },
      this.applyFilter);
  }

  findMatchDepthFirstSearch = (searchLeaf, searchText="", result) => {
    if (!result) result = new Set();

    // add children to call stack and search through them
    searchLeaf.getChildrenLeaves().length && searchLeaf.getChildrenLeaves().forEach(leaf => {
      this.findMatchDepthFirstSearch(leaf, searchText, result);
    })

    // searches for a matching substring in the leaf's name
    const regex = new RegExp(`.*(${searchText}).*`, 'i');

    // ignore root node
    // ignore filtered out nodes (not rendered)
    if (searchLeaf.getGuid() !== "root" && !searchLeaf.isFilteredOut() && searchLeaf.getName().match(regex)) {
      result.add(searchLeaf);
    }

    return result;
  }

  // ==========================================================================================================
  // HIGHLIGHT LEAF
  // ==========================================================================================================
  clearHighlightedLeaves = () => {
    this.highlightedLeaves.forEach(leaf => leaf.setHighlighted(false));
    this.highlightedLeaves = new Set();
  }

  highlightLeaf = (leaf) => {
    this.highlightedLeaves.add(leaf);
    leaf.setHighlighted(true);
  }

  dehighlightLeaf = (leaf) => {
    this.highlightedLeaves.delete(leaf);
    leaf.setHighlighted(false);
  }

  // ==========================================================================================================
  // SELECT LEAF
  // ==========================================================================================================
  clearSelectedLeaves = () => {
    this.selectedLeaves.forEach(leaf => leaf.setSelected(false));
    this.selectedLeaves = new Set();
    this.prevSelectedLeaf = null;
  }

  selectLeaf = (leaf) => {
    this.selectedLeaves.add(leaf);
    leaf.setSelected(true);
  }

  deselectLeaf = (leaf) => {
    this.selectedLeaves.delete(leaf);
    leaf.setSelected(false);
  }


  autoSelectLeaf = (leaf) => {
    if (this.autoSelectedLeaf !== null && this.autoSelectedLeaf instanceof NodeLeaf) {
      this.autoSelectedLeaf.setAutoSelected(false);
    }

    if (!leaf.isSelected()) {
      this.autoSelectedLeaf = leaf;
      leaf.setAutoSelected(true);
      leaf.setSelected(false);
    }
  }

  clearAutoSelectLeaf = () => {
    if (this.autoSelectedLeaf !== null && this.autoSelectedLeaf instanceof NodeLeaf) {
      this.autoSelectedLeaf.setAutoSelected(false);
      this.autoSelectedLeaf = null;
    }
  }

  /**
   * Scratchpad renders a second tree to show uncommitted entities
   *    - This can include real nodes and placeholder nodes
   *    - Currently the node is one level deep and will exist in the diagramPackage node
   */
  isDiagramLeaf = (leaf) => {
    const parent = leaf.getParentLeaf();
    return parent.getGuid() === "diagramPackage";
  }

  isDiagramLeafSelected = () => {
    for (let leaf of this.selectedLeaves) {
      if (this.isDiagramLeaf(leaf)) return true;
    }
    return false;
  }

  onDiagramSelectLeaf = (event, leaf) => {
    // ignore UncommittedEntities
    if (leaf.getGuid() === "diagramPackage") return;

    // prevent the user from combining leaf nodes from the diagramIndex and nodeIndex
    if (!event.ctrlKey || !this.isDiagramLeafSelected()) {
      this.clearSelectedLeaves();
    }

    if (this.selectedLeaves.has(leaf)) {
      this.deselectLeaf(leaf);
    } else {
      this.selectLeaf(leaf);
    }

    this.forceUpdate();
  }

  onCheckLeaf = (event, currLeaf) => {
    const { sub_page, main_page } = this.props;
    const isMergePage = ["push", "pull", "approve"].includes(sub_page);
    const isModelGenPage = main_page === "model_gen";
    const checked = event.target.checked;

    let ancestors = new Set(currLeaf.getAllParentLeaves());
    let leaves = currLeaf.getAllChildrenLeaves();

    currLeaf.setChecked(checked);

    if (isModelGenPage) {
      // check or uncheck all children
      for (let l of leaves) {
        l.setChecked(checked);
      }

      // check the status of parent nodes
      for (let a of ancestors) {
        if (!a || a.getGuid() === "root") continue;
        a.updateCheckedStatus();
      }
    }
    
    if (isMergePage) {
      let relations = new Set();

      if (currLeaf.isMergeCandidate()) {
        this.props.findAllRelationCandidates(currLeaf, relations);
      }
      
      for (let child of leaves) {
        child.isMergeCandidate() && this.props.findAllRelationCandidates(child, relations);
      }

      for (let rel of relations) {
        rel.getAllParentLeaves().forEach(p => ancestors.add(p));
      }

      // check or uncheck all children/relational nodes
      for (let l of [...leaves, ...relations]) {
        l.setChecked(checked);
      }

      // reorder the ancestors and check their status
      let orderedAncestors = [];
      const queue = [...ancestors.difference(relations)];
      while (queue.length) {
        const anc = queue.shift();
        const ancChildren = new Set(anc.getChildrenLeaves());

        if (!ancChildren.size || !queue.find(q => ancChildren.has(q))) {
          orderedAncestors.push(anc);
        } else {
          queue.push(anc);
        }
      }

      // check the status of parent nodes
      for (let anc of orderedAncestors) {
        if (!anc || anc.getGuid() === "root") continue;
        anc.updateCheckedStatus();
      }
    } 

    this.forceUpdate();
  }

  onSelectLeaf = (event, currLeaf) => {
    if (currLeaf instanceof NodeLeaf === false) return;
    
    const prevLeaf = this.prevSelectedLeaf;
    const isSelected = !currLeaf.isSelected();
    let leaves = new Set();

    // Shift + Click - require two nodes. 
    // if one is missing then it is treated like a standard click command
    if (event.shiftKey && prevLeaf && currLeaf) {
      leaves = new Set(this.gatherLeavesFromShiftSelect(prevLeaf, currLeaf));

      // exit early if shift select was invalid
      if (!leaves.size) {
        return;
      }

    } else if (event.ctrlKey && prevLeaf instanceof NodeLeaf) {
      // Ctrl + Click - readd previous leaf node
      
      if (prevLeaf === currLeaf) {
        leaves.add(currLeaf);
        leaves.add(prevLeaf);
      } else {
        leaves.add(currLeaf);
      }

    } else {
      // clear all previously selected leaves
      this.clearSelectedLeaves();

      // Standard Click - add current leaf
      leaves.add(currLeaf);
    }

    // toggle
    for (let leaf of leaves) {
      if (isSelected) {
        this.selectLeaf(leaf);
      } else {
        this.deselectLeaf(leaf);
      }
    }

    this.prevSelectedLeaf = currLeaf;
    this.forceUpdate();
  }

  gatherLeavesFromShiftSelect = (prevLeaf, currLeaf) => {
    // invalid nodes
    if (prevLeaf instanceof NodeLeaf === false || currLeaf instanceof NodeLeaf === false) {
      return [];
    }

    // the leaves must share the same parent
    if (prevLeaf.getParentLeaf() !== currLeaf.getParentLeaf()) {
      return [];
    }

    const result = [];
    const parentLeaf = prevLeaf.getParentLeaf();
    const idx1 = parentLeaf.getChildrenLeaves().findIndex(childLeaf => childLeaf === prevLeaf);
    const idx2 = parentLeaf.getChildrenLeaves().findIndex(childLeaf => childLeaf === currLeaf);

    const start = Math.min(idx1, idx2);
    const end = Math.max(idx1, idx2);
    parentLeaf.getChildrenLeaves().slice(start, end + 1).forEach(childLeaf => {
      result.push(childLeaf);
    })

    return result;
  }

  onExpandLeaf = (leaf) => {
    if (leaf instanceof NodeLeaf === false) return;
    if (leaf.isExpanded()) {
      leaf.setExpanded(false);

      // on collapse - remove children from selection list
      const stack = [...leaf.getChildrenLeaves()];
      while (stack.length) {
        const childLeaf = stack.pop();
        childLeaf.getChildrenLeaves().forEach(c => stack.push(c));
        this.deselectLeaf(childLeaf);
      }

    } else {
      leaf.setExpanded(true);
    }
  }


  // ==========================================================================================================
  // MOUSE EVENTS
  // ==========================================================================================================
  onContextMenu = (event, leaf) => {
    const { sub_page } = this.props;

    if (["push", "pull", "approve"].includes(sub_page)) {
      event.preventDefault();
    } else {
      this.handleContextMenu(event, leaf);
    }
  }

  handleContextMenu = (event, leaf) => {
    event.preventDefault();
    const menuItems = [];

    // If leaf is not selected, then select it
    if (!this.selectedLeaves.has(leaf)) {
      this.clearSelectedLeaves();
      this.selectLeaf(leaf);
      this.forceUpdate();
    }

    if (leaf.getXmiType().match(/(datamodel|uopmodel|diagrammodel)/gi)) {
      // need to create the url with a guid of "new"
      const dataNode = { ...leaf.getData(), guid: "new" };
      
      menuItems.push({
        text: "Create Package",
        func: () => {
          // this.props.history.push( createNodeUrl(leaf.getData()),  );
          this.props.history.push( createNodeUrl(dataNode), { parentNode: leaf.getData() });
        },
      })
    }

    if (leaf.getXmiType() === "conceptual:Composition") {
      if (!isModelPublished(leaf.data.modelId)) {
        menuItems.push({
          text: "Move Attribute",
          id:"context-menu-move-attr",
          func: () => {
            this.clearSelectedLeaves();
            this.forceUpdate();
            AttrMove.show(leaf.getData());
          },
        })
      }
    } else {
      menuItems.push({
        text: "Edit Tags",
        id:"context-menu-edit-tags",
        func: () => {
          if (this.selectedLeaves.has(leaf)) {
            TagsEditor.show( [...this.selectedLeaves].map(leaf => leaf.getData()) );
          } else {
            TagsEditor.show( leaf.getData() );
          }
        },
      })

      menuItems.push({
        text: "Delete",
        id:"context-menu-delete",
        func: () => {
          DeletionConfirm2.show(leaf.getGuid(), leaf.getName(), null, true);
        },
      })
    }

    if (menuItems.length) {
      ContextMenu.show(menuItems, { left: event.pageX + 5, top: event.pageY });
    }
  }
  
  onDiagramContextMenu = (event, leaf) => {
    event.preventDefault();
    const menuItems = [];

    // ignore UncommittedEntities
    if (leaf.getGuid() === "diagramPackage") return;

    // "DIAGRAM_" is old method - will be deleted when Scratchpad is refactored
    const isDiagramGuid = leaf.getGuid().startsWith("DIAGRAM_");

    if (isDiagramGuid) {
      menuItems.push({
        text: "Delete node",
        func: () => {
          window["deleteDiagramNode"] && window["deleteDiagramNode"]([...this.selectedLeaves].map(leaf => leaf.getGuid()));
        }
      })
    } else {
      menuItems.push({
        text: "Reset node",
        func: () => {
          window["diagramResetNode"] && window["diagramResetNode"]([...this.selectedLeaves].map(leaf => leaf.getGuid()));
        }
      })
    }

    if (menuItems.length) {
      ContextMenu.show(menuItems, { left: event.pageX + 5, top: event.pageY });
    }
  }


  // ==========================================================================================================
  // DRAG EVENTS
  // ==========================================================================================================
  onDiagramDragStart = (event) => {
    const whitelist = new Set(["conceptual:Entity", "conceptual:Association", "platform:View", "im:UoPInstance", "im:ComposedBlock"])
    const data = [...this.selectedLeaves].map(leaf => leaf.getData());
    

    if (!data.length || !data.every((node) => whitelist.has(node.xmiType))) {
      return event.preventDefault();
    }

    const dragNode = data[0];
    let name = data.length > 1 ? `${data.length} nodes` : dragNode.name;
    let bgColor = diagramColors[dragNode.xmiType] || diagramColors["default"];

    if (!bgColor) {
      return event.preventDefault();
    }

    let ele = createGhostElement({ name, type: dragNode.xmiType, bgColor });
    event.dataTransfer.setDragImage(ele.htmlElement, ele.offset[0], ele.offset[1]);
    setTimeout(() => ele.htmlElement.remove(), 0);
    event.dataTransfer.setData("treeNodes", JSON.stringify(data));
  }


  onDragStart = (event, dragLeaf) => {
    // If leaf is not selected, then select it
    if (!this.selectedLeaves.has(dragLeaf)) {
      this.clearSelectedLeaves();
      this.selectLeaf(dragLeaf);
      this.forceUpdate();
    }

    // -1) special pages - change behavior of drag start
    if (["scratchpad", "view_trace", "idm"].includes(this.props.main_page)) {
      return this.onDiagramDragStart(event, dragLeaf);
    }

    // 0) Any leaf can be dragged - limit it to only selected leaves
    if (!this.selectedLeaves.has(dragLeaf)) {
      return event.preventDefault();
    }

    // 0) Nothing is selected
    if (!this.selectedLeaves.size) {
      actionCreators.receiveWarnings("Please select a node first and try again.");
      return event.preventDefault();
    }

    // 0) All selected nodes need to share the same parent
    const [firstLeaf] = this.selectedLeaves;   // gets the first element from set
    if ([...this.selectedLeaves].some(leaf => leaf.getXmiType() !== firstLeaf.getXmiType())) {
      actionCreators.receiveWarnings("Please select nodes of the same type and try again.");
      return event.preventDefault();
    }

    // 1) Create Floating Element and provide it with data
    const text_from = this.selectedLeaves.size > 1 ? `${this.selectedLeaves.size} nodes` : firstLeaf.getName();
    const ghosty = createTreeDragElement(text_from);
          ghosty.setAttribute('data-xmitype', firstLeaf.getXmiType());
          ghosty.setAttribute('data-guid', firstLeaf.getGuid());

    const moveGhosty = (e) => {
      // for some reason this is triggered with onDragEnd and it moves the element to position [0, 0].  this line prevents it from moving.
      if (!e.pageX || !e.pageY) return;
      ghosty.style.top = e.pageY - 10 + "px";
      ghosty.style.left = e.pageX + 5 + "px";
    }

    const cleanUp = () => {
      ghosty.remove();
      window.removeEventListener('drag', moveGhosty);
      window.removeEventListener('dragend', cleanUp);
    }

    window.addEventListener("drag", moveGhosty);
    window.addEventListener("dragend", cleanUp);

    const data = [...this.selectedLeaves].map(ele => ele.getData());
    event.dataTransfer.effectAllowed = "copy";
    event.dataTransfer.setData("treeNodes", JSON.stringify(data));
    event.dataTransfer.setDragImage(document.createElement("div"), 0, 0);     // blanks out the default ghost image
  }

  onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const textTo = document.querySelector("#dragging-leaf-to");
    if (textTo) textTo.innerHTML = "";

    const textImg = document.querySelector("#dragging-leaf-img");
    if (textImg) textImg.style.backgroundPosition = "-32px 32px";
  }

  onDragOver = (event, targetLeaf) => {
    event.preventDefault();
    event.stopPropagation();
    const ghosty = document.querySelector("#dragging-leaf-ghosty");
    const ghostyImg = document.querySelector("#dragging-leaf-img");
    if (!ghosty) return;

    // Adjust ghosty text
    const textTo = document.querySelector("#dragging-leaf-to");
    if (textTo) {
      textTo.innerHTML = targetLeaf.getName();
    }
    
    const draggingGuid = ghosty.dataset['guid'];
    const draggingType = ghosty.dataset['xmitype'];
    const dropzoneType = targetLeaf.getXmiType();

    if (!draggingType || !dropzoneType) return;
    
    // On Drop is valid
    const isAllowed = dropzoneType in validDropArea && validDropArea[dropzoneType].has(draggingType) && draggingGuid !== targetLeaf.getGuid();
    if (isAllowed) {
      event.dataTransfer.dropEffect = "copy";
      if (ghostyImg) ghostyImg.style.backgroundPosition = "0 32px";
    } else {
      event.dataTransfer.dropEffect = "none";
      if (ghostyImg) ghostyImg.style.backgroundPosition = "-32px 32px";
    }
  }

  handleDragOverRoot = (e) => {
    const rootLeaf = this.props.diagramIndex?.["root"];
    if (!rootLeaf) return;
    this.onDragOver(e, rootLeaf);
  }

  handleDropOnRoot = (e) => {
    const rootLeaf = this.props.diagramIndex?.["root"];
    if (!rootLeaf || !this.props.onDrop) return;
    this.props.onDrop(e, rootLeaf);
  }

  render() {
    const { allTags, showResizeBar, isCollapsed, onToggleCollapse } = this.props;
    const { showFilter, showSearchResultOnly, searchText, filter, countSearchMatches } = this.state;
    const isFilter = filter.includeTags.size || filter.excludeTags.size || filter.includeXmiTypes.size;
    const phenomId = new PhenomId("nav-tree",this.props.idCtx);

    return <div className='navtree-container'>

            {!isCollapsed &&
            <div className='navtree-search'>
              <TreeSearch id={this.props.id}
                          searchText={searchText}
                          countMatches={countSearchMatches} 
                          showSearchResultOnly={showSearchResultOnly}
                          onSubmit={this.handleSearch}
                          onResultToggle={this.handleSearchToggle} />
            </div> }


            <div className='tree-content'>

              {!isCollapsed &&
              <div className='tree-content-wrapper'>
                {showFilter
                  ? <TreeFilter mode={this.props.mode}
                                allTags={allTags}
                                useAndTags={filter.useAndTags}
                                includeTags={filter.includeTags}
                                excludeTags={filter.excludeTags}
                                includeXmiTypes={filter.includeXmiTypes}
                                setTagFilters={this.setTagFilters}
                                setXmiTypeFilters={this.setXmiTypeFilters} />
                  : <ul data-xmitype="root"
                        onDragOver={this.props.isDraggable ? this.handleDragOverRoot : null}
                        onDrop={this.props.isDraggable ? this.handleDropOnRoot : null}>
                      {this.props.main_page === "scratchpad" && this.props.diagramIndex &&
                      <NavTreeRoot id={this.props.id ? `${this.props.id}-diagram-leaf` : null}
                                    rootLeaf={this.props.diagramIndex["root"]}
                                    isSortByName={this.state.isSortByName}
                                    isDraggable={this.props.isDraggable}
                                    onSelectLeaf={this.onDiagramSelectLeaf}
                                    onExpandLeaf={this.onExpandLeaf}
                                    onDoubleClick={this.props.onDoubleClick}
                                    onContextMenu={this.onDiagramContextMenu}
                                    onDragStart={this.onDiagramDragStart} /> }

                      <NavTreeRoot id={this.props.id ? `${this.props.id}-node-leaf` : null}
                                    rootLeaf={this.props.nodeIndex["root"]}
                                    useCheckbox={this.props.useCheckbox}
                                    isSortByName={this.state.isSortByName}
                                    isDraggable={this.props.isDraggable}
                                    onCheckIncludeChildren={this.props.onCheckIncludeChildren}
                                    onSelectLeaf={this.onSelectLeaf}
                                    onCheckLeaf={this.onCheckLeaf}
                                    onExpandLeaf={this.onExpandLeaf}
                                    onDoubleClick={this.props.onDoubleClick}
                                    onContextMenu={this.onContextMenu}
                                    onDragStart={this.onDragStart}
                                    onDragLeave={this.onDragLeave}
                                    onDragOver={this.onDragOver}
                                    onDrop={this.props.onDrop}
                                    />
                    </ul> }
              </div> }

              <div className="navtree-side-btns">
                  {!isCollapsed && <>
                  <button className={"fas fa-sort-alpha-down" + (this.state.isSortByName ? " active" : "")}
                          title="Sort Tree"
                          id={phenomId.gen("","sort-select")}
                          onClick={() => this.setState((prevState) => ({ isSortByName: !prevState.isSortByName }))} />
                  <button className={"fas fa-filter" + (showFilter || isFilter ? " active" : "")}
                          title="Filter Tree"
                          id={phenomId.gen("","filter-select")}
                          onClick={() => this.setState((prevState) => ({ showFilter: !prevState.showFilter }))} />
                  {this.props.onReset &&
                  <button className="fas fa-sync" 
                          title="Refresh Tree"
                          id={phenomId.gen("","refresh-tree")}
                          onClick={this.props.onReset} /> }
                  {this.props.page_guid && this.props.page_guid !== "new" &&
                    <button className="fas fa-crosshairs"
                            id={phenomId.gen("","crosshairs-select")}
                            title="Find Node in Tree"
                            onClick={() =>{
                              const leaf = this.props.nodeIndex[this.props.page_guid];
                              if (leaf) this.scrollToLeaf(leaf);
                            } } /> }
                  </>}

                  {onToggleCollapse &&
                  <button className="navtree-collapse-btn k-i-arrow-chevron-left"
                          onClick={onToggleCollapse} /> }
              </div>

              {showResizeBar &&
              <ResizeBarHorizontal onResize={this.props.onResize} /> }
            </div>


    </div>
  }
}


export default SmallNavTree;
