import React, {Component} from "react";
import {Notifications, Notifications2} from "../../edit/notifications";
import {ColorCollapsable, LineLabel, PhenomLabel} from "../../util/stateless";
import {Upload} from "@progress/kendo-react-upload";
import ChangeSetPicker from "../../widget/ChangeSetPicker";
import PhenomLoadButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { connect } from "react-redux";
import NavTree from "../../tree/NavTree";
import { getActiveChangeSetId } from "../../../requests/actionCreators";
import { _ajax } from "../../../requests/sml-requests";

class Merge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            overwrite: false,
            checkUnique: true,
            results: null,
        };

        this.mergeModels = this.mergeModels.bind(this);
    }

    mergeModels () {
        if (!this.state.files.length) {
          return Notifications2.parseWarnings("Please select a file and try again.")
        }

        this.setState({isLoading: true, results: null});

        const newSubModel = new FormData();

        newSubModel.append("file", this.state.files[0] ? this.state.files[0].getRawFile() : null);
        newSubModel.append("overwrite", this.state.overwrite);
        newSubModel.append("checkUnique", this.state.checkUnique);
        if (getActiveChangeSetId()) newSubModel.append("changeSetId", getActiveChangeSetId());

        return _ajax({
            url: `/index.php?r=/sub-model/naive-merge`,
            method: "POST",
            data: newSubModel,
            processData: false,
            contentType: false,
            // headers: {
            //     "X-CSRF-TOKEN": window["_csrf"]
            // }
        }).then(resp => {
            if(resp.data.edits || resp.data.additions) {
                this.setState({
                    files: [],
                    results: resp.data,
                }, () => {
                    NavTree.reset();
                });
            }
        });
    };

    fileStateChange = event => {
        const modifiedState = event.newState;
        modifiedState[0].status = 1;

        this.setState({
            files: modifiedState
        });
    };

    render() {
        const phenomId = new PhenomId("merge",this.props.idCtx);
        const additionsTable = <table
          // id="additions"
          id={phenomId.gen(["init","additions"],"wrapper")}>
            <tbody>
            {this.state.results && this.state.results.additions.map((node, idx) => <tr key={idx}>
                <td id={phenomId.gen(["additions",idx],"node-id")}>{node.name || node.rolename || node.guid}</td>
            </tr>)}
            </tbody>
        </table>;

        const editsTable = <table
          // id="edits"
          id={phenomId.gen(["init","edits"],"wrapper")}>
            <tbody>
            {this.state.results && this.state.results.edits.map((node, idx) => <tr key={idx}>
                <td id={phenomId.gen(["edits",idx],"node-id")}>{node.name || node.rolename || node.guid}</td>
            </tr>)}
            </tbody>
        </table>;
        const cinc_works = this.props.userRole.indexOf('c') != -1;

        return (<div className="phenom-content-scrollable subview-wrapper">
            <Notifications ref={ele => this.noticeRef = ele}/>
            <div>
                <div className="flex-h">
                    <div className="flex-v" style={{width: "85%"}}>
                        <p style={{marginBottom: 15}}>A merge external model will inject nodes from a provided model file into the current project unless the given node's id is already represented in the current project. Nodes which are unique to the source content and would normally be injected as part of the merge but which do not have a valid parent in the destination project will also be excluded from the merge. If Overwrite is enabled, nodes that are present in both the source and the destination project but are different in the destination project will be edited to resemble the ones in the source model.<br></br><br></br>Any new nodes created during the process will be added to the model indicated on the right side of the page. 
                        {/* The changes made during a particular merge process can also be associated with a change set by selecting one from the menu below before executing the merge. */}
                        <br></br><br></br>If Overwrite is enabled, any nodes to be overwritten that are in a published model will be skipped.</p>
                    </div>
                </div>
                <div className="flex-h">
                    <div style={{width: "85%"}}>
                        <Upload
                            idCtx={phenomId.gen("","branch-create-upload")}
                            className="branch-create-upload merge-upload"
                            onStatusChange={this.fileStateChange}
                            multiple={false}
                            files={this.state.files}
                            defaultFiles={[]}
                            restrictions={{
                                allowedExtensions: [".face", ".skayl"],
                                maxFileSize: 209715200}}/>
                    </div>

                </div>
                <div className="flex-h" style={{ display: "flex", gap: 20 }}>
                    <PhenomLoadButton onClick={this.mergeModels} disabled={cinc_works} text="Merge" idCtx={phenomId.gen("")}/>
                    {/* <div>
                      <PhenomLabel text="Change Set" style={{ textTransform: "none" }} />
                      <ChangeSetPicker id={phenomId.gen("")} />
                    </div> */}
                    <div>
                      <PhenomLabel text="Overwrite" style={{ textTransform: "none" }} />
                      <input type="checkbox" checked={this.state.overwrite} onChange={(e) => {this.setState({overwrite: e.target.checked});}} id={phenomId.gen("","overwrite-input")}/>
                    </div>
                    <div>
                      <PhenomLabel text="Check for Name Collisions" style={{ textTransform: "none" }} />
                      <input type="checkbox" checked={this.state.checkUnique} onChange={(e) => {this.setState({checkUnique: e.target.checked});}} id={phenomId.gen("","name-collisions-input")}/>
                    </div>
                </div>
            </div>
            {this.state.results && <>
                <label style={{marginTop: 15}}>Results: </label>
                <ColorCollapsable heading="Nodes Added:" content={additionsTable} color={"#03c09b"}
                                  vMargin={10}
                                  idCtx={phenomId.gen("","additions-table")}
                                  contentId={phenomId.gen(["init","additions"],"wrapper")}
                                  default={true}/>
                <ColorCollapsable heading="Nodes Edited:" content={editsTable} color={"#c00328"}
                                  vMargin={10}
                                  idCtx={phenomId.gen("","edits-table")}
                                  contentId={phenomId.gen(["init","edits"],"wrapper")}
                                  default={true}/>
            </>}
        </div>);
    }
}


const msp = (state) => ({
  userRole: state.user.userRole,
})

export default connect(msp)(Merge)