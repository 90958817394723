import React from "react";
import {orderBy} from "@progress/kendo-data-query";
import {FormatMenuHeaderCell, LineLabel} from "../../util/stateless";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {Button, Toolbar, ToolbarItem} from "@progress/kendo-react-buttons";
import {EntityChild} from "./edit-entity-child";
import GenView from "./gen_view";
import SpecRollupPopup from "./spec-rollup-popup";
import {Notifications} from "../notifications";
import PhenomId from "../../../requests/phenom-id";
import DeletionConfirm2 from "../../dialog/DeletionConfirm2";

class EntityChildsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compositions: this.props.children.filter(child => child.xmiType === "conceptual:Composition"),
            associatedEntities: this.props.children.filter(child => child.xmiType === "conceptual:AssociatedEntity"),
            deletions: [],
            specializationSrcGuid: null,
            specializationDstGuid: null,
            possibleSpecializations: [],
            truncatecompositionsDescriptions: true,
            truncateassociatedEntitiesDescriptions: true,
            to_delete_name: "",
        };

        this.newChildId = 0;
        this.fieldRefs = {};
        this.rowElRefs = {};
        this.specializationBracket = undefined;
        this.specializationPointerSrc = undefined;
        this.specializationPointerDst = undefined;
        this.attributeContainer = undefined;
        this.rollupModal = undefined;
        this.rollupButton = undefined;
        this.noticeRef = undefined;
    }

    componentDidMount() {
        this.generatePossibleSpecializations();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.children !== this.props.children) {
            this.setState({
                compositions: this.props.children.filter(child => child.xmiType === "conceptual:Composition"),
                associatedEntities: this.props.children.filter(child => child.xmiType === "conceptual:AssociatedEntity"),
                specializationSrcGuid: null,
                specializationDstGuid: null
            });
            this.clearSpecializationTrace();
        }
        if (prevProps.specializes !== this.props.specializes) {
            this.generatePossibleSpecializations();
        }
        if (this.state.compositionsSort !== prevState.compositionsSort) {
            setTimeout(() => {
                this.getSpecializationTrace(undefined, undefined, false);
            });
        }
    }

    generatePossibleSpecializations = () => {
        const possibleSpecializations = [];
        let specializes = this.props.specializes || {specializes: {}};
        let getNextChildren = true;
        while (getNextChildren) {
            if (specializes.children) {
                const parentName = specializes.name;
                possibleSpecializations.push(...specializes.children.map(e => ({
                    ...e,
                    parentName,
                    fullPath: `${parentName}.${e.rolename}`
                })));
                specializes = specializes.specializes || {};
            } else {
                getNextChildren = false;
            }
        }
        this.setState({
            possibleSpecializations
        });
    };

    gatherChangeData() {
        const childData = {
            deletedChildren: this.state.deletions,
            changedChildren: [],
            errorChildren: [],
        };

        this.state.compositions.concat(this.state.associatedEntities).filter(child => !this.state.deletions.includes(child.guid)).forEach(child => {
            const childState = this.fieldRefs[child.guid].getStateIfChanged();

            if (childState) {
                if (childState.errors) {
                    childData.errorChildren = childData.errorChildren.concat(childState.errors);
                }

                if (childState.data) {
                    childData.changedChildren.push(childState.data);
                }
            }
        });

        return childData;
    }

    determineParentXmiType() {
        if (this.state.associatedEntities.filter(ae => !this.state.deletions.includes(ae.guid)).length) {
            return "conceptual:Association";
        } else {
            return "conceptual:Entity";
        }
    }

    deleteElementChild = (child, childType) => {
        try {
          const { guid, rolename } = child;
          if (guid.startsWith("newChild")) {
              const newChildArray = Array.from(this.state[childType].filter(child => child.guid !== guid));
              this.setState({[childType]: newChildArray});
          } else {
              const field = this.fieldRefs[guid];
              DeletionConfirm2.show(guid, field.state.rolename, this.props.refreshData, true);
          }
        } catch (error) {
          console.error(error);
        }
    };

    createFieldRef = (guid, ele) => {
        this.fieldRefs[guid] = ele;
    };

    createRowElRef = (guid, ele) => {
        this.rowElRefs[guid] = ele;
    };

    getSpecializationTrace = (
        srcGuid = this.state.specializationSrcGuid,
        dstGuid = this.state.specializationDstGuid,
        doScroll = true
    ) => {
        const srcRow = this.rowElRefs[srcGuid];
        const dstRow = this.rowElRefs[dstGuid];

        if (!srcRow || !dstRow) return;

        this.setState({
            specializationSrcGuid: srcGuid,
            specializationDstGuid: dstGuid
        });

        if (doScroll) {
            dstRow.scrollIntoView({
                block: "center",
                behavior: "smooth"
            });
        }

        function getOffset(parent, refEl) {
            let offset = 0;
            while (refEl.parentElement !== parent) {
                offset += refEl.offsetTop;
                refEl = refEl.parentElement;
            }
            return offset + 3;
        }

        function setElementPos(pointer, offset) {
            pointer.style.display = "block";
            pointer.style.top = offset + "px";
        }

        const srcOffset = getOffset(this.attributeContainer, srcRow);
        const dstOffset = getOffset(this.attributeContainer, dstRow);

        setElementPos(this.specializationBracket, srcOffset + 9);
        this.specializationBracket.style.height = (dstOffset - srcOffset) + "px";

        setElementPos(this.specializationPointerSrc, srcOffset);
        setElementPos(this.specializationPointerDst, dstOffset);
    };

    clearSpecializationTrace = () => {
        this.specializationPointerSrc.style.display = "none";
        this.specializationPointerDst.style.display = "none";
        this.specializationBracket.style.display = "none";
    };

    render() {
        const canEdit = (this.props.canEdit && this.props.deprecated === "false");
        const phenomId = new PhenomId(this.props.idCtx);
        let rowRenderIdx = 0;

        return (
            <React.Fragment>
                <Notifications ref={el => this.noticeRef = el} />
                <SpecRollupPopup
                    children={this.props.children.filter(child => child.specializes && child.specializedBy && child.specializedBy.length === 0)}
                    possibleSpecializations={this.state.possibleSpecializations}
                    ref={ele => this.rollupModal = ele} refreshData={this.props.refreshData} idCtx={phenomId.gen()}/>
                <div ref={ele => this.specializationBracket = ele} style={{
                    display: "none",
                    position: "absolute",
                    left: 7,
                    width: 3,
                    background: "linear-gradient(180deg, rgba(255,0,0,1) 0%, rgba(0,255,0,1) 100%)"
                }}/>
                <span ref={ele => this.specializationPointerSrc = ele}
                      style={{
                          display: "none",
                          position: "absolute",
                          left: 2,
                          color: "red",
                          userSelect: "none"
                      }}>&#10148;</span>
                <span ref={ele => this.specializationPointerDst = ele}
                      style={{
                          display: "none",
                          position: "absolute",
                          left: 2,
                          color: "green",
                          userSelect: "none"
                      }}>&#10148;</span>
                <div ref={ele => this.attributeContainer = ele} id={phenomId.gen("attr","wrapper")}>
                    {["compositions", "associatedEntities"].map((childType) => {
                        const childArray = this.state[childType];
                        return (
                            <React.Fragment key={childType}>
                                {/* leave this div. it is important for trace bracket calculation */}
                                <div onKeyUp={this.props.onType} onClick={this.props.onType}>
                                    <LineLabel
                                        text={childType === "compositions" ? "Attributes" : "Participants"}
                                        style={{margin: "15px 0"}}
                                        errorMsg={(childType === "compositions" && this.props.uniquenessErrors !== undefined) ? `This element has the same attributes as:\n${this.props.uniquenessErrors}` : ""}
                                        idCtx={phenomId.genPageId(childType)}
                                    />
                                    <Grid
                                        id={phenomId.gen(["attr",childType],`grid`)}
                                        data={orderBy(childArray, this.state[childType + "Sort"] || [])}
                                        sortable
                                        sort={this.state[childType + "Sort"] || []}
                                        onSortChange={(e) => {
                                            this.setState({
                                                [childType + "Sort"]: e.sort
                                            });
                                        }}
                                        className="editorTable default-table2"
                                        rowRender={(_, props) => {
                                            const child = props.dataItem;
                                            const childIdx = rowRenderIdx++;
                                            return (<tr
                                                id={phenomId.genPageId(childType, childIdx)}
                                                ref={ele => this.createRowElRef(child.guid, ele)}
                                                className={this.state.deletions.includes(child.guid) ? "deleted-row" : ""}
                                                key={child.guid}>
                                                <EntityChild
                                                    ref={ele => this.createFieldRef(child.guid, ele)}
                                                    scrollToGuid={this.props.scrollToGuid}
                                                    canEdit={(this.props.canEdit && this.props.deprecated === "false")}
                                                    element={child}
                                                    new={child.guid.startsWith("newChild")}
                                                    parent_id={child.guid.startsWith("newChild") ? this.props.guid : null}
                                                    typeOptions={this.props.typeOptions.filter(typeOption => typeOption.guid !== this.props.guid && (childType === "compositions" || typeOption.xmiType !== "conceptual:Observable"))}
                                                    getSpecTrace={this.getSpecializationTrace}
                                                    allowSpecializationEdit={true}
                                                    genParentGuid={(this.props.specializes || {}).guid}
                                                    possibleSpecializations={this.state.possibleSpecializations}
                                                    curSpecSrc={this.state.specializationSrcGuid}
                                                    deprecated={this.props.deprecated === "true"}
                                                    deleteChild={() => this.deleteElementChild(child, childType)}
                                                    truncations={{
                                                        descriptions: this.state[`truncate${childType}Descriptions`]
                                                    }}
                                                    idCtx={phenomId.genPageId(childType, childIdx)}
                                                />
                                            </tr>);
                                        }}
                                        resizable>
                                        <GridNoRecords>
                                            No Data Is Available For This Table
                                        </GridNoRecords>
                                        <Column title="ROLENAME" field="rolename"/>
                                        <Column title="DESCRIPTION"
                                                headerCell={(props) => {
                                                    return <FormatMenuHeaderCell text={props.title}
                                                                                 useTruncation={(val) => this.setState({[`truncate${childType}Descriptions`]: val})}/>;
                                                }}/>
                                        {childType === "compositions" || <Column title="SOURCE BOUNDS"/>}
                                        <Column title="TARGET BOUNDS"/>
                                        <Column title="TYPE" field="type.name"/>
                                        {childType === "compositions" || <Column title="PATH"/>}
                                        <Column title="PROJECTORS"/>
                                        { <Column title="SPECIALIZES"/>}
                                        {childType !== "compositions" || !canEdit || <Column title="MOVE" width="40px"/>}
                                        {!canEdit || <Column title="DELETE" width="40px"/>}
                                    </Grid>
                                    {!this.props.canEdit || <Toolbar>
                                        <ToolbarItem>
                                            <Button
                                                id={phenomId.genPageId(childType, "create-btn")}
                                                disabled={this.props.deprecated === "true"}
                                                iconClass="fa fa-plus fa-fw"
                                                onClick={() => {
                                                    const newTable = Array.from(childArray);
                                                    const times = (childType === "compositions" || newTable.length) ? 1 : 2;
                                                    for (let i = 0; i < times; i++) {
                                                        newTable.push({
                                                            guid: `newChild${this.newChildId++}`,
                                                            parent_id: this.props.guid,
                                                            name: "",
                                                            xmiType: "conceptual" + (childType === "compositions" ? ":Composition" : ":AssociatedEntity"),
                                                            description: "",
                                                            type: {guid: "", name: "", xmiType: ""},
                                                            projections: false,
                                                            showViews: false,
                                                            deleted: false,
                                                        });
                                                    }
                                                    this.setState({
                                                        [childType]: newTable
                                                    });
                                                }}>
                                                Create
                                            </Button>
                                        </ToolbarItem>
                                    </Toolbar>}
                                </div>
                            </React.Fragment>
                        );
                    })}
                                <GenView specializes={this.props.specializes}
                                         createRowElRef={this.createRowElRef}
                                         getSpecTrace={this.getSpecializationTrace}
                                         deprecated={this.props.deprecated === "true"}
                                         possibleSpecializations={this.state.possibleSpecializations}
                                         idCtx={phenomId.genPageId()}/>
                </div>
            </React.Fragment>
        );
    }
}

export default EntityChildsView;
