import React from 'react';
import { isStormData } from '../../../util';
import { NodeComboBox, PhenomComboBox, PhenomInput, PhenomLabel, PhenomRadioButtons, PhenomSelect, PhenomTextArea } from '../../../../../util/stateless';
import { BaseNodeModel } from '../../../base/BaseNode';
import { getShortenedStringRepresentationOfXmiType } from '../../../../../util/util';
import { TransportChannelManager } from '../../../../../edit/integration/EditTransportChannel';
import ModalNodeBuilder from '../../../../../dialog/ModalNodeBuilder';
import SidePortDetail from './SidePortDetail';
import SideMessagePortDetail from './SideMessagePortDetail';
import { Button } from '@progress/kendo-react-buttons';
import { TREE_ICON_MAP } from '../../../../../tree/tree_constants';



class SideNodeDetail extends React.Component {
  
  renderHeader = () => {
    const { selectedNodeModel } = this.props;
    const stormData = selectedNodeModel.getStormData();
  
    return <div className="storm-side-header" style={{ background: selectedNodeModel.getNodeColor() }}>
        { getShortenedStringRepresentationOfXmiType(stormData.getXmiType()) }
      </div>
  }

  renderEquationNodeCell = () => {
    const { $app, $manager, selectedNodeModel, phenomId } = this.props;
    const stormData = selectedNodeModel.getStormData();

    let equationData = $app.getDiagramStormData(stormData.getAttr("test"));

    return <PhenomInput label="Test"
                        id={phenomId.genPageId("test")}
                        value={equationData?.getAttr("equation") || ""}
                        autoComplete="off"
                        onChange={(e) => {
                          if (!equationData) {
                            equationData = $manager.createStormData("im:Equation");
                            isStormData(equationData) && stormData.setAttr("test", equationData.getGuid())
                          }

                          if (isStormData(equationData)) {
                            equationData.setAttr("equation", e.target.value);
                            selectedNodeModel.fireEvent({}, 'nodeDataChanged');
                          }
                        }} />
  }

  renderTransportChannelCell = () => {
    const { $app, $manager, selectedNodeModel, phenomId } = this.props;
    const stormData = selectedNodeModel.getStormData();

    let associationData = $app.getDiagramStormData(stormData.getAttr("associationNode"));
    let transportChannelGuid = isStormData(associationData) && associationData.getAttr("Channel_Guid")
    // let selectedTransportChannel = transportChannelList.find(tc => isStormData(associationData) && tc.guid === associationData.getAttr("Channel_Guid"));

    return <div>
            <NodeComboBox label="Transport Channel"
                          xmiType="im:TransportChannel"
                          selectedGuid={transportChannelGuid}
                          id={phenomId.genPageId("transportChannel")}
                          autoComplete="off"
                          onChange={(tc) => {
                            if (!associationData) {
                              associationData = $manager.createStormData("im:TransporterNodeToTransportChannel");
                              if (isStormData(associationData)) { 
                                stormData.setAttr("associationNode", associationData.getGuid());
                                associationData.setAttr("Transporter_Guid", stormData.getGuid());
                              }
                            }

                            if (isStormData(associationData)) {
                              associationData.setAttr("Channel_Guid", tc?.guid);
                              selectedNodeModel.fireEvent({}, 'nodeDataChanged');
                            }
                          }}
                          onClickPlusIcon={() => {
                            ModalNodeBuilder.show({
                              component: TransportChannelManager,
                            });
                          }}
                          onClickCancelIcon={() => {
                            if (isStormData(associationData)) {
                              associationData.setAttr("Channel_Guid", "");
                              selectedNodeModel.fireEvent({}, 'nodeDataChanged');
                            }
                          }} />
            </div>
  }

  renderManualTransformCell = () => {
    const { $app, $manager, selectedNodeModel, transformCode, phenomId, fetchPreviewCode } = this.props;
    const stormData = selectedNodeModel.getStormData();

    return <>
            <PhenomRadioButtons label="Transform Type"
                                value={stormData.getAttr("transformType")}
                                data={["auto", "manual"]}
                                id={phenomId.genPageId("transformType")}
                                onChange={(e) => {
                                  stormData.setAttr("transformType", e.target.value);
                                  fetchPreviewCode();
                                  this.forceUpdate();
                                }} />

            {stormData.getAttr("transformType") === "manual" &&
              <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: 10 }}>
                {stormData.getChildren().map((mtGuid, idx) => {
                  // index starts at 2 default children, need to set back to 0 manually
                  const index = idx - 2;

                  if ($app.isNodeMarkedForDeletion(mtGuid)) {
                    return null;
                  }

                  const manualTransform = $app.getDiagramStormData(mtGuid);
                  if (!isStormData(manualTransform) || manualTransform.getXmiType() !== "im:ManualTransform") {
                    return null;
                  }

                  let equationData = $app.getDiagramStormData(manualTransform.getAttr("update"));
                  return <div key={mtGuid}>
                          <PhenomInput value={equationData?.getAttr("equation") || ""}
                                       autoFocus={true}
                                       placeholder="enter value"
                                       id={phenomId.genPageId("update-" + index)}
                                       autoComplete="off"
                                       onBlur={() => fetchPreviewCode()}
                                       onChange={(e) => {
                                        if (!equationData) {
                                          equationData = $manager.createStormData("im:Equation");
                                          isStormData(equationData) && manualTransform.setAttr("update", equationData.getGuid())
                                        }

                                        if (isStormData(equationData)) {
                                          equationData.setAttr("equation", e.target.value);
                                          selectedNodeModel.fireEvent({}, 'nodeDataChanged');
                                        }
                                       }}>
                            <Button icon="close" look="bare" title="Remove"
                                    id={phenomId.genPageId("remove")}
                                    onClick={() => {
                                      $app.markNodeForDeletion({
                                        data: manualTransform.serializeData(),
                                        position: [0, 0],
                                      });
                                      stormData.removeChild(mtGuid);
                                      fetchPreviewCode();
                                      this.forceUpdate();
                                    }} />
                          </PhenomInput>
                        </div> })}

              <Button icon="add"
                      id={phenomId.genPageId("add-update")}
                      onClick={() => {
                        $manager.createChildStormData("im:ManualTransform", stormData);
                        this.forceUpdate();
                      }}>Add Update</Button>
              </div> }

            <PhenomTextArea label="Transform Code Preview"
                            disabled
                            value={transformCode}
                            id={phenomId.genPageId("transformCode")}
                            autoComplete="off"
                            containerProps={{
                              style: {
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                              }
                            }} />
    </> }

  renderPortCells = () => {
    const { $app, selectedNodeModel, viewList, phenomId, fetchPreviewCode, templateTypeMap } = this.props;

    const outPorts = selectedNodeModel.getOutPorts();
    const inPorts = selectedNodeModel.getInPorts();

    return <div>
            {outPorts.length > 0 && <>
              {outPorts.map((port, idx) => {
                return <SidePortDetail key={port.getOptions().id}
                                        idx={idx}
                                        $app={$app}
                                        parentNodeModel={selectedNodeModel}
                                        attrData={port.getAttrData()}
                                        port={port}
                                        viewList={viewList}
                                        phenomId={phenomId}
                                        templateTypeMap={templateTypeMap}
                                        fetchPreviewCode={fetchPreviewCode} />                
              })}
                
            </> }
            {inPorts.length > 0 && <>
              {inPorts.map((port, idx) => {
                return <SidePortDetail key={port.getOptions().id}
                                        idx={idx}
                                        $app={$app}
                                        parentNodeModel={selectedNodeModel}
                                        attrData={port.getAttrData()}
                                        port={port}
                                        viewList={viewList}
                                        phenomId={phenomId}
                                        templateTypeMap={templateTypeMap}
                                        fetchPreviewCode={fetchPreviewCode} />
              })}
            </> }
      </div>
  }

  renderPortableComponentCell = () => {
    const { $app, selectedNodeModel, phenomId } = this.props;
    const stormData = selectedNodeModel.getStormData();

    const portableComponent = $app.getDiagramStormData(stormData.getAttr("realizes"));
    if (!portableComponent) {
      return null;
    }
  
    return <div>
              <PhenomLabel text="Service" />
              {portableComponent.getChildren()
                                .map(msgPortGuid => {
                                  const msgPortData = $app.getDiagramStormData(msgPortGuid);
                                  if (!isStormData(msgPortData) || msgPortData.getXmiType() !== "uop:MessagePort") {
                                    return null;
                                  }

                                  return <SideMessagePortDetail msgPortData={msgPortData}
                                                                $app={$app}
                                                                phenomId={phenomId} />
                                })}
           </div>
  }

  render() {
    const { $app, selectedNodeModel, attrData, port, viewList, phenomId } = this.props;

    if (selectedNodeModel instanceof BaseNodeModel === false) {
      return null;
    }

    const stormData = selectedNodeModel.getStormData();
    if (!isStormData(stormData)) {
      return null;
    }

    const xmiType = stormData.getXmiType();
    const composed_block = $app.findNode(stormData.getAttr("realizes"));

    return <section>

        { this.renderHeader() }

        <div className="storm-side-content phenom-content-scrollable">
          <PhenomInput label="Name"
                       value={stormData.getName()}
                       id={phenomId.genPageId("name")}
                       autoComplete="off"
                       onChange={(e) => selectedNodeModel.updateProp("name", e.target.value)} />
  
          <PhenomTextArea label="Description"
                          value={stormData.getDescription()}
                          id={phenomId.genPageId("description")}
                          autoComplete="off"
                          onChange={(e) => selectedNodeModel.updateProp("description", e.target.value)} />

          {composed_block &&
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}> 
            <img src={TREE_ICON_MAP["im:ComposedBlock"]} /> 
            <label>Instance of {composed_block.name}</label>
          </div>}
  
          {xmiType === "im:UoPInstance" &&
          <PhenomInput label="Configuration URI"
                       value={stormData.getAttr("configurationURI")}
                       id={phenomId.genPageId("configurationURI")}
                       autoComplete="off"
                       onChange={(e) => selectedNodeModel.updateProp("configurationURI", e.target.value)} /> }
        
          {xmiType === "im:FilterNode" && 
            this.renderEquationNodeCell() }

          {xmiType === "im:SIMAdapter" &&
          <PhenomInput label="Stale Time"
                       value={stormData.getAttr("staleTime")}
                       id={phenomId.genPageId("staleTime")}
                       autoComplete="off"
                       onChange={(e) => selectedNodeModel.updateProp("staleTime", e.target.value)} /> }
  
          {xmiType === "im:QueuingAdapter" &&
          <PhenomInput label="Queue Depth"
                       value={stormData.getAttr("queueDepth")}
                       id={phenomId.genPageId("queueDepth")}
                       autoComplete="off"
                       onChange={(e) => selectedNodeModel.updateProp("queueDepth", e.target.value)} /> }
  
          {xmiType === "im:ViewTransporterNode" && 
            this.renderTransportChannelCell() }

          {xmiType === "im:UoPInstance"
            ? this.renderPortableComponentCell()
            : this.renderPortCells() }

          {xmiType === "im:TransformNode" &&
            this.renderManualTransformCell() }
        </div>
    </section>
  }
}


export default SideNodeDetail;