import RootLeaf from "./RootLeaf";
import { getShortenedStringRepresentationOfXmiType } from "../../util/util";

export function convertNodeToLeaf(node) {
  const data = {};
  if (!node?.guid || !node?.xmiType) return data;
  
  data.guid = node.guid;
  data.n = node.name || node.rolename || getShortenedStringRepresentationOfXmiType(node.xmiType);
  data.t = node.xmiType;
  data.p = typeof node.parent === 'string' ? node.parent : node.parent?.guid;
  data.m = typeof node.subModelId === 'number' ? node.subModelId : null;
  data.x = Array.isArray(node.tags) ? node.tags : [];
  data.w = node.viewType || null;
  data.o = typeof node.childIndex === 'number' && node.childIndex > -1 ? node.childIndex : null;

  return data;
}

function NodeLeaf(data) {
  // inherit from RootLeaf
  RootLeaf.call(this);

  // MODEL NODE
  this.data = {
    guid: data.guid,
    name: data.n || data.name || data.rolename || (data.xmiType && getShortenedStringRepresentationOfXmiType(data.xmiType)),
    xmiType: data.t || data.xmiType,
    parent: data.p || data.parent?.guid || data.parent,
    modelId: typeof data.m === 'number' ? data.m :
             typeof data.subModelId === 'number' ? data.subModelId : null,
    tags: data.x || data.tags || [],
    viewType: data.w || data.viewType || null,
    childIndex: typeof data.o === 'number' && data.o > -1 ? data.o : 
                typeof data.childIndex === 'number' && data.childIndex > -1 ?  data.childIndex : null,
  }

  this.config = {
    ...this.config,
    hasMergableChildren: false,
    isMergeable: false,
    isReachable: true,
    isDeletedForMerge: data.l || false,
    isMovedForMerge: data.v || false,
    isFilteredForMerge: data.f || false,
  }

  //--------------------
  // GETTERS
  //--------------------
  /**
   * Used to save the node's settings.
   *    - When the page reloads or when the component remounts, the current settings need to be readded.
   *    - ignore "filteredOut" - this is handled by reapplying the filter
   *    - ignore "selected" - the page is reloading and nothing should be selected
   *
   * @returns javascript object
   */
  this.serializeConfig = () => {
    return {
      expanded: this.isExpanded(),
    }
  }

  this.getGuid = () => {
    return this.data.guid;
  }

  this.getName = () => {
    return this.data.name;
  }

  this.getXmiType = () => {
    return this.data.xmiType;
  }

  this.getParentGuid = () => {
    return this.data.parent;
  }

  this.getModelId = () => {
    return this.data.modelId;
  }

  this.getTags = () => {
    return this.data.tags;
  }

  this.hasTag = (tag) => {
    return this.data.tags.includes(tag);
  }

  this.isChildrenFilteredOut = () => {
    return this.getChildrenLeaves().every(childLeaf => childLeaf.isFilteredOut());
  }

  /**
   * Used in Push/Pull/Approve pages
   *
   */
  this.isMergeCandidate = () => {
    return this.config.isMergeable;
  }

  this.hasMergableChildren = () => {
    return this.config.hasMergableChildren;
  }

  this.isMarkedForMerge = () => {
    return this.isChecked();
  }

  this.isDeletedForMerge = () => {
    return this.config.isDeletedForMerge;
  }

  this.isFilteredForMerge = () => {
    return this.config.isFilteredForMerge;
  }

  this.isMovedForMerge = () => {
    return this.config.isMovedForMerge;
  }

  this.isReachable = () => {
    return this.config.isReachable;
  }

  //--------------------
  // SETTERS
  //--------------------
  this.updateData = (node={}) => {
    const uName = node.n || node.name;
    const uType = node.t || node.xmiType;
    const uParent = node.p || node.parent;
    const uModel = node.m || node.modelId;
    const uChildIdx = node.o || node.childIndex;

    this.data = {
      ...this.data,
      guid: node.guid,
      name: uName,
      xmiType: uType,
      parent: uParent,
      modelId: uModel,
      childIndex: typeof uChildIdx === 'number' && uChildIdx > -1 ? uChildIdx : null,
    }

    // for Push/Pull/Approve pages
    if (node.l) {
      this.config = {
        ...this.config,
        isDeletedForMerge: node.l,
        isMovedForMerge: node.v,
      }
    }

    this.forceUpdate();
  }

  this.updateTags = (tags=[]) => {
    this.data = {
      ...this.data,
      tags,
    }
  }

  /**
   * Used in Push/Pull/Approve pages
   *
   */
  this.setMergeCandidate = () => {
    this.config = { ...this.config, isMergeable: true };
    this.getParentLeaf() && this.getParentLeaf().setExpanded(true);
  }

  this.setHasMergeCandidateChildren = (bool) => {
    this.config = { ...this.config, hasMergableChildren: bool };
  }

  this.setReachable = (bool) => {
    this.config.isReachable = bool;
  }

  this.markForMerge = (bool) => {
    this.setChecked(bool);
    bool && this.getParentLeaf() && this.getParentLeaf().setExpanded(true);
  }
}

NodeLeaf.prototype = Object.create(RootLeaf.prototype);
NodeLeaf.prototype.constructor = NodeLeaf;

export default NodeLeaf;
