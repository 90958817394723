import React from "react";
import PhenomId from "../../../requests/phenom-id";
import { PhenomLabel, ColorCollapsable } from "../../util/stateless";
import NavTree from "../../tree/NavTree";
import { BasicAlert } from "../../dialog/BasicAlert";
import { getShortenedStringRepresentationOfXmiType } from "../../util/util";
import { Checkbox } from '@progress/kendo-react-inputs';
import { _ajax } from "../../../requests/sml-requests";

class MergeSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        creations: {},
        deletions: {},
        moves: {},

        categoryChecked: {
          creations: {},
          deletions: {},
          moves: {},
        },
    }
  }

  componentDidMount() {
    const { page } = this.props
    if (["pull", "push"].includes(page)) this.fetchMergeNodes(page);
  }

  sortMergeCandidates = (mergeCandidates) => {
    const { creations, deletions, moves, categoryChecked } = this.state;
    Array.from(mergeCandidates).forEach((leaf) => {
      const xmiType = leaf.getXmiType();
      switch (true) {
        case !!leaf.config.isDeletedForMerge:
          (deletions[xmiType] = deletions[xmiType] || []).push(leaf);
          categoryChecked.deletions[xmiType] = false;
          break;
        case !!leaf.config.isMovedForMerge:
          (moves[xmiType] = moves[xmiType] || []).push(leaf);
          categoryChecked.moves[xmiType] = false;
          break;
        default:
          if (!NavTree.isMovedInNode(leaf.getGuid())) {
            (creations[xmiType] = creations[xmiType] || []).push(leaf);
            categoryChecked.creations[xmiType] = false;
          }
      }
    });
    this.setState({ creations, deletions, moves });
  }

  clearMergeSummary = () => {
    this.setState({
      creations: {},
      deletions: {},
      moves: {},
      categoryChecked: {
        creations: {},
        deletions: {},
        moves: {},
      },
    });
  }

  fetchMergeNodes = () => {
    const { page } = this.props;
    this.clearMergeSummary();
    BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);
    NavTree.fetchTreeWithMergeCandidates(page)
           .finally(() => {
              this.sortMergeCandidates(NavTree.getAllMergeCandidates());
              BasicAlert.hide();
           })
  }

  fetchPushRequestNodes = (pendingRequests, requestsInView) => {
    if (this.props.page !== "approve") return;
    this.clearMergeSummary();
    BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);
    const lastId = requestsInView[requestsInView.length - 1];
    const lastReq = lastId && pendingRequests.find(r => r.id === lastId);
    const requests = [this.fetchMergeRequests(requestsInView)];
    if (lastReq) {
      requests.push(NavTree.fetchDependencyMap("approve", lastReq.modelId));
    }
    Promise.all(requests)
           .then((res) => {
            if (!res[0]?.data) return;
            NavTree.addMergeCandidates(JSON.parse(res[0].data))
           })
           .finally(() => {
            this.sortMergeCandidates(NavTree.getAllMergeCandidates());
            BasicAlert.hide();
           })
  }

  fetchMergeRequests = (requestsInView) => {
    return _ajax({
      url: "/index.php?r=/referencing-model/fetch-merge-request/",
      data: { 
        childBranchRefIds: requestsInView 
      },
    })
 }

  mergeNodesCount = (mergeTypeNodes) => {
    return Object.values(mergeTypeNodes).reduce((a, v) => a + v.length, 0);
  }

  handleSelectCategory = (e, mergeType) => {
    e.stopPropagation();
    const mergeTypeNodes = this.state[mergeType];
    Object.keys(mergeTypeNodes).forEach((xmiType) => {
      if (e.target.checked && !this.state.categoryChecked[mergeType][xmiType] || 
         !e.target.checked && this.state.categoryChecked[mergeType][xmiType]) {
        this.handleCheckXmiType(mergeType, xmiType);
      }
    });
  }

  handleCheckXmiType = (mergeType, xmiType) => {
    const { categoryChecked } = this.state;
    const check = !categoryChecked[mergeType][xmiType];
    const mergeNodes = this.state[mergeType][xmiType];

    NavTree.selectMergeCandidates(mergeNodes.map(n => n.getGuid()), check);
    categoryChecked[mergeType][xmiType] = check;
    this.setState({ categoryChecked });
  }
 
  renderCollapsableContent = (mergeType) => {
    const mergeTypeNodes = this.state[mergeType];
    const { categoryChecked } = this.state;
    const phenomId = new PhenomId("merge-summary");
    return Object.keys(mergeTypeNodes).length === 0 ?
      false :
      <div id={phenomId.gen(["merge-nodes",mergeType],"wrapper")}>
        {Object.keys(mergeTypeNodes).map((xmiType) => {              
          return <>
            <span onClick={(e) => e.stopPropagation()}
                  style={{ display: "block", margin: "15px 20px" }}>
              <Checkbox label={getShortenedStringRepresentationOfXmiType(xmiType)}
                        id={phenomId.gen(["collapsable-checkbox",mergeType], xmiType)}
                        checked={categoryChecked[mergeType][xmiType]}
                        onClick={(e) => this.handleCheckXmiType(mergeType, xmiType)} />
              {mergeTypeNodes[xmiType].map((node) => {
                return <p style={{ margin: "0 30px" }}>{node.getName()}</p>
              })}
            </span>
          </>
        })}
      </div>
  }

  renderCollapsableHeading = (text, mergeType, onClick) => {
    const xmiTypesChecked = Object.values(this.state.categoryChecked[mergeType]);
    const mergeTypeNodes = this.state[mergeType];
    const count = this.mergeNodesCount(mergeTypeNodes);
    const allSelected = xmiTypesChecked.length > 0 && xmiTypesChecked.reduce((a, v) => a && v, true);
    const phenomId = new PhenomId("merge-summary")

    return <div style={{ margin: "5px 0", display: "flex" }}>
      <Checkbox label={false}
                id={phenomId.gen("collapsable-checkbox", mergeType)}
                checked={allSelected}
                onClick={(e) => onClick(e, mergeType)}
                disabled={count === 0} />
      <p style={{ margin: "auto 4px", fontWeight: "bold" }}>{`${text} (${count})`}</p> 
    </div>
  }

  render() {
    const { creations, deletions, moves } = this.state;
    const phenomId = new PhenomId("merge-summary",this.props.idCtx);
    
    return <div id={phenomId.gen("","wrapper")} style={{ marginTop: "20px" }}>
      <PhenomLabel text="Merge Summary"/>
      <ColorCollapsable headingComponent={this.renderCollapsableHeading("Node Creations and Edits", "creations", this.handleSelectCategory)} 
                        color={"#489bb3"}
                        content={this.renderCollapsableContent("creations")}
                        vMargin={15}
                        idCtx={phenomId.gen("","creations-table")}
                        contentId={phenomId.gen(["merge-nodes","creations"],"wrapper")}
                        default={true}
                        noToggle={this.mergeNodesCount(creations) === 0} />
      <ColorCollapsable headingComponent={this.renderCollapsableHeading("Node Deletions", "deletions", this.handleSelectCategory)}
                        color={"#8b0000"}
                        content={this.renderCollapsableContent("deletions")}
                        vMargin={15}
                        idCtx={phenomId.gen("","deletions-table")}
                        contentId={phenomId.gen(["merge-nodes","deletions"],"wrapper")}
                        default={true}
                        noToggle={this.mergeNodesCount(deletions) === 0} />
      <ColorCollapsable headingComponent={this.renderCollapsableHeading("Node Moves", "moves", this.handleSelectCategory)}
                        color={"#833b83"}
                        content={this.renderCollapsableContent("moves")}
                        vMargin={15}
                        idCtx={phenomId.gen("","moves-table")}
                        contentId={phenomId.gen(["merge-nodes","moves"],"wrapper")}
                        default={true}
                        noToggle={this.mergeNodesCount(moves) === 0} />
    </div>;
  }
}
  
export default MergeSummary