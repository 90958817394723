import React from 'react';
import ReactTooltip from "react-tooltip";
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {PortWidget} from "@projectstorm/react-diagrams-core";
import {cloneDeep} from 'lodash';
import PhenomId from "../../../../requests/phenom-id";
import { Checkbox } from "@progress/kendo-react-inputs"
import { isNestingChar } from '../../../util/util';

import {
    modifiedPairsForward, 
    isDiagramNode, 
    defaultCharacteristic,
    formatPathAttr,
    createPathPortGuid,
    serializeViewData,
} from "../util";

import { BasicConfirm } from '../../../dialog/BasicConfirm';


import {
    DiagramNodeModel,
    DiagramNodeWidget,

    NodeContainer, 
    NodeHalo, 
    NodeHeader, 
    NodeInput, 
    AttributeTable,
    AttributeRow,
    AttributeHeaderRow,
    AttributeCell, 
    AttributeCellFixedSize,
    SelectionOverlay,
    NodeOuterPorts,
    DropZone,

    CharRow,
    CharRowEdit,
    ViewAttribute,
} from '../index';



// ================================



// ================================




export class ViewNodeFactory extends AbstractReactFactory {
    constructor() {
        super("view-node");
    }

    generateModel(event) {
        return new ViewNodeModel();
    }

    generateReactWidget(event) {
        return <ViewNodeWidget engine={this.engine} node={event.model}/>;
    }
}


export class ViewNodeModel extends DiagramNodeModel {
    constructor(options = {}, $app) {
        super(
          {
            ...options,
            type: "view-node"
          }, $app);
    }

    /*
     * Saving and Loading Data
     */
	  serialize() {
        return {
            ...super.serialize(),
            nodeData: serializeViewData(this.getNodeData()),
            inPortMap: this.inPortMap,
            outPortMap: this.outPortMap,
        };
    }

    async deserialize(event) {
        // reassign $app
        this.$app = event.engine.$app;
        const guid = event.data.nodeData.guid;
        let nodeData = this.$app.getOptionNode(guid);
                
        this.options.nodeData = nodeData || event.data.nodeData;
        this.originalData = cloneDeep(nodeData || event.data.nodeData);
        this.inPortMap = event.data.inPortMap;
        this.outPortMap = event.data.outPortMap;
        
        super.deserialize(event);
    }


    // override
    removePathLinks = (viewChild) => {
        if(typeof viewChild === 'string') {
            const portGuids = viewChild.split("--");
            viewChild = this.getNodeData().children.find(child => child.guid === portGuids[0]);
        }
        
        try {
            if(!viewChild) throw "invalid characteristic, failed to remove link.";
            modifiedPairsForward(viewChild.pathPairs, viewChild.projectedCharacteristic);
    
            // this.$app.storePathViewData(this, viewChild);

            // remove Path Links in reverse order
            for(let idx = viewChild.pathPairs.length - 1; idx >= 0; idx--) {
                const pair = viewChild.pathPairs[idx];
                const portGuid = createPathPortGuid(pair, {viewChild, pathPos: idx});

                
                // modifiedPairsForward(viewChild.pathPairs, viewChild.projectedCharacteristic);
                this.$app.removeHop(pair, portGuid);
            }

            // remove ProjChar Link
            const portCharGuid = createPathPortGuid(viewChild, {viewChild, pathPos: 0});
            this.removeLink(portCharGuid);
    
            // this.$app.stopPathBuilder();
        } catch (error) {
            console.error(error);
        }
    }
}






class ViewNodeWidget extends DiagramNodeWidget {

    constructor(props) {
        super(props);

        this.createNewPlaceholder();
        this.editNameOnMount = this.nodeModel.getNodeData().guid && isDiagramNode(this.nodeModel.getNodeData().guid);
        this.hidden = [];
    }

    // override
    componentDidUpdate(_, prevState) {
        if(prevState.isEditing !== this.state.isEditing) {
            ReactTooltip.hide();
        }

        if(this.editNameOnMount && this.nameRef) {
            this.nameRef.focus();
            this.nameRef.select();
            this.editNameOnMount = false;
        }
    }

    // sortChildren = () => {
    //     const hidden = [];

    //     this.nodeModel.getNodeData().children.forEach(child => {
    //         if(this.state.hideAttr.has(child.guid)) {
    //             hidden.push(child);
    //         } 
    //     })

    //     this.hidden = hidden;
    // }



    /*
     * Actions
     */
    createNewPlaceholder = () => {
        this.placeholder = this.createAttrNode("", true);
    }

    createAttrNode = (rolename="", isPlaceholder=false) => {
        const node = cloneDeep(defaultCharacteristic);
            node.guid = this.$app.props.manager.createDiagramId();
            node.rolename = rolename;
            node.parent = this.nodeModel.getNodeData().guid;
            node.isPlaceholder = isPlaceholder;

        return node;
    }

    createNewCharacteristic = (dstData) => {
        let rolename = dstData ? dstData.name[0].toLowerCase() + dstData.name.substring(1) : "";
        const srcData = this.createAttrNode(rolename);

        this.nodeModel.getNodeData().children.push(srcData);
        this.forceUpdate();
        return srcData;
    }

    getObsOrViewData = (char) => {
        if(isNestingChar(char)) {
            return char.viewType;
        } else {
            let observable = {};
            const measurement = this.$app.getOptionNode(char.measurement);
            if(measurement) observable = this.$app.getOptionNode(measurement.realizes);
            return observable;
        }
    }

    // Toggle Char's Path
    expandAttrPath = async (attr) => {
        if(!attr || !attr.guid || !attr.pathPairs.length) return;

        const hasOutPort = createPathPortGuid(attr, {viewChild: attr, pathPos:0}) in this.nodeModel.getOutPorts();
        const loadingDiagram = this.$app.state.loadingDiagram;

        // Remove Path if it is displayed
        if(hasOutPort && !loadingDiagram) {
            this.nodeModel.removePathLinks(attr.guid);
            this.$app.engine.repaintCanvas();

        // Create Path Connectors
        } else {
            await this.$app.startPathBuilder(this.nodeModel, attr);
            await this.$app.buildExistingPath(attr, attr.pathPairs, false);
    
            if(isNestingChar(attr)) {
                this.$app.stopPathBuilder();
            }
        }
    }

    handleEditChild = (attr) => {
        if(!attr || !attr.guid) return;
        
        this.$app.props.manager.charModalRef.show(attr, null, true, async (childData) => {
            Object.entries(childData).forEach(([key, val]) => {
                attr[key] = val;
            });
            
            await this.nodeModel.forceWidgetToUpdate(false);
            ReactTooltip.rebuild();
        });
    }

    actionUpdateChildName = (e, child) => {
        this.updateAttr(child, "rolename", e.target.value);
    }

    actionUpdateAttributeKind = (child, value) => {
      this.updateAttr(child, "attributeKind", value);
    }

    actionExpandViewAttr = async (child, view) => {
        if(!child || !view) return;
        const hasOutPort = child.guid in this.nodeModel.getOutPorts();
        
        if(hasOutPort) {
            this.nodeModel.removeLink(child.guid);
        } else {
            this.$app.switchToProjectionMode();
            const viewNodeModel = await this.expandTypeNode(child, view);
            this.nodeModel.establishLink(child, viewNodeModel);
        }
    }

    renderChildren = () => {
      const nodeData = this.nodeModel.getNodeData();
      const isParentComposite = nodeData.structureKind === "composite";
      const { hiddenAttributeGuids, showHiddenAttributes } = this.nodeModel.getSettings();
      const { isEditing, hideAttr, showHiddenAttrs } = this.state;
      const appState = this.$app.state;
      const threeDots = !hiddenAttributeGuids.size ? null :
                          <AttributeRow node={{}}
                                        style={{cursor:"pointer", paddingLeft:5}} 
                                        onClick={() => this.nodeModel.toggleShowHiddenAttributes()}>
                                              ...</AttributeRow>;

      if (isEditing) {
        var attrList = [nodeData.children, [this.placeholder]];                   
      } else {
        var attrList = [nodeData.children, ["threeDots"], nodeData.children];     
      }

      return attrList.map((items, idx) => (
        items.map((c, jdx) => {
          if (c === "threeDots") return threeDots;
          if (isParentComposite && c.isPlaceholder) return;

          if (!isEditing) {
            if ((idx === 0 && hiddenAttributeGuids.has(c.guid)) ||                                  // idx 0 (main section) and attr is hidden, then return null
                (idx === 2 && (!showHiddenAttributes || !hiddenAttributeGuids.has(c.guid)))) {      // idx 2 (hidden section) and attr is not hidden, then return null
                  return null;
            }
          }

          // if(hideAttr.has(c.guid) && idx !== 2) return null;
          // if(idx === 2 && !showHiddenAttrs) return null;

          const isUncommitted = appState.showUncommitted && c.isUncommitted;
          const obsViewData = this.getObsOrViewData(c);
          const hasPath = c.pathPairs && !!c.pathPairs.length;
          const hasOutPort = c.guid in this.nodeModel.getOutPorts() || `${c.guid}--${c.guid}--0` in this.nodeModel.getOutPorts();
          let domId = c.isPlaceholder ? "char-placeholder" : 
                            idx === 2 ? `char-hidden-${jdx}` : `char-${jdx}`;

          return <ViewAttribute key={`view-node-${c.guid}`}
                                id={this.phenomId.genPageId(domId)}
                                attr={c}
                                $app={this.$app}
                                obsViewData={obsViewData}
                                parentModel={this.nodeModel}
                                hasPath={hasPath}
                                hasOutPort={hasOutPort}
                                isParentComposite={isParentComposite}
                                isEditing={isEditing}
                                isHidden={hiddenAttributeGuids.has(c.guid)}
                                isUncommitted={isUncommitted}
                                isDeletable={!c.isPlaceholder}
                                actionToggle={this.nodeModel.toggleHiddenAttributeGuid}
                                actionUpdateChildName={this.actionUpdateChildName}
                                actionToggleEditing={this.toggleEditMode}
                                actionExpandPath={this.expandAttrPath}
                                actionExpandViewAttr={this.actionExpandViewAttr}
                                actionEditChar={this.handleEditChild}
                                actionUpdateAttributeKind={this.actionUpdateAttributeKind}
                                ref={el => this.childrenRef[c.guid] = el} />
        })
      ))
    }

    render() {
        const nodeData = this.nodeModel.getNodeData();
        const isComposite = nodeData.structureKind === "composite";
        const { hiddenAttributeGuids } = this.nodeModel.getSettings();
        const appState = this.$app.state;
        const isUncommitted = appState.showUncommitted && nodeData.isUncommitted;
        const isNewNode = appState.showUncommitted && isDiagramNode(nodeData.guid);
        
        const showOverlay = appState.modeAction === "select-a-projChar" || appState.modeAction === "select-a-hop";

        return (<>
            <NodeContainer id={this.phenomId.genPageId("-view-container")}
                            data-id={nodeData.guid}
                            style={{width: this.nodeModel.width || null}} 
                            onDoubleClick={this.handleDoubleClick}
                            ref={el => this.widgetRef = el}
                            onKeyPress={(e) => {
                                if(e.charCode === 13) this.toggleEditMode(false)
                            }}
                            onClick={(e) => {
                                if(this.nodeModel.isSelected()) {
                                    this.$app.sidePanelRef.current.load(this.nodeModel);
                                }      
                            }}>

                <DropZone data-drop-zone="view-node" />

                <SelectionOverlay show={showOverlay} />

                <NodeHalo selected={this.nodeModel.options.selected} />

                <NodeHeader id={this.phenomId.genPageId("header")}
                            xmiType={nodeData.xmiType}
                            uncommitted={isNewNode}
                            data-tip={nodeData.description} data-for="diagramTip">
                    {this.state.isEditing ?
                        <NodeInput ref={el => this.nameRef = el}
                                    type="text"
                                    style={{padding:"0 10px"}}
                                    id={this.phenomId.genPageId("name-input")}
                                    value={nodeData.name}
                                    placeholder="Name"
                                    onChange={(e) => {
                                        this.nodeModel.updateProp("name", e.target.value);
                                        this.forceUpdate();
                                    }} /> :
                        `${nodeData.name}`
                    }
                </NodeHeader>


                <AttributeTable xmiType={nodeData.xmiType}  uncommitted={isUncommitted}>
                    <AttributeHeaderRow hide={!this.state.isEditing} 
                                        style={{overflowX:"hidden"}}>
                        <AttributeCellFixedSize checkbox header>
                            <input id={this.phenomId.genPageId("edit-attrs-hide-all-toggle")}
                                    type="checkbox"
                                    style={{margin:0, height:24}}
                                    tabIndex="-1"
                                    checked={hiddenAttributeGuids.size === 0}
                                    onChange={() => this.nodeModel.toggleHiddenAttributeGuids()}/>
                        </AttributeCellFixedSize>
                            
                        <AttributeCell header style={{height:24}}>Rolename</AttributeCell>

                        <AttributeCell medium header style={{height:24}}>{isComposite ? "View Type" : "Observable"}</AttributeCell>
                        {!isComposite && <>
                          <AttributeCellFixedSize char header style={{fontSize:10, height:24, padding:0, textAlign:"center"}}>Private</AttributeCellFixedSize>
                          <AttributeCellFixedSize char header style={{fontSize:10, height:24, padding:0, textAlign:"center"}}>Expand Path</AttributeCellFixedSize>
                          <AttributeCellFixedSize char header style={{fontSize:10, height:24, padding:0, textAlign:"center"}}>Edit Char</AttributeCellFixedSize>
                          <AttributeCellFixedSize char header style={{fontSize:10, height:24, padding:0, textAlign:"center"}}>New Path</AttributeCellFixedSize>
                        </>}
                    </AttributeHeaderRow>
                    {this.renderChildren()}
                </AttributeTable>

                <NodeOuterPorts>
                    {Object.entries(this.nodeModel.getOutPorts()).map(([portGuid, portName]) => {
                        const port = this.nodeModel.getPort(portName);
                        if(!port) return null;

                        const isPathPort = port.options.type === 'path-port';
                        const charGuid = portGuid.split("--")[0];
                        
                        const { absX, absY, pathX, pathY } = this.getNewPortPosition(port, {searchChildGuid: charGuid, displayInChildRow: true});

                        if(pathX && pathY) {
                            port.setPosition(pathX, pathY);
                        }
                        
                        return (
                            <PortWidget engine={this.props.engine} 
                                    key={port.options.id}
                                    port={port}
                                    style={{
                                        position: "absolute",
                                        left: absX,
                                        top: absY
                                    }} />
                        )
                    })}

                    {Object.entries(this.nodeModel.getInPorts()).map(([portGuid, portName]) => {
                        const port = this.nodeModel.getPort(portName);
                        if(!port) return null;

                        const isPathPort = port.options.type === 'path-port';
                        const { absX, absY, pathX, pathY } = this.getNewPortPosition(port, {});
                        
                        if(isPathPort && pathX && pathY) {
                            port.setPosition(pathX, pathY);
                        }
                        
                        return (
                            <PortWidget engine={this.props.engine} 
                                    key={port.options.id}
                                    port={port}
                                    style={{
                                        position: "absolute",
                                        left: absX,
                                        top: absY
                                    }} />
                        )
                    })}
                </NodeOuterPorts>

                <div>
                    <div id={this.phenomId.genPageId("resize-left-bar")} className="resize-left" onMouseDown={this.startResize} />
                    <div id={this.phenomId.genPageId("resize-right-bar")} className="resize-right" onMouseDown={this.startResize} />
                </div>
            </NodeContainer>
        </>);
    }
}