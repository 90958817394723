import React from 'react'
import { Checkbox } from '@progress/kendo-react-inputs';
import { DEFAULT_ICON, TREE_ICON_MAP } from "./tree_constants";



export const NavTreeRoot = (props) => {
  const { rootLeaf } = props;
  if (!rootLeaf) return null;

  return (props.isSortByName ? rootLeaf.getSortedChildrenLeaves() : rootLeaf.getChildrenLeaves()).map((leaf) => {
            return <NavTreeLeaf key={leaf.getGuid()}
                                leaf={leaf}
                                config={leaf.getConfig()}    // when config "changes" it will trigger a rerender with React.memo
                                {...props} />
         })
}





export const NavTreeLeaf = React.memo(class extends React.Component {
  leafRef = React.createRef();

  toggleExpanded = (e) => {
    e.stopPropagation();
    const { leaf, onExpandLeaf } = this.props;
    onExpandLeaf && onExpandLeaf(leaf);
    this.forceUpdate();
  }

  handleClick = (e) => {
    e.stopPropagation();
    const { leaf, onSelectLeaf } = this.props;
    if (!onSelectLeaf) return;
    onSelectLeaf(e, leaf);
  }

  handleDoubleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { leaf, onDoubleClick } = this.props;
    onDoubleClick && onDoubleClick(leaf);
  }

  handleContextMenu = (e) => {
    const { leaf, onContextMenu } = this.props;
    onContextMenu && onContextMenu(e, leaf);
  }

  handleDragStart = (evt) => {
    const { leaf, onDragStart } = this.props;
    return !!onDragStart && onDragStart(evt, leaf);
  }

  handleOnDrop = (evt) => {
    const { leaf, onDrop } = this.props;
    return !!onDrop && onDrop(evt, leaf);
  }

  handleOnDragOver = (evt) => {
    const { leaf, onDragOver } = this.props;
    return !!onDragOver && onDragOver(evt, leaf);
  }

  renderCaret() {
    const { leaf } = this.props;
    // if every child is "hidden" then return null (an empty array will return a result of true)
    if (leaf.getChildrenLeaves().every(c => c.isFilteredOut())) return null;

    const classes = ["tree-node-caret", "fas"];
    if (leaf.isExpanded()) {
      classes.push("fa-caret-down");
    } else {
      classes.push("fa-caret-right");
    }

    return <span className={classes.join(" ")} 
                 onClick={this.toggleExpanded} />
  }

  renderCheckbox() {
    const { leaf, onCheckLeaf, isModelGenPage } = this.props;

    if (!onCheckLeaf) {
      return null;
    }

    if (isModelGenPage || leaf.isMergeCandidate() || leaf.hasMergableChildren()) {
      return <span onClick={(e) => e.stopPropagation()}>
                <Checkbox label=""
                          checked={leaf.isChecked()}
                          onClick={(e) => onCheckLeaf(e, leaf)} />
             </span>
    }

    return null;
  }

  renderMergeableIcon() {
    const { leaf } = this.props;
    if (!leaf.isReachable()) {
      return <span className="fas fa-diamond-exclamation"/>
    }

    if (!leaf.isMergeCandidate()) return null;
    if (leaf.isDeletedForMerge()) {
      return <span className="fas fa-trash"/>;
    } else if (leaf.isMovedForMerge()) {
      return <span className="fas fa-exchange-alt"/>;
    } else if (leaf.isFilteredForMerge()) {
      return <span className="fa fa-filter"/>;
    } else {
      return <span className="fas fa-plus-circle" />
    }
  }

  renderTypeIcon() {
    const { leaf } = this.props;
    switch (leaf.getXmiType()) {
      case "skayl:DiagramContext":
        return <span className="far fa-image" style={{ fontSize: 20 }} />
      default:
        return <img src={TREE_ICON_MAP[leaf.getXmiType()] || DEFAULT_ICON} alt={leaf.getXmiType()} />
    }
  }

  renderChildren() {
    const { leaf, isSortByName } = this.props;
    if (!leaf.isExpanded() || leaf.getChildrenLeaves().length < 1) return null;

    return <ul>
            {(isSortByName ? leaf.getSortedChildrenLeaves() : leaf.getChildrenLeaves()).map((childLeaf) => {

              // prevent react from yelling about duplicate keys
              let key = childLeaf.getGuid();
              if (childLeaf.isMergeCandidate()) {
                key += " merge";
              }

              return <NavTreeLeaf key={key} 
                                  {...this.props} 
                                  leaf={childLeaf}
                                  config={childLeaf.getConfig()} />
            })}
          </ul>
  }

  render() {
    const { id, leaf } = this.props;
    if (leaf.isFilteredOut()) return null;

    const classes = ["tree-node"];

    if (leaf.isAutoSelected()) {
      classes.push("autoSelected");
    }

    if (leaf.isSelected()) {
      classes.push("selected");
    }

    if (leaf.isHighlighted()) {
      classes.push("highlighted");
    }

    if (leaf.isMergeCandidate()) {
      classes.push("mergeable");
    }

    if (leaf.isDeletedForMerge()) {
      classes.push("highlighted");
    }

    if (leaf.isMovedForMerge()) {
      classes.push("mergemoved");
    }

    if (leaf.isFilteredForMerge()) {
      classes.push("mergefiltered");
    }

    if (!leaf.isReachable()) {
      classes.push("unreachable");
    }

    return <li>
            <span id={id ? `${id}-${leaf.getGuid()}` : null}
                  className={classes.join(" ")}
                  data-xmitype={leaf.getXmiType()}
                  onClick={this.handleClick}
                  onDoubleClick={this.handleDoubleClick}
                  onContextMenu={this.handleContextMenu}
                  onDragStart={this.handleDragStart}
                  onDragOver={this.handleOnDragOver}
                  onDrop={this.handleOnDrop}
                  onDragLeave={this.props.onDragLeave}
                  draggable={this.props.isDraggable}
                  ref={this.leafRef}>
              { this.renderCaret() }
              { this.renderCheckbox() }
              { this.renderTypeIcon() }
              { leaf.getName() }
              { this.renderMergeableIcon() }
            </span>
            { this.renderChildren() }
          </li>
  }
})
