import React, {Component} from "react";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {modelDeprecateNodeMulti} from "../../requests/sml-requests";
import {PhenomLink} from "../widget/PhenomLink";
import {Notifications2} from "../edit/notifications";
import {ColorCollapsable} from "../../components/util/stateless.jsx";
import $ from "jquery";
import { getActiveChangeSetId, removeNodes } from "../../requests/actionCreators";
import { withRouter } from "react-router-dom";
import { PHENOM_URL } from "../../global-constants";
import { getCurrentModelRefIndex } from "../../requests/actionThunks";
import { _ajax } from "../../requests/sml-requests";

class DeletionConfirm2 extends Component {
    constructor(props) {
        super(props);
        DeletionConfirm2.__instance = this;

        this.state = {
            visible: false,
            loading: true,
            additionalDeletions: {},
            additionalSseverances: {},
            messages: [], // handled by smm-can-delete-nodes
            flags: [],    // handled by smm-can-delete-nodes
            deprecateOnly: false,
        }

        this.__hide = this.__hide.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.doDeprecate = this.doDeprecate.bind(this);
        this.doUndeprecate = this.doUndeprecate.bind(this);
        this.receiveResponse = this.receiveResponse.bind(this);
        this.reEvalDeletion = this.reEvalDeletion.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.response_data = null;
        this.severances = null;
    }

    /**
     * 
     * @param {guid} guid 
     * @param {string} name 
     * @param {func} reloadNode 
     * @param {bool} isChild 
     * 
     */
    static show(guid, name, reloadNode = null, isChild = false) {
        DeletionConfirm2.__instance.setState({
            visible: true,
            guid: guid,
            name: name,
            reloadNode: reloadNode,
            isChild: isChild,
            deprecateOnly: false,
        }, DeletionConfirm2.__instance.__show);
    }

    static showMulti(guids=[], name, reloadNode = null, isChild = false) {
        const that = DeletionConfirm2.__instance;

        that.setState({
            visible: true,
            guid: guids[0],
            name: name,
            reloadNode: reloadNode,
            isChild: isChild,
            deprecateOnly: false,
        }, () => {
            that.determineDeletability({
                guids,
                flags: ["multi"], // value to disable flags and messages handling
            }).then(that.receiveResponse);
        })
    }

    __show(){
        this.determineDeletability({
            guid: this.state.guid,
            flags: this.state.flags,
        }).then(this.receiveResponse);
    }

    __hide() {
        this.setState({
            visible: false,
            loading: true,
            guid: undefined,
            name: undefined,
            deletable: undefined,
            compadres: undefined,
            bad_refs: undefined,
            flags: [],
        });
    }

    currentSelection() {
        return Object.keys(this.state.additionalDeletions)
                .filter(added_guid => this.state.additionalDeletions[added_guid]);
    }

    reEvalDeletion() {
        const additionalSeverances = [];

        Object.entries(this.state.additionalSeverances).forEach(([guid, value]) => {
            Object.entries(value).forEach(([attr, bool]) => {
                if(bool) additionalSeverances.push([guid,attr]);
            })
        });
        this.setState({
            loading: true,
        }, () => {
            this.determineDeletability({
                guids : [
                    this.state.guid,
                    ...this.response_data.deletes.map(el => el.deleted.guid),
                    ...this.currentSelection(),
                ],
                severances : [
                    ...this.severances.map(severance => [severance.node.guid, severance.attr]),
                    ...additionalSeverances,
                ],
                flags: this.state.flags
            }).then(this.receiveResponse)});
    }

    determineDeletability(data) {
        return _ajax({
            url: "/index.php?r=/node/smm-can-delete-node",
            method: "POST",
            data: data,
        });
    }

    receiveResponse(response) {
        Notifications2.parseResponse(response);
        this.response_data = response.data;
        const {severances, messages, flags } = this.response_data;
        this.severances = severances;

        const currentMessages = messages || (this.state.messages?.length ? this.state.messages : []);
        const currentFlags = flags || [];

        this.setState({
            loading : false,
            additionalDeletions : {},
            additionalSeverances : {},
            deletable : this.response_data.deletable,
            messages: currentMessages,
            flags: currentFlags,
            deprecateOnly: currentFlags.includes("deprecate") || this.response_data?.deletes[0]?.deleted?.deprecated,
        });
    }

    doDelete() {
        const deleted_guids = this.response_data.deletes.map(el => el.deleted.guid);

        _ajax({
            url: "/index.php?r=/node/smm-delete-node",
            method: "POST",
            data: {
                guids : deleted_guids,
                severances : this.severances.map(severance => [severance.node.guid, severance.attr]),
                changeSetId : getActiveChangeSetId(),
            },
        }).then(response => {
            Notifications2.parseResponse(response);

            if (response.status === 'success') {
                Array.isArray(response.data?.deleted_nodes) && removeNodes(response.data.deleted_nodes);

                // a submodel was removed - refresh the table
                if (Array.isArray(response.data?.removed_submodels) && response.data.removed_submodels.length) {
                    getCurrentModelRefIndex();
                }

                if (!this.state.isChild) {
                    const url = PHENOM_URL[this.props.match.params.mode];
                    url && this.props.history.push(url);
                } else {
                  if(this.state.reloadNode) {
                    this.state.reloadNode({ deleted: true, deleted_guids });
                  }
                }
            }
            this.__hide();
        })
    }

    doDeprecate() {
        const analyzed_deletions = this.response_data.deletes.map(el => el.deleted.guid);
        const selected_deletions = this.currentSelection();
        const total_deletions = analyzed_deletions.concat(selected_deletions);

        modelDeprecateNodeMulti(total_deletions, getActiveChangeSetId()).then((res) => {
            Notifications2.parseLogs(`Deprecated '${this.state.name}' in this model.`);
            if(this.state.reloadNode) {
                this.state.reloadNode({ deprecated: true });
            }
            this.__hide();
        })
    }

    doUndeprecate() {
      const analyzed_deletions = this.response_data.deletes.map(el => el.deleted.guid);

      _ajax({
          url: "/index.php?r=/edit/undeprecate",
          method: "POST",
          data: {
              guids: analyzed_deletions,
          },
      }).then(response => {
        if (response.status === 'success') {
            Notifications2.parseLogs(`Undeprecated '${this.state.name}' in this model.`);

          if (this.state.reloadNode) {
            this.state.reloadNode({ undeprecated: true });
          }
        }
        this.__hide();
      })
    }

    deleteDialogMeat() {
        const { deprecateOnly } = this.state;
        const name = this.state.name;
        const outerStyle = {maxHeight: "70vh"}
        let topText;

        const messageMeat = this.state.messages.length ? this.state.messages.map((msg) => <div>{msg}</div>) : null;

        switch (true) {
            case this.state.loading: return (
                <div style={outerStyle}>
                     <img src={loadingIcon} style={{width: 75}}/>
                     <span> {`Determining the fallout of deleting ${name}...`}</span>
                </div>);
            case this.state.deletable:
                topText = deprecateOnly ? <div>You are about to {this.getDialogueTerminology(false, true)} the node '{name}', and all the below listed node(s) in your model. This action <strong>can</strong> be reversed.</div>
                                                   : <div>You are about to {this.getDialogueTerminology(false, true)} the node '{name}', and all the below listed node(s) from your model. This action <strong>cannot</strong> be reversed.</div>
                    return (
                        <div style={outerStyle}>
                            {topText}<br/>
                            {messageMeat}{messageMeat && <br/>}
                            {this.renderCompadreDeletes(true)}<br/>
                            {!!this.severances.length && this.renderAttrSeverances()}{!!this.severances.length && <br/>}
                            <span>Are you sure you would like to go through with this action?</span>
                        </div>);
            default: 
                topText = deprecateOnly ? <div style={{marginBottom: 14}}>You <strong>can</strong> {this.getDialogueTerminology(false, true)} the node '{name}' at this time.</div> 
                                                   : <div style={{marginBottom: 14}}>You <strong>cannot</strong> {this.getDialogueTerminology(false, true)} the node '{name}' at this time because that would render your model invalid.</div>
                const node_deprecated = this.response_data?.deletes[0]?.deleted?.deprecated;
                const bottomText = node_deprecated ? 'You may mark this node as "Undeprecated". This action will unflag the node and its children and fully restore its current model relationships.' : 'You may mark this node as "Deprecated". This action will flag the node and its children to make it easier to clear away its current model relationships.'
                return (
                    <div style={outerStyle}>
                        {topText}
                        {messageMeat}{messageMeat && <br/>}
                        {this.renderCompadreDeletes(false)}
                        {!!this.severances.length && this.renderAttrSeverances()}
                        {this.renderBadRefs()}<br/>
                        <span>{bottomText}</span>
                    </div>);
        }
    }

    renderCompadreDeletes(deletable) {
        const compadre_content =
            <div id="deletion-compadre-content" style={{paddingLeft: 15, paddingTop: 5}}>
              The following nodes will be <strong>{this.getDialogueTerminology(false, true, true)}</strong>{deletable ? " from" : " in"} the model as part of this action.
              <table className="deletion-table">
                <thead><tr><th>Node</th><th>Type</th></tr></thead>
                <tbody>
                    {this.response_data.deletes.map((datum, idx) =>
                     <tr key={idx} style={{backgroundColor : idx % 2 ? "white" : "rgb(245,245,245)"}}>
                        <td><PhenomLink node={datum.deleted} newTab={true}/></td>
                        <td>{datum.deleted.xmiType.split(":")[1]}</td>
                    </tr>)}
                </tbody>
              </table>
           </div>;

        return (
            <ColorCollapsable color="crimson" content={compadre_content} heading={`${this.getDialogueTerminology(true, true, true)} Nodes (` + this.response_data.deletes.length + ")"} contentId="deletion-compadre-content" vMargin={5} collapsableStyle={{border: "1px solid crimson", paddingRight: 0, marginBottom: 15}}/>
        );
    }

    renderAttrSeverances() {
        const severance_content =
            <div id="attr-severance-content" style={{paddingLeft: 15, paddingTop: 5}}>
                The following attributes will be removed as part of a <strong>delete</strong> action.
                <table className="deletion-table">
                    <thead><tr><th>Node</th><th>Type</th><th>Remove Attribute</th></tr></thead>
                    <tbody>
                        {this.severances.map((severance, idx) =>
                            <tr key={idx} style={{backgroundColor : idx % 2 ? "white" : "rgb(245,245,245)"}}>
                                <td><PhenomLink node={severance.node} newTab={true}/></td>
                                <td>{severance.node.xmiType.split(":")[1]}</td>
                                <td>{severance.attr}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>;

            return (
                <ColorCollapsable color="crimson" content={severance_content} heading={"Removed Attributes (" + this.severances.length + ")"} contentId="attr-severance-content" vMargin={5} collapsableStyle={{border: "1px solid crimson", paddingRight: 0, marginBottom: 15}}/>);
    }

    addDeletion(guid_s, value = true) {
        const new_additional_deleted = {...this.state.additionalDeletions};

        guid_s.forEach((guid) => {
            if(!this.state.additionalSeverances[guid]) {
                new_additional_deleted[guid] = value;
            }
        });
        this.setState({additionalDeletions: new_additional_deleted});
    }

    addSeverance(guid_attr_arr, value = true) {
        const new_severances = {...this.state.additionalSeverances};

        guid_attr_arr.forEach(([guid, attr]) => {
            if(!this.state.additionalDeletions[guid]) {

                if (!new_severances[guid]) new_severances[guid] = {};
                if(value) {
                    new_severances[guid][attr] = value;
                } else {
                    delete new_severances[guid];
                }
            }
        })
        this.setState({additionalSeverances: new_severances});
    }

    deleteAll() {
         this.addDeletion(
             this.response_data.deletes
                .map(depcy_datum => depcy_datum.dependents)
                .reduce((prev, curr) => prev.concat(curr))
                .map(dept_datum => dept_datum.dependent.guid));
    }

    /**
     * Constructs a term based on the context of deletion or deprecation.
     * This function adjusts for various grammatical aspects including verb/noun form, 
     * past or present tense, and singular or plural forms based on the provided options. 
     * And can capitalize the first letter of the resulting string if needed.
     * 
     * @param {boolean} startCaps - If true, capitalizes the first letter of the returned term.
     * @param {boolean} isVerb - Determines if the term should be formatted as a verb (true) or a noun (false).
     * @param {boolean} pastTense - If true, formats the verb in past tense; uses present tense if false.
     * @returns {string} A term that is adjusted for prefixes (e.g., "un"), suffixes (e.g., "ed" for past tense verbs),
     *                   and plural forms. Returns an empty string if no data is available.
     */
    getDialogueTerminology(startCaps = false, isVerb = false, pastTense = false) {
        if (!this.response_data?.deletes) return "";
        const main_node = this.response_data.deletes[0];
        const node_deprecated = main_node?.deleted.deprecated;
        const deprecateOnly = this.state.deprecateOnly || node_deprecated;
        
        const prefix = node_deprecated ? "un" : "";
        const suffix = isVerb ? (pastTense ? "ed" : "e") : "";
        const plural = !isVerb && this.response_data.deletes.length > 1 ? "s" : "";
        
        // Determine the base term based on the action and grammatical usage
        const baseTerm = deprecateOnly ? (isVerb ? "deprecat" : "deprecation") 
                                        : (isVerb ? "delet" : "deletion");
        
        // Construct and return the term with optional prefix, suffix, and pluralization
        const completeTerm = `${prefix}${baseTerm}${suffix}${plural}`;

        // Capitalize the first letter if startCaps is true
        return startCaps ? completeTerm.charAt(0).toUpperCase() + completeTerm.slice(1) : completeTerm;
    }

    renderBadRefs() {
        const multiNodes = this.response_data.deletes.length > 1;
        const badRefText = `The proposed ${this.getDialogueTerminology()} ${multiNodes ? "have" : "has"} the following model dependencies:`;

        return (<div>
            <div style={{marginBottom: 7}}>{badRefText}</div>
                <button style={{marginBottom: 10}} className="btn btn-primary" onClick={this.deleteAll}>{this.getDialogueTerminology(true, true)} All Dependencies</button>
                {this.response_data.deletes.filter(depcy_datum => depcy_datum.dependents.length).map((depcy_datum, idx) => {
                    let resolution_count = 0;
                    const color = idx % 2 ? "rgb(243, 122, 76)" : "rgb(56, 214, 143)";
                    const dependent_content =
                        <div>
                            <table className="deletion-table" id={depcy_datum.deleted.guid + "-dependent-content-table"}>
                                <thead><tr><th>Dependent</th><th>Type</th><th>Attribute</th><th>{this.getDialogueTerminology(true, true)} Node</th><th>Remove Attribute</th></tr></thead>
                                <tbody>
                                    <tr><td/><td/><td/>
                                        <td style={{padding: 0}}>
                                            <a
                                                className="cadet-anchor"
                                                onClick={() => this.addDeletion(depcy_datum.dependents.map(dept => dept.dependent.guid))}>
                                                {this.getDialogueTerminology(true, true)} All
                                            </a>
                                        </td>
                                        <td style={{padding: 0}}>
                                            <a
                                                className="cadet-anchor"
                                                onClick={() => this.addSeverance(depcy_datum.dependents.filter(dept => dept.optionality === "optional").map(dept => [dept.dependent.guid, dept.attribute]))}>
                                                Remove All
                                            </a>
                                        </td>
                                    </tr>
                                  {depcy_datum.dependents.map((dept_datum, idxx) => {
                                      const dept = dept_datum.dependent;
                                      const dept_guid = dept.guid;
                                      const set_to_delete = !!this.state.additionalDeletions[dept_guid];
                                      const severed = !!this.state.additionalSeverances[dept_guid] && !!this.state.additionalSeverances[dept_guid][dept_datum.attribute];
                                      const optional = dept_datum.optionality === "optional";

                                      if (set_to_delete || severed) resolution_count++;
                                      return (<tr key={idxx} style={{backgroundColor : idxx % 2 ? "white" : "rgb(245,245,245)"}}>
                                          <td><PhenomLink node={dept} newTab={true} stagger={true}/></td>
                                          <td>{dept.xmiType.split(":")[1]}</td>
                                          <td>{dept_datum.attribute}</td>
                                          <td>
                                            <input
                                                type="checkbox"
                                                checked={set_to_delete}
                                                onChange={(e) => this.addDeletion([dept_guid], e.target.checked)}
                                                title={`Add the node ${dept.name || dept.rolename || dept.xmiType} to the list of ${this.getDialogueTerminology()}.`}
                                                disabled={severed}/>
                                          </td>
                                          <td>
                                            <input
                                                type="checkbox"
                                                checked={severed}
                                                onChange={(e) => this.addSeverance([[dept_guid, dept_datum.attribute]], e.target.checked)}
                                                title={`The attribute ${dept_datum.attribute} is ${optional ? "" : "not"} optional and can ${optional ? "" : "not " + "be"} + ${this.getDialogueTerminology(false, false, true)}.`}
                                                disabled={!optional || set_to_delete}/>
                                          </td>
                                      </tr>);})}
                                </tbody>
                            </table>
                        </div>;

                    return (
                        <ColorCollapsable
                            key={idx}
                            color={color}
                            default={resolution_count === depcy_datum.dependents.length}
                            content={dependent_content}
                            heading={<span><PhenomLink node={depcy_datum.deleted} newTab={true}/> {" node dependents: (" + depcy_datum.dependents.length + ")"}</span>}
                            contentId={depcy_datum.deleted.guid + "-dependent-content-table"}
                            vMargin={15}
                            collapsableStyle={{border: ("1px solid " + color), paddingRight: 0, borderRight: "none"}}/>);
                })}
            </div>);
    }

    dialogButtons() {
        if(this.state.loading) {
            return null;
        } else {
            const { deletes } = this.response_data;
            const have_added_deletes = Object.values(this.state.additionalDeletions).find(val => val)
            const have_added_severances = Object.values(this.state.additionalSeverances).find(val => val);
            const have_added_changes = have_added_deletes || have_added_severances;
            const many_entires = deletes.length > 1;
            const main_node = deletes[0];
            const node_deprecated = main_node?.deleted.deprecated;

            return (
                <DialogActionsBar>
                    {node_deprecated 
                        ? <button
                              id="confirm-undeprecate"
                              className="k-button"
                              onClick={this.doUndeprecate}>
                              {many_entires ? "Undeprecate Nodes" : "Undeprecate Node"}
                          </button>
                        : !have_added_changes && 
                          <button
                              id="confirm-deprecate"
                              className="k-button"
                              onClick={this.doDeprecate}>
                              {many_entires ? "Deprecate Nodes" : "Deprecate Node"}
                          </button> }
                    {have_added_changes && <button
                        id="confirm-reevaluate"
                        className="k-button"
                        onClick={this.reEvalDeletion}>
                        Re-Evaluate
                    </button>}
                    {this.state.deletable && 
                        !this.state.deprecateOnly && <button
                            id="confirm-delete"
                            className="k-button"
                            style={{color: "crimson", fontWeight: "bold"}}
                            onClick={this.doDelete}>
                            {many_entires ? "Delete Nodes" : "Delete Node"}
                        </button>}
                        <button
                            id="confirm-cancel"
                            className="k-button"
                            onClick={this.__hide}>
                            Cancel
                        </button>
                </DialogActionsBar>
            );
        }
    }

    render() {
        if(!this.state.visible) return null;
        return(
            <Dialog
                title={`${this.response_data?.deletes ? this.getDialogueTerminology(true, true) : "Delete"} ${this.state.name}`}
                onClose={this.__hide}
                width={900}>
                {this.deleteDialogMeat()}
                {this.dialogButtons()}
            </Dialog>
        );
    }
}


export default withRouter(DeletionConfirm2);
