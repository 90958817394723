import React from 'react';
import styled from "@emotion/styled";
import { LinkWidget } from "@projectstorm/react-diagrams-core";
import { isStormData, randomColor } from "../util";
import { isPhenomGuid } from "../../../util/util";
import StormData from './StormData';

import { 
  DefaultLinkFactory, 
  DefaultLinkModel,
  DefaultLinkWidget,
} from '@projectstorm/react-diagrams-defaults';





// ------------------------------------------------------------
// LINKS - REACT STORM
// ------------------------------------------------------------
export const Path = styled.path`
  fill: none;
  ${p => !p.selected ? `stroke-dasharray: ${p.strokeSize} ${p.strokeOffset}` : null};
  pointer-events: auto;
`;

export class BaseLinkFactory extends DefaultLinkFactory {
	constructor(type = 'base-link') {
    super(type);
	}

	generateModel() {
		return new BaseLinkModel();
	}

	generateReactWidget(event) {
		return <BaseLinkWidget link={event.model} diagramEngine={this.engine} />;
  }

  generateLinkSegment(model, selected, path) {
		return <Path selected={selected}
                 stroke={selected ? model.getSelectedColor() : model.getColor()}
                 strokeWidth={model.getOptions().width}
                 strokeSize={model.getDashSize()}
                 strokeOffset={model.getDashOffset()}
                 d={path} />
	}
}


export class BaseLinkModel extends DefaultLinkModel {
	constructor(options={}, settings={}) {
		super({
			type: 'base-link',
			width: options.width || 2,
      ...options,
    });

    if (!isStormData(this.options.attrData)) {
      this.options.attrData = new StormData();
    }

    this.settings = {
      onDeleteRemoveOutPort: true,
      onDeleteRemoveInPort: true,
      hide: false,
      linkType: null,   // this is used to provide specific functionality without creating a new "options.type". (options.type require a new factory)
      ...settings,
    }

    this.registerListener({
      refreshData: () => {
        this.refresh();
      },
      nodeDataChanged: (e) => {
        this.refresh();
      },
      entityRemoved: (e) => {
        var { sourcePort, targetPort } = e.entity;
        if (!sourcePort || !targetPort) return;
        const $app = sourcePort?.getNode()?.$app;
        
        if (this.getSettings().onDeleteRemoveOutPort) {
          const sourceNode = sourcePort.getNode();
          sourceNode.getStormData().removeChild(sourcePort.getName());
          // fires port's event listener
          sourcePort.remove();
        }

        if (this.getSettings().onDeleteRemoveInPort) {
          const targetNode = targetPort.getNode();
          targetNode.getStormData().removeChild(targetPort.getName());
          // fires port's event listener
          targetPort.remove();
        }

        // trigger only for IM Links
        // originally this was in ImLink file but moved up to fix a version problem. 
        // this can move back down because the version problem was fixed 
        if (sourcePort.getName() === "draw-tool") {
          return;
        }

        const attrData = this.getAttrData();

        switch (this.getOptions().type) {
          case "im-link":
            $app?.markNodeForDeletion && $app.markNodeForDeletion({
              data: attrData.serializeData(),
              position: [0, 0],
            })
            break;
        }
      },
      colorChanged: (e) => {
        var { sourcePort, targetPort } = e.entity;
        if (!sourcePort || !targetPort) return;

        if (sourcePort.parent.isMatchLineColor()) {
          sourcePort.parent.setNodeColor(e.color);
        }

        if (targetPort.parent.isMatchLineColor()) {
          targetPort.parent.setNodeColor(e.color);
        }
      },
    })
  }

  serialize() {
    return {
      ...super.serialize(),
      dashSize: this.getDashSize(),
      dashOffset: this.getDashOffset(),
      arrowHead: this.getArrowHead(),
      arrowTail: this.getArrowTail(),
      settings: this.getSettings(),
      attrData: {
        guid: this.getAttrGuid(),
      },
    }
  }

  deserialize(event) {
    super.deserialize(event);

    this.options.attrData = event.data.attrData;
    if (!isStormData(this.options.attrData)) {
      this.options.attrData = new StormData();
    }

    this.options.dashSize = event.data.dashSize;
    this.options.dashOffset = event.data.dashOffset;
    this.options.arrowHead = event.data.arrowHead;
    this.options.arrowTail = event.data.arrowTail;
    this.settings = event.data.settings;
  }

  serializeAttrData() {
    return {
      guid: this.getAttrGuid(),
      parent: this.getAttrData().getParentGuid(),
    }
  }


  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  getSettings() {
    return this.settings;
  }

  getLinkType() {
    return this.getSettings().linkType;
  }

  getDashSize() {
    return this.getOptions().dashSize || 0;
  }

  getDashOffset() {
    return this.getOptions().dashOffset || 0;
  }

  getColor() {
    return this.getOptions().color;
  }

  getSelectedColor() {
    return this.getOptions().selectedColor;
  }

  getArrowHead() {
    return this.getOptions().arrowHead;
  }

  getArrowTail() {
    return this.getOptions().arrowTail;
  }

  getWidget() {
    return this.$widget;
  }
  
  /**
   * Javascript is a dynamically typed language.
   *  -> If IDE doesn't show suggested methods, then look up the StormData Class to see the methods
   * 
   * @returns StormData
   */
  getAttrData() {
    return this.options.attrData;
  }

  // deprecated
  getNodeData() {
    return this.getAttrData();
  }

  getStormData() {
    return this.getAttrData();
  }

  getLabel() {
    return this.getLabels()[0];
  }

  /**
   * Returns the guid of attached StormData
   *    -> i.e. links contain NodeConnection nodes
   * 
   * @returns guid
   */
  getAttrGuid() {
    return this.getAttrData().getGuid();
  }

  isUncommitted() {
    const $app = this.getSourcePort()?.getNode()?.$app;
    return $app?.isShowUncommitted() && this.getAttrData().isEdited();
  }

  isLinesVisible() {
    const $app = this.getSourcePort()?.getNode()?.$app;
    return $app?.isShowConnectorLines();
  }

  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  refresh() {
    this.getAttrData().checkEditedStatus();
    this.forceLinkToUpdate();
  }

  setSettings(key, value) {
    this.settings[key] = value;
  }

  setAttrData(attrData) {
    if (!isStormData(attrData)) return;
    this.options.attrData = attrData;
  }
  

  /**
   * 
   * @param {string} linkType 
   */
  setLinkType(linkType) {
    return this.getSettings().linkType = linkType;
  }

  /**
   * 
   * @param {string} color 
   */
  setLinkColor(color) {
    if (!color) color = randomColor();
    if (this.getOptions().color === color) return;
    this.setColor(color);
    this.forceLinkToUpdate();
  }

  /**
   * 
   * @param {number} value 
   */
  setDashSize(value) {
    if (!value) value = Math.floor(Math.random() * Math.floor(20) + 10);      // num between 20 and 30
    this.getOptions().dashSize = value;
  }

  /**
   * 
   * @param {number} value 
   */
  setDashOffset(value) {
    if (!value) value = Math.floor(Math.random() * Math.floor(15) + 5);     // num between 5 and 20
    this.getOptions().dashOffset = value;
  }

  /**
   * 
   * @param {string} value 
   */
  setArrowHead(value) {
    this.getOptions().arrowHead = value;
  }

  /**
   * 
   * @param {string} value 
   */
  setArrowTail(value) {
    this.getOptions().arrowTail = value;
  }

  /**
   * 
   * @param {boolean} bool 
   */
  setOnDeleteRemoveOutPort(bool) {
    this.getSettings().onDeleteRemoveOutPort = bool;
  }

  /**
   * 
   * @param {boolean} bool 
   */
  setOnDeleteRemoveInPort(bool) {
    this.getSettings().onDeleteRemoveInPort = bool;
  }


  // ------------------------------------------------------------
  // Link
  // ------------------------------------------------------------
  hideLink() {
    if (this.getSettings().hide) return;    // prevent unnecessary forceUpdate
    this.setSettings("hide", true);
    this.forceLinkToUpdate();
  }

  showLink() {
    if (!this.getSettings().hide) return;   // prevent unnecessary forceUpdate
    this.setSettings("hide", false);
    this.forceLinkToUpdate();
  }

  isLinkHidden() {
    return this.getSettings().hide;
  }

  forceLinkToUpdate() {
    this.getWidget() && this.getWidget().forceUpdate();
  }

  /**
   * When true, render a red glow effect around the link.
   * 
   * @returns false, written by inherited linkModels
   */
  isError() {
    return false;
  }
}


export class BaseLinkWidget extends DefaultLinkWidget {
  constructor(props) {
    super(props);

    this.props.link.$widget = this;
    this.$app = this.props.diagramEngine.$app;

    // used to create a bend point on mouse move
    this.createPointAtIdx = -1;
    this.pointCreated = false;
    this.mouseEvent = null;
  }

  /**
   * Create Bend Point on mouse move
   * 
   * @param {mouse event} e 
   */
  moveMouseAndCreatePoint = (e) => {
    e.preventDefault();
    e.persist = this.mouseEvent.persist;

    let points = this.props.link.getPoints();

    if(!this.pointCreated) {
        this.addPointToLink(e, this.createPointAtIdx);
        this.pointCreated = true;
    };

    let point = points[this.createPointAtIdx];
    point.extras = {isBendPoint: true}
    let coord = this.$app.engine.getRelativeMousePoint(e);
    point.setPosition(coord);
  }

  /**
   * Reset values when mouse move event ends
   * 
   * @param {mouse event} e 
   */
  cleanUpAfterMouseMove = (e) => {
    window.removeEventListener("mousemove", this.moveMouseAndCreatePoint);
    this.createPointAtIdx = -1;
    this.pointCreated = false;
    this.mouseEvent = null;
  }

  generateArrow = (point, previousPoint, arrowType) => {
    return <CustomLinkArrowWidget key={point.getID()}
                                  arrowType={arrowType}
                                  point={point}
                                  previousPoint={previousPoint}
                                  colorSelected={this.props.link.getSelectedColor()}
                                  color={"var(--linkColor)"} />
  }

  // generateHandle(point, port) {
  //   return <DragHandle key={point.getID()} point={point}
  //                      port={port} link={this} engine={this.props.diagramEngine}
  //                      color={this.props.link.getOptions().selectedColor} />
  // }

  generateText = (point, previousPoint, text) => {
    return <TextWidget key={point.getID() + "text"}
                       point={point}
                       previousPoint={previousPoint}
                       text={text} />
  }

  generatePoint = (point) => {
		return <BaseLinkPointWidget key={point.getID()}
                                point={point}
                                isSelected={this.props.link.isSelected()}
				                        colorSelected={this.props.link.getOptions().selectedColor}
				                        color={this.props.link.getOptions().color} />
	}

  // overriding Right Click
  generateLink = (path, extraProps, id) => {
    const ref = React.createRef();
    this.refPaths.push(ref);

    return <BaseLinkSegmentWidget
              key={`link-${id}`}
              path={path}
              selected={this.state.selected}
              diagramEngine={this.props.diagramEngine}
              factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
                      link={this.props.link}
                      linkWidget={this}
                      forwardRef={ref}
              onSelection={(selected) => {
                this.setState({ selected: selected });
              }}
              extras={extraProps} />
  }

  isAppHidingLinkType = () => {
    return this.$app.isLinkTypeHidden(this.props.link.getLinkType());
  }

  // ------------------------------------------------------------
  // Events
  // ------------------------------------------------------------
  /**
   * It is difficult to select a link, if they are stacked on top of each other.
   * As the mouse enters a link, it rises to the top so it can be selected.
   *    -> SVG elements do not work with z-index
   *    -> SVG elements are "painted" on to the dom, so the latest element takes precedence
   *    -> to bring a SVG element forward, it needs to be reordered and become the last child
   * 
   * @param {mouse event} e 
   */
  handleMouseEnter = (e) => {
    if (this.linkRef) {
      const svgLayer = this.$app.getSvgLayerDOM();
      svgLayer.appendChild(this.linkRef.parentElement)
    }
  }

  /**
   * Create a bend point
   * 
   * @param {mouse event} e 
   * @param {number} idx the last index of the array of points
   */
  handleMouseDown = (e, idx) => {
    if (e.button === 0) {
      // Add a Point
      if (e.ctrlKey) {
          this.mouseEvent = e;
          this.createPointAtIdx = idx + 1;
          window.addEventListener("mousemove", this.moveMouseAndCreatePoint);
          window.addEventListener("mouseup", this.cleanUpAfterMouseMove);
      }
    }
  }

  /**
   * Removes the link
   * 
   * @param {mosue event} e 
   */
  handleMouseClick = (e) => {
    if(e.ctrlKey) {
      e.stopPropagation();
      this.props.link.remove();
      this.props.diagramEngine.repaintCanvas();
    }
  }

  handleMouseUp = (e) => {
    this.props.diagramEngine.model.clearSelection();
    this.props.link.setSelected(true);
  }


	render() {
    const { sourcePort, targetPort } = this.props.link;
    if (!sourcePort) return null;

		//ensure id is present for all points on the path
    const settings = this.props.link.getSettings();
		var points = this.props.link.getPoints();
		var paths = [];
		this.refPaths = [];

    let classes = ["base-link"];

    //draw the multiple anchors and complex line instead
    for (let j = 0; j < points.length - 1; j++) {
      paths.push(
        this.generateLink(
          LinkWidget.generateLinePath(points[j], points[j + 1]),
          {
            'data-linkid': this.props.link.getID(),
            'data-point': j,
            onMouseEnter: this.handleMouseEnter,
            onMouseDown: (e) => this.handleMouseDown(e, j),
            onClick: this.handleMouseClick,
            onMouseUp: this.handleMouseUp,
          },
          j
        )
      );
    }

    if (targetPort !== null) {
      paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2], this.props.link.getArrowHead()))
      paths.push(this.generateArrow(points[0], points[1], this.props.link.getArrowTail()))
    }

    //render the circles
    for (let i = 1; i < points.length - 1; i++) {
      paths.push(this.generatePoint(points[i]));
    }
    
		return <g className={classes.join(" ")}
              data-default-link-test={this.props.link.getOptions().testName}
              ref={el => this.linkRef = el}
              style={{
                display: settings.hide || this.isAppHidingLinkType() ? "none" : null,
                "--linkColor": this.props.link.isSelected() ? this.props.link.getSelectedColor() : 
                               this.props.link.isUncommitted() ? "var(--skayl-orange)" : this.props.link.getColor()
              }}>
                {paths}
           </g>
	}
}



// ------------------------------------------------------------
// Arrow Head and Tail
// ------------------------------------------------------------
export const CustomLinkArrowWidget = props => {
  const {point, previousPoint, arrowType, colorSelected} = props;
  if (!arrowType) return null;

  const color = "var(--linkColor)";
  const angle = 90 +
      (Math.atan2(point.getPosition().y - previousPoint.getPosition().y, point.getPosition().x - previousPoint.getPosition().x) *
          180) /
      Math.PI;

  let arrow = null;
  let translate = "translate(0, 0)";
  switch(arrowType) {
      // Association
      // Dependency
      case "thinArrow":
          arrow = <path d="M5,20 L0,0 L-5,20" stroke={color} strokeWidth={2} fill="none" 
                          data-id={point.getID()} data-linkid={point.getLink().getID()} />
          break;
      // ShapeArrow (Stencilbox)
      case "mediumArrow":
          arrow = <path d="M10,20 L0,0 L-10,20" stroke={color} strokeWidth={3} fill="none" 
                        data-id={point.getID()} data-linkid={point.getLink().getID()} />
          break;
      // Inheritance
      // Realization / Implementation
      case "thinEmptyArrow":
          arrow = <path d="M0,0 L5,20 L-5,20 L0,0" stroke={color} strokeWidth={2} fill={color} 
                          data-id={point.getID()} data-linkid={point.getLink().getID()} />
          break;
      // Aggregation
      case "diamondEmptyArrow":
          arrow = <path d="M0,0 L5,12 L0,24 L-5,12 L0,0 L5,12" stroke={color} strokeWidth={2} fill={color} 
                          data-id={point.getID()} data-linkid={point.getLink().getID()} />
          break;
      // Composition
      case "diamondFilledArrow":
          arrow = <path d="M0,0 L5,12 L0,24 L-5,12 L0,0 L5,12" stroke={color} strokeWidth={2} fill={color} 
                          data-id={point.getID()} data-linkid={point.getLink().getID()} />
          break;
      case "renameMe":
          arrow = <polygon points="0,10 8,30 -8,30" fill={color} 
                          onMouseLeave={() => this.setState({selected: false})} 
                          onMouseEnter={() => this.setState({selected: true})} 
                          data-id={point.getID()} data-linkid={point.getLink().getID()} />
          translate = "translate(0, -10)";
          break;
      default: 
          return null;
  }

  //translate(50, -10),
  return (<g className="arrow" 
              transform={"translate(" + point.getPosition().x + ", " + point.getPosition().y + ")"}>
              <g style={{transform: "rotate(" + angle + "deg)"}}>
                  <g transform={translate}>
                      {arrow}
                  </g>
              </g>
          </g>)
};


// ------------------------------------------------------------
// TEXT WIDGET
// ------------------------------------------------------------
const TextWidget = (props) => {
  const { point, previousPoint, text } = props;
  const angle = 90 +
                (Math.atan2(point.getPosition().y - previousPoint.getPosition().y, point.getPosition().x - previousPoint.getPosition().x) *
                    180) / Math.PI;

  const textProps = {
    rotate: 90,
    posX: 20,
    posY: -10,
    anchor: "start",
  }

  if (angle > 0 && angle < 180) {
    textProps.rotate = -90;
    textProps.posX = -20;
    textProps.anchor = "end";
  }

  return (
    <g transform={'translate(' + point.getPosition().x + ', ' + point.getPosition().y + ')'}>
      <g style={{transform: "rotate(" + (angle + textProps.rotate) + "deg)"}}>
        <text x={textProps.posX}
              y={textProps.posY}
              textAnchor={textProps.anchor}
              style={{
                fontSize: 12,
              }}>
          {text}
        </text>
      </g>
    </g>
  )
}





// ------------------------------------------------------------
// LINK SEGMENT - REACT STORM
// ------------------------------------------------------------
class BaseLinkSegmentWidget extends React.Component {
	render() {
    const isError = this.props.link.isError();

		const Bottom = React.cloneElement(
			this.props.factory.generateLinkSegment(
				this.props.link,
				this.props.selected || this.props.link.isSelected(),
				this.props.path
			),
			{
				ref: this.props.forwardRef
			}
		);
    
		const Top = React.cloneElement(Bottom, {
			strokeLinecap: 'round',
			...this.props.extras,
			ref: null,
			'data-linkid': this.props.link.getID(),
			strokeOpacity: isError ? 0.1 : 0,
      strokeWidth: 20,
      className:"base-link",
      fill: 'none',
      stroke: isError ? "red" : this.props.link.getColor(),
		});

		return (
			<g>
				{Bottom}
				{Top}
			</g>
		);
	}
}



// ------------------------------------------------------------
// LINK POINT - REACT STORM
// ------------------------------------------------------------
const PointTop = styled.circle`
    pointer-events: all;
    cursor: pointer;
`;

export class BaseLinkPointWidget extends React.Component {
	constructor(props) {
        super(props);
        
		this.state = {
			selected: false
		};
	}

	render() {
		const { point } = this.props;
		return (
			<g className="base-point"
         style={{ display: this.props.isSelected ? "block" : "none" }}>
				<circle
					cx={point.getPosition().x}
					cy={point.getPosition().y}
					r={5}
					fill={this.props.colorSelected}
				/>
				<PointTop
					className="point"
					onMouseLeave={() => {
						this.setState({ selected: false });
					}}
					onMouseEnter={() => {
						this.setState({ selected: true });
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            if(e.ctrlKey && this.props.point?.extras?.isBendPoint === true) {
                this.props.point.remove();
            }
          }}
					data-id={point.getID()}
					data-linkid={point.getLink().getID()}
					cx={point.getPosition().x}
					cy={point.getPosition().y}
					r={15}
					opacity={0.0}
				/>
			</g>
		);
	}
}