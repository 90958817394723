import $ from 'jquery'
import { BasicAlert } from '../../../dialog/BasicAlert';

class DependencyMapper {
  static async createMapper(srcProjectId, mergeType=false) {
    const dependencyData = await DependencyMapper.fetchDependencyData(srcProjectId, mergeType);
    const dependencyMap = JSON.parse(dependencyData);

    return new DependencyMapper(dependencyMap);
  }

  static fetchDependencyData(srcProjectId, mergeType=false) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: "/index.php?r=/referencing-model/node-relations-map/",
        method: "post",
        data: { 
          modelId: srcProjectId,
          mergeType: mergeType
        },
        success: resolve,
        error: () => reject("Failed to retreive dependency map."),
      });
    });
  }

  constructor(dependencyMap) {
    this.leafMap = {};
    this.dependencyCache = {};
    this.dependentCache = {};
    const idMap = {};

    Object.entries(dependencyMap).forEach(entry => idMap[entry[1][0]] = entry[0]);
    Object.entries(dependencyMap).forEach(entry => this.leafMap[entry[0]] = new DependencyLeaf(this, entry[0], entry[1].slice(1), idMap));
    Object.values(this.leafMap).forEach(depLeaf => depLeaf.populate(this.leafMap));
  }

  findLeaf(guid) {
    return this.leafMap[guid];
  }
}

class DependencyLeaf {
  static packNodeTypes = new Set(["datamodel:DataModel", "face:ConceptualDataModel", "face:LogicalDataModel", "face:PlatformDataModel",
                                  "face:UoPModel", "skayl:IntegrationModel", "skayl:DeploymentModel", "skayl:DiagramModel",
                                  "skayl:ObjectModel", "skayl:MessageDataModel", "root"]);
  constructor (depMap, guid, dependencyIDs, idMap) {
    this.dependencyMap = depMap;
    this.guid = guid;
    this.dependencyGuids = dependencyIDs.map(id => idMap[id]);
    this.dependencies = [];
    this.dependents = [];
  }

  populate(leafMap) {
    this.dependencyGuids.forEach(dependencyGuid => {
      const dependencyLeaf = leafMap[dependencyGuid];

      if(!!dependencyLeaf) { // this check prevents the addition of "root" as a dependency
        this.dependencies.push(dependencyLeaf);
        dependencyLeaf.addDependent(this);
      }
    });

    delete this['dependencyGuids'];
  }

  addDependent(dependentLeaf) {
    this.dependents.push(dependentLeaf);
  }

  getDependencies(treeIndex) {
    const res = Array.from(this.getDependenciesImpl(treeIndex));
    return res;
  }

  getDependenciesImpl(treeIndex, seenSoFar = new Set()) {
    if(this.guid in this.dependencyMap.dependencyCache) {
      return this.dependencyMap.dependencyCache[this.guid];
    }

    const children = [];
    const treeLeaf = treeIndex[this.guid];
    if (!treeLeaf) return new Set();    // dependency node not found

    const isPack   = DependencyLeaf.packNodeTypes.has(treeLeaf.data.xmiType);
    if(!isPack) {
      const treeChildren = treeLeaf.childrenLeaves.reduce((allChilds, myChild) => allChilds.concat(myChild.childrenLeaves),
                                                          treeLeaf.childrenLeaves);
      treeChildren.forEach(treeChild => children.push(this.dependencyMap.findLeaf(treeChild.data.guid)));
    }

    const allDependencies    = this.dependencies.concat(children);
    const initDependencyList = new Set(allDependencies.map(dependency => dependency.guid));

    initDependencyList.add(this.guid); // adding self to dependency list prevents circular dependencies (especially caused by children) from looping

    const res =  allDependencies
                   .filter(dependency => !seenSoFar.has(dependency.guid)) // this filter prevent circular dependencies looping
                   .reduce((totalDeps, myDependency) => {
                              const allSeen = seenSoFar.union(initDependencyList);
                              const theseDeps = myDependency.getDependenciesImpl(treeIndex, allSeen);

                              return totalDeps.union(theseDeps);
                           },
                           initDependencyList);

    this.dependencyMap.dependencyCache[this.guid] = res;
    return res;
  }

  getDependents() {
    return Array.from(new Set(this.getDependentsImpl()));
  }

  getDependentsImpl(seenSoFar = []) {
    if(this.guid in this.dependencyMap.dependentCache) {
      return this.dependencyMap.dependentCache[this.guid];
    }

    const initDependentsList = this.dependents.map(dependent => dependent.guid).concat(new Array(this.guid));
    const res =  this.dependents.filter(dependent => !seenSoFar.includes(dependent.guid)) // this filter prevent circular dependencies looping
                                .reduce((allDependents, myDependent) => allDependents.concat(myDependent.getDependentsImpl(seenSoFar.concat(initDependentsList))),
                                        initDependentsList);

    this.dependencyMap.dependentCache[this.guid] = res;
    return res;
  }
}

export default DependencyMapper;
