import React, {useEffect, useState, useRef, useCallback, forwardRef} from "react";
import ReactDOM from "react-dom";
import {withRouter} from "react-router-dom";
import {ComboBox as KComboBox, DropDownList} from "@progress/kendo-react-dropdowns";
import { RadioButton } from '@progress/kendo-react-inputs';
import {Popup} from "@progress/kendo-react-popup";
import Menu from "@progress/kendo-react-layout/dist/npm/menu/components/Menu";
import MenuItem from "@progress/kendo-react-layout/dist/npm/menu/components/MenuItem";
import {NavLink, Link} from "react-router-dom";
import deprecatedSrc from "../../images/deprecated_hover.png";
import {createNodeUrl} from "../../requests/type-to-path";
import {getActiveChangeSets, modelGetNode} from "../../requests/sml-requests";
import {sortNodesByName, sortNodesByType, splitXmiType, splitCamelCaseWithSpace, isPhenomGuid} from "./util";
import {Button} from "@progress/kendo-react-buttons";
import styled from "@emotion/styled";
import PhenomId from "../../requests/phenom-id";
import PropTypes from "prop-types"
import { useInputErrorMsg } from "../../hooks/useInputError";
import { useSelector } from "react-redux";
import { PhenomButtonLink, PhenomLink } from "../widget/PhenomLink";
import NavTree from "../tree/NavTree";
import { BasicConfirm } from "../dialog/BasicConfirm";
import { getKbUrl } from "./util";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactTooltip from "react-tooltip";



/**
 * INDEX
 * ------------------------------------------------------------
 * # Form Fields
 * ## Label
 * ## Buttons
 * ## Input
 * # Node Detail
 * # Symbols / Icons
 * # Resize
 * ## Uncategorized
 * ------------------------------------------------------------
 */



// ------------------------------------------------------------
// ## Label
// ------------------------------------------------------------

/**
 * Label component renders a label element with optional ID, class name, and additional props.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.id - The ID attribute for the label element.
 * @param {string} [props.className="cadet-label"] - The CSS class name for styling the label.
 * @param {any} props.children - The content to display within the label element.
 * @param {any} props.restProps - Additional props to be spread onto the label element.
 *
 * @returns {JSX.Element} The rendered label element with the specified properties and children.
 */

export const Label = ({ id, className="cadet-label", children, ...restProps }) => {
  return <label id={id ? `${id}-label` : null} className={className} {...restProps}>
            { children }
         </label>
}

Label.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Label with a horizontal line
 *    requires text attribute
 *    - text overflow is ellipsis
 *
 */
export const PhenomLabel = ({ id, className="cadet-line-label", text, isEdited, children, ...restProps }) => {
  return <Label id={id}  className={className} {...restProps}>
            <span>
              { text }
              { isEdited && "*" }
            </span>
            { children }
         </Label>
}

PhenomLabel.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  style: PropTypes.object,
};


/**
 * Original Line Label
 *
 */
export const LineLabel = ({text, style, classes = "", errorMsg, idCtx, children}) => {
  return (
      <div className={"lineLabel " + classes} style={style} id={idCtx+"-label"}>
          {text}
          {!errorMsg ||
          <strong id={idCtx+"-label-error"} title={errorMsg} style={{color: "crimson", cursor: "pointer", marginLeft: ".5em"}}>⚠</strong>}
          {children}
      </div>
  )
}

/**
 * PhenomCollapsable component renders a collapsible section with a header that can be expanded or collapsed.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.id - The ID attribute for the collapsible section.
 * @param {string} [props.label=""] - The label or title displayed in the collapsible header.
 * @param {boolean} [props.startCollapsed=true] - Whether the collapsible section starts collapsed.
 * @param {JSX.Element} props.headerChildren - Additional JSX elements to display in the header.
 * @param {function} props.onClick - Optional callback function triggered when the header is clicked.
 * @param {any} props.children - The content to be displayed within the collapsible section.
 *
 * @returns {JSX.Element} The rendered collapsible section with header and content.
 */

export const PhenomCollapsable = (props) => {
  const { id, label="", startCollapsed=true, headerChildren, onClick } = props;
  const [collapsed, setCollapsed] = useState(startCollapsed);
  const caretClasses = ["fas"];
  const wrapperClasses = [];

  if (collapsed) {
    caretClasses.push("fa-caret-right");
  } else {
    caretClasses.push("fa-caret-down");
  }

  if (collapsed) {
    wrapperClasses.push("collapsed")
  }

  return <div className="edit-collapsable">
    <header onClick={() => {
                if (onClick) onClick();
                setCollapsed(prev => !prev);
               }}>
      <div>
        <span id={id ? `${id}-collapse-toggle` : null}
              className={caretClasses.join(" ")} />
        <span>{ label }</span>
        {headerChildren}
      </div>
    </header>

    <div className={wrapperClasses.join(" ")}>
      { props.children }
    </div>
  </div>
}

// ------------------------------------------------------------
// ## Buttons
// ------------------------------------------------------------

/**
 * KendoButtonWithDebounce component renders a button from the Kendo UI library with debounced click handling.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {function} props.onClick - The callback function triggered when the button is clicked.
 * @param {any} props.restProps - Additional props passed to the underlying Button component.
 *
 * @returns {JSX.Element} The rendered button component with debounced click handling.
 */

export const KendoButtonWithDebounce = ({ disabled, onClick, ...restProps }) => {
  const [processing, setProcessing] = useState(false);

  const handleClick = useCallback(async (e) => {
    try {
      setProcessing(true);
      onClick && await onClick();
      setProcessing(false);

    } catch (error) {
      setProcessing(false);
    }
  }, [onClick])

  return <Button {...restProps}
                 disabled={processing || disabled}
                 onClick={handleClick} />
}

export const KbButton = ({ props }) => {
  const kbUrl = getKbUrl(useParams());

  if(!kbUrl) return null;

  return (
    <Link className='kb-link'
          title="Knowledge Base"
          to={{ pathname: kbUrl }} 
          target="_blank"
          {...props}>
      <span className='fas fa-book' />
    </Link>
  );
}


// ------------------------------------------------------------
// ## Inputs
// ------------------------------------------------------------

/**
 * Regular Input Field
 *
 */
export const Input = forwardRef(({ id, className, type, value, isError, children, ...restProps }, ref) => {
  
  const classes = ["cadet-input-wrapper", "border-effect-01"];
  if (isError) classes.push("error");

  return <div className={classes.join(" ")}>
            <input id={id ? `${id}-input` : null}
                   className={className || "cadet-input"}
                   type={type || "text"}
                   value={value || ""}
                   ref={ref}
                   {...restProps} />
            { children }

            {/* border effects */}
            <span className="be-top be-bottom" />
            <span className="be-left be-right" />
          </div>
})

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
}


/**
 * Input Field with Box Shadow
 *
 */
export const InputWithShadow = ({ id, className, type, value, children, ...restProps }) => {
  return <div className="cadet-input-wrapper box-shadow">
            <input id={id ? `${id}-input` : null}
                   className={className || "cadet-input"}
                   type={type || "text"}
                   value={value || ""}
                   autoComplete="off"
                   {...restProps} />
            { children }
         </div>
}

InputWithShadow.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
}




/**
 * Input Field with Label
 *
 */
export const PhenomInput = forwardRef(({ id, className, label, value, originalValue, disabled, customErrorMsg,
                                         config, type="text", children,
                                         onChange, onClickResetIcon,
                                         containerProps, wrapperProps, labelProps, ...restProps }, ref) => {
    const errorMsg = !disabled && useInputErrorMsg(value, config, originalValue);
    const isEdited = originalValue !== undefined && value !== originalValue;

    return <div className={className} {...containerProps}>
              {label &&
              <PhenomLabel id={id} text={label} isEdited={isEdited} {...labelProps} /> }

              <Input id={id}
                     type={type}
                     value={value ?? ""}
                     onChange={onChange}
                     isError={!!errorMsg || !!customErrorMsg}
                     disabled={disabled}
                     required={config?.required}
                     ref={ref}
                     autoComplete="off"
                     {...restProps}>
                      

                  { children }

                  {!disabled && isEdited && onClickResetIcon &&
                  <Button icon="reset" look="bare"
                          onClick={onClickResetIcon} /> }

                  {!disabled && (customErrorMsg || errorMsg) &&
                  <Button icon="warning" look="bare"
                          title={customErrorMsg || errorMsg}
                          onClick={(e) => e.preventDefault()} /> }
              </Input>
    </div>
})

PhenomInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
}




/**
 * Original Input
 *
 */
export const CadetInput = ({text, title, onChange, onFocus, onBlur, onKeyDown, onKeyUp, style = {}, error, idCtx, id, placeholder = "", type = "text", min, max, step, disabled, inputRef}) => {
  return (
      <input
          id={(idCtx || id) + "-input"}
          ref={inputRef}
          type={type}
          min={min}
          max={max}
          step={step}
          style={{...style, border: error ? "1px solid red" : null}}
          className="cadet-text-input"
          disabled={disabled === undefined ? !onChange : disabled}
          autoComplete="off"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={text}
          title={title}
          placeholder={placeholder}
          onDoubleClick={e => e.stopPropagation()}
          onDrop={e => e.preventDefault()}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}/>
  );
}

/**
 * PackageComboBox component for selecting packages within a node hierarchy.
 * @param {Object} props - Component props.
 * @param {string} props.id - Identifier for the component.
 * @param {string} props.label - Label for the combo box.
 * @param {string} props.xmiType - Type of XMI for the combo box.
 * @param {string} props.selectedGuid - Currently selected GUID.
 * @param {string} props.originalGuid - Original GUID.
 * @param {string} props.placeholder - Placeholder text for the combo box.
 * @param {boolean} props.disabled - Whether the combo box is disabled.
 * @param {Object} props.config - Configuration object.
 * @param {boolean} props.showXmiType - Whether to show XMI type.
 * @param {Array} props.additionalNodes - Additional nodes to include.
 * @param {function} props.onChange - Function called when selection changes.
 * @param {function} props.onClickResetIcon - Function called on reset icon click.
 * @param {function} props.onClickPlusIcon - Function called on plus icon click.
 * @param {function} props.onClickCancelIcon - Function called on cancel icon click.
 * @returns {JSX.Element} PackageComboBox component.
 */

export const PackageComboBox = (props) => {
  const [prevSelectedGuid, setPrevSelectedGuid] = useState(null);
  const subModels = useSelector((state) => state.app.subModels);

  useEffect(() => {
    setPrevSelectedGuid(props.selectedGuid);
  }, [props.selectedGuid]);

  // notify user if selected parent is within a different model
  useEffect(() => {
    // invalid
    if (!props.onChange || !props.nodeGuid || isPhenomGuid(props.nodeGuid)) return;

    // return if leaf nodes aren't found
    const selectedLeaf = NavTree.getLeafNode(props.selectedGuid);
    if (!selectedLeaf) return;
    const detailsLeaf = NavTree.getLeafNode(props.nodeGuid);
    if (!detailsLeaf) return;
    
    if (selectedLeaf.getModelId() !== detailsLeaf.getModelId()) {
      const model = subModels[selectedLeaf.getModelId()];
      const modelName = model ? `- ${model.name}` : "";
      const prevSelectedLeaf = NavTree.getLeafNode(prevSelectedGuid);
      if (!prevSelectedLeaf) return;

      BasicConfirm.show(`You are about to move the node(s) to a different model ${modelName}`, null, () => {props.onChange(prevSelectedLeaf.getData())});
    }
  }, [props.selectedGuid]);

  const updateParent = useCallback((e) => {
    // const movedGuids = e?.detail?.guids;

    // invalid
    if (!props.onChange || !props.nodeGuid || isPhenomGuid(props.nodeGuid)) {
      return;
    }

    const leaf = NavTree.getLeafNode(props.nodeGuid);
    if (!leaf) return;

    const parentLeaf = leaf.getParentLeaf();
    if (!parentLeaf) return;

    const currParentGuid = props.selectedGuid;
    const newParentGuid = parentLeaf.getGuid();
    
    if (currParentGuid !== newParentGuid) {
      props.onChange(parentLeaf.getData());
    }
  }, [props.nodeGuid, props.selectedGuid])
  
  useEffect(() => {
    window.addEventListener('MOVED_NODES', updateParent);
    
    return () => {
      window.removeEventListener('MOVED_NODES', updateParent);
    }
  }, [props.nodeGuid, props.selectedGuid])

  return <NodeComboBox id={props.id}
                        label={props.label}
                        xmiType={props.xmiType}
                        selectedGuid={props.selectedGuid}
                        originalGuid={props.originalGuid}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        config={props.config}
                        showXmiType={props.showXmiType}
                        additionalNodes={props.additionalNodes}
                        onChange={props.onChange}
                        onClickResetIcon={props.onClickResetIcon}
                        onClickPlusIcon={props.onClickPlusIcon}
                        onClickCancelIcon={props.onClickCancelIcon} />
}

/**
 * NodeComboBox component for selecting nodes based on XMI types.
 * @param {Object} props - Component props.
 * @param {string} props.id - Identifier for the component.
 * @param {string} props.label - Label for the combo box.
 * @param {string|string[]} props.xmiType - Type or types of XMI for filtering nodes.
 * @param {string[]} props.additionalNodes - Additional nodes to include in the list.
 * @param {string[]} props.filterOutGuids - GUIDs to filter out from the node list.
 * @param {string} props.selectedGuid - GUID of the currently selected node.
 * @param {string} props.originalGuid - Original GUID for comparison.
 * @param {string} props.placeholder - Placeholder text for the combo box.
 * @param {boolean} props.disabled - Whether the combo box is disabled.
 * @param {Object} props.config - Configuration object.
 * @param {boolean} props.showXmiType - Whether to show XMI type.
 * @param {function} props.onChange - Function called when selection changes.
 * @param {function} props.onClickResetIcon - Function called on reset icon click.
 * @param {function} props.onClickPlusIcon - Function called on plus icon click.
 * @param {function} props.onClickCancelIcon - Function called on cancel icon click.
 * @returns {JSX.Element} NodeComboBox component.
 */

export const NodeComboBox = (props) => {
  const nodesOfType = useSelector(state => state.navTree.nodesOfType || {});
  const subModels = useSelector(state => state.app.subModels || {});
  const [nodeList, setNodeList] = useState([]);
  const [pubNodeList, setPubNodeList] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [originalNode, setOriginalNode] = useState(null);

  useEffect(() => {
    const pubModels = Object.fromEntries(Object.entries(subModels).filter(([, value]) => value.created !== null));
    let newNodeList = [];

    // combine multiple xmiType lists
    //  -> some nodes can reference two different packages (i.e. PrcoessingElement)
    if (Array.isArray(props.xmiType)) {
      for (let type of props.xmiType) {
        newNodeList = newNodeList.concat(nodesOfType[type] || []);
      }
    } else {
      // MAIN USE CASE - single list of XmiTypes
      newNodeList = nodesOfType[props.xmiType] || [];
    }

    // add additional nodes to the list 
    //  -> these nodes are not returned by nodes-of-type (i.e. Root node for packages)
    if (Array.isArray(props.additionalNodes)) {
      newNodeList = [...props.additionalNodes, ...newNodeList];
    }

    // prevent the node/detail page from selecting itself
    //  -> some nodes can find itself in the list (i.e. Package node selecting a parent)
    if (Array.isArray(props.filterOutGuids)) {
      newNodeList = newNodeList.filter(node => !props.filterOutGuids.includes(node.guid));
    }

    // iterate through newNodeList to find if nodes are published
    const newPubNodeList = [];
    newNodeList.forEach(node => {
      if (node.xmiType.match(/^(face|skayl|datamodel):.*Model$/) && pubModels.hasOwnProperty(node.modelId)) {
        newPubNodeList.push(node.guid);
      }
    });

    setNodeList(newNodeList);
    setPubNodeList(newPubNodeList);
  }, [props.xmiType, nodesOfType, props.filterOutGuids, subModels]);

  useEffect(() => {
    const node = nodeList.find(n => n.guid === props.selectedGuid);
    setSelectedNode(node || null);
  }, [props.selectedGuid, nodeList]);

  useEffect(() => {
    const node = nodeList.find(n => n.guid === props.originalGuid);
    setOriginalNode(node);
  }, [props.originalGuid, nodeList]);

  return <PhenomComboBox id={props.id}
                          label={props.label}
                          value={selectedNode}
                          originalValue={originalNode}
                          data={nodeList}
                          dataDisabled={pubNodeList}
                          dataItemKey="guid"
                          placeholder={props.placeholder}
                          disabled={props.disabled}
                          config={props.config}
                          autoComplete="off"
                          showXmiType={props.showXmiType}
                          onChange={props.onChange}
                          onClickResetIcon={props.onClickResetIcon}
                          onClickPlusIcon={props.onClickPlusIcon}
                          onClickCancelIcon={props.onClickCancelIcon} />
}



// ------------------------------------------------------------
// ## Text Area
// ------------------------------------------------------------


/**
 * Text Area with Label
 *
 * @param {string} value text attribute for textarea field
 * @param {Object} [labelProps] provide additional data to the label component
 * @param {Object} [containProps] provide additional data to the container
 * @param {*} [restProps] any additional props is distributed to the radio button wrapper
 */
export const PhenomTextArea = ({ id, className, label, value, originalValue, disabled,
                                 onChange, onClickResetIcon,
                                 containerProps, wrapperProps, labelProps, ...restProps}) => {

  const isEdited = originalValue !== undefined && value !== originalValue;

  return <div id={id} className={className}>
            {label &&
            <PhenomLabel id={id} text={label} isEdited={isEdited} {...labelProps} /> }

            <div className="cadet-textarea-wrapper border-effect-01" {...wrapperProps}>
              <textarea id={id ? `${id}-input` : null}
                        className="cadet-textarea"
                        value={value || ""}
                        disabled={disabled}
                        onChange={onChange}
                        {...restProps} />

              <div className="floating-buttons">
                {!disabled && isEdited && onClickResetIcon &&
                <Button icon="reset" look="bare"
                        onClick={onClickResetIcon} /> }
              </div>

              {/* border effects */}
              <span className="be-top be-bottom" />
              <span className="be-left be-right" />
            </div>
         </div>
}



export const CadetTextArea = ({text, title, onChange, style = {}, id, idCtx, placeholder = "", disabled, autoComplete, narrow = false, onBlur}) => {
  return (
      <textarea
          id={idCtx || id}
          style={{...style, minHeight: narrow ? "unset" : "100px"}}
          className="cadet-text-input"
          disabled={disabled === undefined ? !onChange : disabled}
          onChange={onChange}
          value={text || ""}
          title={title}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onDoubleClick={e => e.stopPropagation()}
          onDrop={e => e.preventDefault()}
          onBlur={onBlur}
      />
  );
};


/**
 * Select Field with Label
 *
 * @param {*} value this element should match the contents contained in data list. example: if data is a list of nodes, then value should be one of the nodes.
 * @param {Object[]} data an array of primitives or objects. Note: falsey values will not render, if you need false or 0 then edit the return value
 * @param {string} dataItemKey required when data is an array of objects. This will key into the object to determine what is selected. Tip: use "guid" if data is a list of nodes
 * @param {string} [textField] used with an array of objects and used to display a specific attribute as text
 * @param {Object} [labelProps] provide additional data to the label component
 * @param {Object} [containerProps] provide additional data to the container
 * @param {*} [restProps] any additional props is distributed to the radio button wrapper
 */
export const PhenomSelect = ({ id, className, value, data=[], dataDisabled=[], dataItemKey, textField, label, disabled, children,
                               config,                             
                               onChange, onClickPlusIcon, onClickPencilIcon,
                               containerProps, wrapperProps, labelProps, ...restProps }) => {

  const errorMsg = useInputErrorMsg(value, config);
  const classes = ["cadet-input-wrapper", "border-effect-01"]
  if (!!errorMsg) classes.push("error");

  return <div id={id} className={className} {...containerProps}>
            {label &&
            <PhenomLabel id={id} text={label} {...labelProps} />}

            <div className={classes.join(" ")} {...wrapperProps}>
              <select id={id ? `${id}-select` : null}
                      className="cadet-select-single"
                      value={value}
                      onChange={onChange}
                      disabled={disabled}
                      required={config?.required}
                      {...restProps}>

                {data.length > 0
                  ? data.map((item) => {
                      if (!item) return null;   // prevent falsey values (undefined and null)
                      const displayText = item[textField] || item.name || item.rolename || item[dataItemKey] || item;
                      const itemValue = dataItemKey !== undefined ? item[dataItemKey] : item;
                      const isDisabled = dataDisabled.some(ele => ele === itemValue);

                      return <option key={item[dataItemKey] || item}
                                    id={id ? `${id}-select-${itemValue}` : null}
                                    value={itemValue}
                                    disabled={isDisabled}>
                                  {displayText}
                            </option>
                    })
                  : <option disabled>No Data Found.</option>
                }
              </select>

              { children }

              {!disabled && onClickPencilIcon &&
              <Button id={id ? `${id}-add-btn` : null} icon="pencil" look="bare" title="edit" disabled={disabled} onClick={onClickPencilIcon} /> }

              {!disabled && onClickPlusIcon &&
              <Button id={id ? `${id}-add-btn` : null} icon="add" look="bare" title="add" disabled={disabled} onClick={onClickPlusIcon} /> }
            
              {!disabled && errorMsg &&
                  <Button icon="warning" look="bare"
                          title={errorMsg}
                          onClick={(e) => e.preventDefault()} /> }

              {/* border effects */}
              <span className="be-top be-bottom" />
              <span className="be-left be-right" />
            </div>
        </div>
}

/**
 * Radio Buttons with Label
 *
 * @param {*} value this element should match the contents contained in data list. example: if data is a list of nodes, then value should be one of the nodes.
 * @param {Object[]} data an array of primitives or objects. Note: falsey values will not render, if you need false or 0 then edit the return value
 * @param {string} dataItemKey required when data is an array of objects. This will key into the object to determine what is selected. Tip: use "guid" if data is a list of nodes
 * @param {string} [textField] used with an array of objects and used to display a specific attribute as text
 * @param {Object} [labelProps] provide additional data to the label component
 * @param {Object} [containerProps] provide additional data to the container
 * @param {*} [restProps] any additional props is distributed to the radio button wrapper
 */
export const PhenomRadioButtons = ({ id, className, data=[], dataItemKey, textField, label, value, disabled,
                                     onChange, containerProps, labelProps, ...restProps }) => {
  const radioRef = useRef(null);

  useEffect(() => {
    const slider = radioRef.current.querySelector('.slider');
    const inputs = radioRef.current.querySelectorAll('input');
    const idx = Array.from(inputs).findIndex(ele => ele.checked);

    if (idx > -1) {
      const label = inputs[idx].parentElement;
      const rect = label.getBoundingClientRect();



      slider.style.cssText = `
        left: ${idx / data.length * 100}%;
        width: calc(100% / ${data.length});
        height: ${rect.height}px;
      `
    }
  }, [value])

  return <div id={id} className={className} {...containerProps}>
            {label &&
            <PhenomLabel id={id} text={label} {...labelProps} />}

            <div className={"cadet-radio-horizontal"} ref={radioRef} {...restProps}>
              {data.map((item, idx) => {
                if (!item) return null;   // prevent falsey values (undefined and null)
                const displayText = item[textField] || item.name || item.rolename || item[dataItemKey] || item;
                const itemValue = item[dataItemKey] || item;
                const checked = value === (item[dataItemKey] || item);

                return <label key={itemValue}>
                          <input id={id ? `${id}-${itemValue}` : null}
                                 name={id}
                                 type="radio"
                                 value={itemValue}
                                 hidden
                                 checked={checked}
                                 disabled={disabled}
                                 onChange={onChange} />
                          <span>{displayText}</span>
                      </label>
              })}
              <span className="slider" />
            </div>
        </div>
}



/**
 * PhenomToggle component renders a toggle switch with optional labels and loading state.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.checked - Whether the toggle switch is checked.
 * @param {Array<string>} props.data - Array containing labels for "Off" and "On" states respectively.
 * @param {boolean} props.disabled - Whether the toggle switch is disabled.
 * @param {boolean} props.isLoading - Whether the toggle switch is in a loading state.
 * @param {function} props.onChange - The callback function triggered when the toggle switch state changes.
 * @param {any} props.restProps - Additional props to be spread onto the label element.
 *
 * @returns {JSX.Element} The rendered toggle switch component.
 */

export const PhenomToggle = (props) => {
  const {
    checked,
    data=[],
    disabled,
    isLoading,
    onChange,
    ...restProps
  } = props;

  const labelClasses = ["cadet-toggle"];
  if (disabled || isLoading) labelClasses.push("disabled");

  return (
    <label className={labelClasses.join(" ")} {...restProps}>
      <input className="cadet-toggle-input"
              checked={checked}
              type="checkbox"
              disabled={disabled}
              onChange={onChange} />
      <span className="cadet-toggle-label" data-off={data[0] || "Off"} data-on={data[1] || "On"} />
      <span className="cadet-toggle-handle" />
    </label>
  )
}






/**
 * ComboBox with Label
 *
 * @param {*} value this element should match the contents contained in data list. example: if data is a list of nodes, then value should be one of the nodes.
 * @param {Object[]} data an array of primitives or objects. Note: falsey values will not render, if you need false or 0 then edit the return value
 * @param {string} dataItemKey required when data is an array of objects. This will key into the object to determine what is selected. Tip: use "guid" if data is a list of nodes
 * @param {Object} [containerProps] provide additional data to the container
 * @param {*} [restProps]
 */
export class PhenomComboBox extends React.Component {
  static defaultProps = {
    dataDisabled: [],
  }

  inputRef = React.createRef();
  containerRef = React.createRef();
  listRef = React.createRef();

  state = {
    inputValue: "",
    isFocusInput: false,
    isFocusList: false,
    dataList: [],
  }

  componentDidMount() {
    const { data=[], value, dataItemKey, dataDisabled } = this.props;
    let dataList = data;

    // ==================================
    // Update data list
    // ==================================
    if (dataItemKey === "guid") {
      dataList = this.sortDataByType(data);
    }

    this.setState({ dataList }, () => {
      this.handleValueChange();
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, value, dataItemKey } = this.props;
    const { inputValue, isFocusInput, isFocusList } = this.state;

    // ==================================
    // Update data list when data changes
    // ==================================
    if (prevProps.data !== data) {
      if (dataItemKey === "guid") {
        this.setState({ dataList: this.sortDataByType(data) });
      } else {
        this.setState({ dataList: data.sort() });
      }
    }

    // ==================================
    // Update the text when value changes
    // ==================================
    if (prevProps.value !== value) {
      this.handleValueChange();

    } else if (prevState.isFocusInput !== isFocusInput || prevState.isFocusList !== isFocusList) {
    // ==================================
    // Reset back to original value if user clicks away without making a selection
    //
    // Special case: when the user clears out the text field and then clicks away, register the change
    // ==================================
      !isFocusList && !isFocusInput && this.handleValueChange();
    }
  }

  /**
     * Sort nodes by alphabetically and by xmiType
     *    and push disabled nodes to the end of the list
     * 
     * @param {array} nodes 
     * @returns array of sorted nodes
     */
  sortDataByType = (data=[]) => {
    const { dataDisabled=[] } = this.props;
    const sortedData = data.sort(sortNodesByType);
    
    if (dataDisabled.length) {
      sortedData.sort((a, b) => dataDisabled.includes(a.guid) - dataDisabled.includes(b.guid));
    }
    
    return sortedData;
  }

  // filter the dropdown list as the user types
  filterDataList = (text="") => {
    const { data, dataItemKey, textField, dataDisabled } = this.props;
    let newDataList;

    // data is a list of nodes
    if (dataItemKey === "guid") {
      newDataList = data.filter((ele) => {
        const name = ele.name ?? ele.rolename;
        const regex = new RegExp(text, 'i');
        return name.match(regex);
      });

      newDataList = this.sortDataByType(newDataList);

    // data is a list of objects
    } else if (textField) {
      newDataList = data.filter((ele) => {
        if (typeof ele[textField] !== "string") return true;
        const regex = new RegExp(text, 'i');
        return ele[textField].match(regex);
      })

    // data is a list of strings
    } else if (typeof data[0] === 'string') {
      newDataList = data.filter((ele) => {
        const regex = new RegExp(text, 'i');
        return ele.match(regex);
      })
    }

    this.setState({ dataList: newDataList ? newDataList.sort() : data.sort() });
  }

  setInputValueAndFilterList = (e) => {
    this.setState({ inputValue: e.target.value }, () => {
      this.filterDataList(e.target.value);
    })
  }

  getOriginalValue = () => {
    const { dataItemKey, textField, originalValue } = this.props;

    // default to undefined (note: 0, "", false, null, undefined are falsey values)
    if (!originalValue) return;

    // data is a list of primitives
    if (!dataItemKey) return originalValue;

    // data is a list of nodes/objects
    if (dataItemKey || textField) {
      return originalValue[textField] || originalValue["name"] || originalValue["rolename"] || originalValue[dataItemKey] || "";
    }

    return "";
  }

  // ==============================================
  // Keyboard commands when focus is on input field
  // ==============================================
  handleKeyPress = (e) => {
    const { data, value, dataItemKey } = this.props;
    const { dataList, inputValue, isFocusInput, isFocusList } = this.state;

    // Enter (user typed out the name of the object and wants to commit it)
    if (e.keyCode === 13) {
      if (inputValue.trim()) {
        const element = dataList.find((ele) => {
          // data is a list of primitives
          if (!dataItemKey) return ele === inputValue;

          // data is a list of nodes
          if (dataItemKey === "guid") return ele.name === inputValue || ele.rolename === inputValue;

          // data is a list of objects
          return ele[dataItemKey] === inputValue;
        });
        element && this.handleSelectElement(element);
      }

    // ArrowDown (user changing the focus to the dropdown list without using the mouse)
    } else if (e.keyCode === 40) {
      e.preventDefault();
      const firstListItem = this.listRef.current.querySelector('li');
      firstListItem && firstListItem.focus();
    }
  }

  handleValueChange = () => {
    const { dataItemKey, textField } = this.props;
    const element = this.props.value;

    // default to empty string (note: 0, "", false, null, undefined are falsey values)
    if (!element) {
      this.setState({ inputValue: "" });

    } else if (textField) {
      this.setState({ inputValue: element[textField] });
    
    } else if (dataItemKey) {
      // data is a list of nodes/objects
      this.setState({ inputValue: element.name || element.rolename || element[dataItemKey] || "" });

    } else {
      this.setState({ inputValue: element || "" });
    }

    this.filterDataList(""); // do not down select dropdown lsit
  }


  // user selects an element from the dropdown list
  handleSelectElement = (element) => {
    const { data, value, dataItemKey, dataDisabled, onChange } = this.props;
    const { dataList, inputValue, isFocusInput, isFocusList } = this.state;

    const item = element[dataItemKey] || element;
    if (dataDisabled.includes(item)) return;
    onChange && onChange(element);
    isFocusInput && this.inputRef.current.blur();
    isFocusList && this.setState({ isFocusList: false });
  }

  // when the focus is on the dropdown list and the user presses the esc key
  handleCancelDropdown = () => {
    this.inputRef.current.focus();
  }
  

  render() {
    const { id, label, data, value, dataItemKey, textField, containerProps, disabled, customErrorMsg,
            dataDisabled, showXmiType, children,
            onClickCancelIcon, onClickPlusIcon, onClickPencilIcon, onClickTrashIcon, ...restProps } = this.props;
    const { dataList, inputValue, isFocusInput, isFocusList } = this.state;
    const originalValue = this.getOriginalValue();

    return <>
        <PhenomInput id={id}
                     label={label}
                     value={inputValue}
                     tabIndex="0"
                     autoComplete="off"
                     disabled={disabled}
                     onFocus={() => this.setState({ isFocusInput: true })}
                     onBlur={() => this.setState({ isFocusInput: false })}
                     containerProps={{
                        onKeyDown: this.handleKeyPress,
                        ref: this.containerRef,
                        ...containerProps,
                     }}
                     {...restProps}
                     onChange={this.setInputValueAndFilterList}
                     originalValue={originalValue}
                     customErrorMsg={customErrorMsg}
                     ref={this.inputRef}>

            {!disabled &&
            <Button id={id ? `${id}-dropwdown-chevron` : null} 
                    class="fa fa-chevron-down" 
                    look="bare"
                    onFocus={() => this.setState({ isFocusInput: true })}
                    onBlur={() => this.setState({ isFocusInput: false })}
                    style={{border: "none", cursor:"text", backgroundColor: "transparent", fontSize: "11px"}}
                    onClick={() => this.inputRef.current?.focus()}/>}

            { children }

            {!disabled && onClickCancelIcon && !!value &&
            <Button id={id ? `${id}-clear-btn` : null} icon="close" look="bare" title="clear" onClick={onClickCancelIcon} />}

            {!disabled  && onClickPencilIcon && !!value &&
            <Button id={id ? `${id}-add-btn` : null} icon="pencil" look="bare" title="edit" onClick={onClickPencilIcon} /> }

            {!disabled  && onClickTrashIcon && !!value &&
            <Button id={id ? `${id}-add-btn` : null} icon="trash" look="bare" title="delete" onClick={onClickTrashIcon} /> }

            {!disabled && onClickPlusIcon &&
            <Button id={id ? `${id}-add-btn` : null} icon="add" look="bare" title="add" onClick={onClickPlusIcon} />}
        {(isFocusInput || isFocusList) &&
        // <Portal>
          <PhenomDropdownList id={id}
                          value={value}
                          data={dataList}
                          dataItemKey={dataItemKey}
                          showXmiType={showXmiType}
                          disabled={disabled}
                          dataDisabled={dataDisabled}
                          ref={this.listRef}
                          containerRef={this.containerRef}
                          onFocus={() => this.setState({ isFocusList: true })}
                          onBlur={() => this.setState({ isFocusList: false })}
                          onChange={this.handleSelectElement}
                          onCancel={this.handleCancelDropdown} />
        // </Portal>
        }
        </PhenomInput>

    </>
  }
}




const PortalDropDown = forwardRef((props, ref) => {
  const [scrollingDom] = useState(() => {
    let validLocations = ["phenom-content-scrollable"]
    let dom = ref.current;

    while (dom) {
      if (!dom.parentElement) {
        dom = document.querySelector('body');
        break;
      }

      if (validLocations.some(className => dom.parentElement.classList.contains(className))) {
        break;
      }

      dom = dom.parentElement;
    }
    dom.style.setProperty("position", "relative");
    return dom;
  })

  const [containerDom] = useState(() => {
    let containerRect = ref.current.getBoundingClientRect();
    let scrollingRect = scrollingDom.getBoundingClientRect();
    let dom = document.createElement('div');
        dom.style.cssText = `
          position: absolute;
          width: ${containerRect.width}px;
          top: ${containerRect.top + containerRect.height - scrollingRect.top}px;
          left: ${containerRect.left - scrollingRect.left}px;
          z-index: 20;
        `
    scrollingDom.appendChild(dom);
    return dom;
  })

  useEffect(() => {
    return () => {
      scrollingDom && containerDom && scrollingDom.removeChild(containerDom);
      scrollingDom && scrollingDom.style.removeProperty('position');
    }
  }, [])

  return ReactDOM.createPortal(props.children, containerDom);
});



/**
 * Used with Combobox
 */

/**
 * PhenomDropdownList component for displaying a dropdown list with dynamic data.
 * @param {Object} props - Component props.
 * @param {string} props.id - Identifier for the component.
 * @param {Array} props.data - Array of data items to display in the dropdown list.
 * @param {Array} props.dataDisabled - Array of disabled data item keys.
 * @param {string} props.dataItemKey - Key to identify each data item.
 * @param {string} props.textField - Field name to display as text for each item.
 * @param {Object} props.value - Currently selected value object.
 * @param {boolean} props.showXmiType - Whether to show XMI type information.
 * @param {Object} props.containerRef - Reference to the container element.
 * @param {function} props.onChange - Function called when a dropdown item is selected.
 * @param {function} props.onCancel - Function called when the cancel action is triggered.
 * @param {function} props.onFocus - Function called when the dropdown gains focus.
 * @param {function} props.onBlur - Function called when the dropdown loses focus.
 * @param {React.RefObject} ref - Forwarded ref to the dropdown list component.
 * @returns {JSX.Element} PhenomDropdownList component.
 */

export const PhenomDropdownList = forwardRef(({ id, data=[], dataDisabled=[], dataItemKey, textField, value, showXmiType=false,
                                          containerRef, onChange, onCancel, onFocus, onBlur }, ref) => {
  const [focusIdx, setFocusIdx] = useState(0);

  const handleKeyPress = (e, element) => {
    e.preventDefault();

    // Esc
    if (e.keyCode === 27) {
      onCancel && onCancel();
    }

    // Enter or Spacebar
    if (e.keyCode === 13 || e.keyCode === 32) {
      !e.target.classList.contains("disabled") && onChange && onChange(element);
    }

    // ArrowUp
    if (e.keyCode === 38) {
      const items = ref.current.querySelectorAll('li');
      const newIdx = focusIdx === 0 ? items.length - 1 : focusIdx - 1;
      items[newIdx] && items[newIdx].focus();
      setFocusIdx(newIdx);
    }

    // ArrowDown
    if (e.keyCode === 40) {
      const items = ref.current.querySelectorAll('li');
      const newIdx = (focusIdx + 1) % items.length;
      items[newIdx] && items[newIdx].focus();
      setFocusIdx(newIdx);
    }
  }

  const handleMouseDown = (e, element) => {
    e.preventDefault();
    !e.target.classList.contains("disabled") && onChange && onChange(element);
  }

  return (<PortalDropDown ref={containerRef}>
    <div className="cadet-dropdown-list"
         ref={ref}>

      {!!data.length
        ? <ul>
            {data.map((item, idx) => {
              const classes = [];
              const itemValue = dataItemKey ? item[dataItemKey] : item;
              const isDisabled = dataDisabled.some((ele) => ele === itemValue);

              if (itemValue === value?.[dataItemKey] || itemValue === value) {
                classes.push("active");
              }

              if (isDisabled) {
                classes.push("disabled")
              }

              return <li key={`dropdownlist-${itemValue}`}
                        id={`dropdownlist-${itemValue}`}
                        className={classes.join(" ")}
                        tabIndex="0"
                        onKeyDown={(e) => handleKeyPress(e, item)}
                        onMouseDown={(e) => handleMouseDown(e, item)}
                        onFocus={onFocus}
                        onBlur={onBlur}>
                      {showXmiType && item?.xmiType && `[${item.xmiType.match(/.*:(.*)/)[1]}] `}
                      {item.text || item.name || item.rolename || itemValue}
                    </li>
            })}
          </ul>
        : <div className="no-data">
            No Data Found.
          </div> }
    </div>
    </PortalDropDown>
  )
})


// TODO: REMOVE
// radio buttons is the preferred
// keeping code for now - in case I need it for UI redesign
export const CircleLetter = styled.button`
  --hue-sat: ${p => p.hue_sat || "var(--skayl-violet-hs)"};
  --lightness: ${p => p.lightness || "60%"};
  --darkness: calc(var(--lightness) - 30%);

  display: inline-block;
  position: relative;
  font-size: ${p => p.fontSize ? p.fontSize + "px" : null};
  font-style: normal;
  height: ${p => p.widthSize || 26}px;
  width: ${p => p.widthSize || 26}px;
  padding: 0;
  border-radius: 50%;
  color: ${p => p.color || "hsl(var(--hue-sat) var(--darkness))"};
  background: hsl(var(--hue-sat) var(--lightness));
  border: none;
  cursor: auto;

  &:after {
    display: block;
    content: "${p => p.letter}";
    width: ${p => p.widthSize || 26}px;
  }
`

// TODO: REMOVE
// radio buttons is the preferred
// keeping code for now - in case I need it for UI redesign
export const CircleLetterToggle = styled(CircleLetter)`
  --ms: 250ms;
  --animation: ease-in-out;
  transition: background var(--ms) var(--animation), color var(--ms) var(--animation);

  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  &:not([disabled]):hover {
    cursor: pointer;
    color: ${p => p.color || "hsl(var(--hue-sat) var(--lightness))"};
    background: hsl(var(--hue-sat) var(--darkness));
  }
`


// ------------------------------------------------------------
// # Node Detail
// ------------------------------------------------------------

/**
 * NodeBoxWithFetch component fetches node data based on a GUID and renders a NodeBox with children.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.guid - The GUID used to fetch node data.
 * @param {string} props.id - The ID attribute for the NodeBox component.
 * @param {JSX.Element} props.children - The children elements to render within the NodeBox.
 *
 * @returns {JSX.Element} The rendered NodeBox component with fetched node data and children.
 */

export const NodeBoxWithFetch = (props) => {
  const [node, setNode] = useState({});

  useEffect(() => {
    if (props.guid) {
      // guid changed - fetch new node
      setNode({});

      modelGetNode(props.guid)
        .then((res) => {
          setNode(res);
        })
        .fail(() => {
          console.error(`failed to retrieve ${props.guid}`);
          setNode({});
        })

    } else {
      // received invalid guid
      setNode({});
    }
  }, [props.guid])


  return <NodeBox id={props.id} node={node}>
          {props.children}
        </NodeBox>
}

/**
 * NodeBox component renders a box representation for a node with optional children elements.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.node - The node object containing data to render.
 * @param {string} [props.id=null] - The optional ID attribute for the NodeBox component.
 * @param {JSX.Element} props.children - The optional children elements to render within the NodeBox.
 *
 * @returns {JSX.Element | null} The rendered NodeBox component or null if the node data is invalid.
 */

export const NodeBox = ({ node, id=null, children }) => {
  // invalid node
  if (!node?.guid || !node?.xmiType) return null;
  if (id) id += `${node.guid}-node-box`;

  return <div id={id} className="node-box">
            <header>
              <i>{splitCamelCaseWithSpace(splitXmiType(node.xmiType))}</i>

            </header>
            <div id={id ? id + "-name" : null}>{node.name || node.rolename}</div>
            {node.description &&
              <p>{node.description}</p> }

            <div className="node-box-btns">
              <PhenomButtonLink node={node} newTab={true}/>
            </div>

            { children }
        </div>
}






// ------------------------------------------------------------
// # Symbols / Icons
// ------------------------------------------------------------

/**
 * DeprecatedWarning component renders a warning icon if the component or feature is deprecated.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.deprecated="false"] - Indicates if the component or feature is deprecated.
 * @param {any} props.restProps - Additional props to be spread onto the warning icon element.
 *
 * @returns {JSX.Element | null} The rendered warning icon element if deprecated is true, otherwise null.
 */

export const DeprecatedWarning = ({deprecated="false", ...restProps}) => {
  if (deprecated === "true") {
    return <span className="bs-text-warning"
                 data-tip="Deprecated"
                 data-for="hoverTip"
                 {...restProps}>&#9888;</span>
  } else {
      return null
  }
}


// ------------------------------------------------------------
// # Resize
// ------------------------------------------------------------
export const ResizeBarHorizontal = ({ onResize, ...restProps }) => {

  const handleResize = (e) => {
    e.stopPropagation();
    let prev_mouse_x = e.pageX;

    const start = (e) => {
      e.preventDefault();
      // calculate the change in mouse position and send to callback function
      onResize && onResize(prev_mouse_x - e.pageX);
      prev_mouse_x = e.pageX;
    }

    const stop = () => {
      window.removeEventListener("mousemove", start);
      window.removeEventListener("mouseup", stop);
    }

    window.addEventListener("mousemove", start);
    window.addEventListener("mouseup", stop);
  }


  return <div className="resize-horizontal"
              onDragStart={(e) => e.preventDefault}
              onMouseDown={handleResize}
              {...restProps} />
}


export const ResizeBarVertical = ({ onResize, ...restProps }) => {
  const handleResize = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let prev_mouse_y = e.pageY;

    const start = (e) => {
      // calculate the change in mouse position and send to callback function
      onResize && onResize(e.pageY - prev_mouse_y);
      prev_mouse_y = e.pageY;
    }

    const stop = () => {
      window.removeEventListener("mousemove", start);
      window.removeEventListener("mouseup", stop);
    }

    window.addEventListener("mousemove", start);
    window.addEventListener("mouseup", stop);
  }


  return <div className="resize-vertical"
              onDragStart={(e) => e.preventDefault}
              onMouseDown={handleResize}
              {...restProps} />
}



// ------------------------------------------------------------
// ## Uncategorized
// ------------------------------------------------------------
export const DetailBox = ({show, label, name, description}) => {
    if (show) {
        return (
            <div className="detail-box">
                <span>{label}</span>
                <input
                    className="cadet-text-input"
                    disabled={true}
                    type="text"
                    value={name}
                />
                <textarea
                    disabled={true}
                    value={description || ""}>
        </textarea>
            </div>
        );
    } else {
        return false;
    }
};









export const CadetLink = ({node, style=null, look, text, newPage=false, onClick, idCtx}) => {
    if(!node) return null;
    
        return <Link id={idCtx + "-cadet-link"}
                      className="cadet-anchor"
                      style={style}
                      to={createNodeUrl(node)}
                      target={newPage ? "_blank" : null}
                      onClick={onClick}>
                  {text || node.name || node.rolename}
              </Link>
}

/**
 * SkoogleResult component renders a search result item for Skoogle, displaying node information and description.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.node - The node object containing information to display.
 * @param {function} props.closeModal - The callback function to close the modal or handle result click.
 * @param {string} props.id - The ID attribute for the SkoogleResult component.
 * @param {string} props.idCtx - The context ID used to uniquely identify the SkoogleResult component instance.
 * @param {number} props.order - The order or index of the SkoogleResult in the list.
 *
 * @returns {JSX.Element} The rendered SkoogleResult component displaying node details and description.
 */

export const SkoogleResult = ({node, closeModal, id, idCtx, order}) => {
    const description = node.description || node.descriptionExtension || "NO DESCRIPTION";
    idCtx = idCtx+"-skoogle-result-"+order;

    return (
        <div style={{borderBottom: "1px solid gray", width: "100%", margin: "4px 0 4px 20px"}} id={idCtx}>
          <span style={{fontSize: "90%"}}>
            <PhenomLink node={node} onClick={closeModal} showParent={false} />
            &nbsp; in &nbsp;
            <PhenomLink node={node.parent} onClick={closeModal} />
          </span>
            <Lwp txt={description} max={300} style={{fontSize: "90%"}} idCtx={idCtx}/>
        </div>
    );
};

export const modifiedPairs = (pathPairs) => {
    function goingUp(startingIdx, pathGuids) {
        const latestHop = pathGuids[startingIdx];
        const sLatestHop = pathGuids[startingIdx + 1];
        if (!sLatestHop || latestHop.xmiType === "conceptual:Composition") {
            return false;
        }
        const latestTyperGuid = latestHop.type.guid;
        const sLatestTyperGuid = sLatestHop.type.guid;

        if (latestTyperGuid !== sLatestTyperGuid) {
            if (sLatestHop.xmiType === "conceptual:Composition") {
                return sLatestHop.parent.guid === latestHop.parent.guid;
            } else {
                if (latestHop.parent.guid === sLatestHop.parent.guid) {
                    return !(goingUp(startingIdx + 1, pathGuids));
                } else {
                    return (goingUp(startingIdx + 1, pathGuids));
                }
            }
        } else {
            if (sLatestHop.xmiType === "conceptual:Composition") {
                return true;
            } else {
                return !(goingUp(startingIdx + 1, pathGuids));
            }
        }
    }

    pathPairs.forEach((pair, idx) => {
        pair["goingUp"] = goingUp(idx, pathPairs);
    });
    return pathPairs;
};

/**
 * AnchoredPath component for displaying a path with anchored links and optional warnings.
 * @param {Object} props - Component props.
 * @param {Object} props.pathHead - Head object for the path.
 * @param {Object[]} props.pathPairs - Array of path pairs containing type and parent objects.
 * @param {Object} props.style - Inline styles for customization.
 * @param {boolean} props.txtOnly - Whether to render only text content without links.
 * @param {boolean} props.fullError - Whether to show full error details.
 * @param {boolean} props.hasNormalAnchor - Whether there is a normal anchor present.
 * @param {boolean} props.isHealthCheck - Indicates if it's a health check.
 * @param {boolean} props.ignorePathHeadTxt - Whether to ignore path head text.
 * @param {string} props.idCtx - Identifier context for the component.
 * @param {boolean} props.pathIsValid - Whether the path is valid.
 * @returns {JSX.Element|null} AnchoredPath component.
 */

export const AnchoredPath = ({pathHead, pathPairs, style, txtOnly = false, fullError = false, hasNormalAnchor = false, isHealthCheck = false, ignorePathHeadTxt = false, idCtx, pathIsValid = true}) => {
    if (!pathPairs.length) return null;
    const usedPathHead = pathHead || (pathPairs[0]["goingUp"] ? pathPairs[0].type : pathPairs[0].parent);

    let hasDeprecation = usedPathHead.deprecated && usedPathHead.deprecated === "true";
    pathPairs = modifiedPairs(pathPairs);
    idCtx += "-anchored-path"

    const pathHeadPortion =
        <span key="head_portion">
            {fullError || <strong
                title="PHENOM has detected a possible error in this path"
                style={{ color: "crimson", cursor: "pointer",}}>
                {!pathIsValid ? "⚠" : ""}
            </strong>}
            <NavLink
                className={`cadet-anchor ${hasNormalAnchor ? " normal-anchor" : ""}`}
                to={`/edit/details/entity/${usedPathHead.guid}/`}
                key="head" id={idCtx+"-head-link"}>
                {usedPathHead.deprecated === "true" ? <img src={deprecatedSrc} style={{"height" : "20px"}}/> : null}
                {usedPathHead.name}
             </NavLink>
        </span>;

    const response = pathPairs.map((pair, idx) => {
        const goingUp = pathPairs[idx]["goingUp"];
        const linkContent = goingUp ? `->${pair.rolename}[${pair.parent.name}]` : `.${pair.rolename}`;

        if (pair.type.deprecated === "true" || pair.deprecated === "true"){
            hasDeprecation = true
        }
        if (txtOnly) {
            return linkContent;
        } else {
            return (
                <NavLink
                    className={`cadet-anchor ${hasNormalAnchor ? " normal-anchor" : ""}`}
                    key={idx}
                    style={style}
                    to={`/edit/details/entity/${(goingUp || (idx === pathPairs.length - 1)) ? pair.parent.guid : pair.type.guid}/`}
                    id={idCtx+"-pair"+`${idx}`+"-link"}>
                    {pair.type.deprecated === "true" || pair.deprecated === "true" ? <img src={deprecatedSrc} style={{"height" : "20px"}}/> : null}
                    {linkContent}
                </NavLink>);
        }
    });

    if (!ignorePathHeadTxt) {
        response.unshift(txtOnly ? usedPathHead : pathHeadPortion);
    }
    if (hasDeprecation && !isHealthCheck) {
       response.unshift(
           <div
              style={{color: "crimson",}}>
              "Warning path contains deprecation error(s)"
           </div>);
    }
    if (!pathIsValid && fullError) {
        response.push(
            <div
                style={{color: "crimson",fontSize: "90%",}}>
                {"\n\n\n ⚠ PHENOM has detected a possible error in this path. Editing the path may change it irreversibly."}
            </div>);
    }
    return response;
};

/**
 * Lwtd component renders a truncated text in a table cell with a tooltip displaying the full text.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.txt - The text content to display.
 * @param {number} [props.max=25] - The maximum length of text to display before truncation.
 * @param {string} props.idCtx - The context ID used to uniquely identify the Lwtd component instance.
 *
 * @returns {JSX.Element} The rendered table cell with truncated text and tooltip showing the full text.
 */

export const Lwtd = ({txt, max = 25, idCtx}) => {
    const displayTxt = txt.length > max ? txt.slice(0, max) + "..." : txt;
    return <td id={idCtx+"-lwtd"} title={txt}>{displayTxt}</td>;
};

/**
 * Lwa component renders a truncated text within a NavLink component, with a tooltip displaying the full text.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.txt - The text content to display.
 * @param {number} [props.max=25] - The maximum length of text to display before truncation.
 * @param {string} props.href - The destination URL for the NavLink.
 * @param {string} [props.className="cadet-anchor"] - The CSS class for styling the NavLink.
 * @param {string} props.idCtx - The context ID used to uniquely identify the Lwa component instance.
 *
 * @returns {JSX.Element} The rendered NavLink with truncated text and tooltip showing the full text.
 */

export const Lwa = ({txt, max = 25, href, className = "cadet-anchor", idCtx}) => {
    const displayTxt = txt.length > max ? txt.slice(0, max) + "..." : txt;
    return <NavLink to={href} id={idCtx+"-lwa"} className={className} title={txt}>{displayTxt}</NavLink>;
};

/**
 * Lwp component renders a paragraph with truncated text and a tooltip displaying the full text.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.txt - The text content to display.
 * @param {number} [props.max=325] - The maximum length of text to display before truncation.
 * @param {Object} [props.style={}] - The inline CSS styles to apply to the paragraph element.
 * @param {string} props.idCtx - The context ID used to uniquely identify the Lwp component instance.
 *
 * @returns {JSX.Element} The rendered paragraph with truncated text and tooltip showing the full text.
 */

export const Lwp = ({txt, max = 325, style = {}}, idCtx) => {
    const displayTxt = txt.length > max ? txt.slice(0, max) + "..." : txt;
    return <p style={style} id={idCtx+"-lwp"} title={txt}>{displayTxt}</p>;
};

export class FadingDirections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {opacity: 0.5, display: "block"}
        };
    }

    hide = () => {
        this.setState({style: {opacity: 0, display: "block"}});
        setTimeout(() => this.setState({style: {display: "none"}}), 100);
    };

    render() {
        return (
            <div
                id={this.props.idCtx+"-fading-directions"}
                className="fading-directions"
                style={{...this.state.style, ...this.props.style}}
                onMouseEnter={this.hide}
                onDragOver={this.hide}>
                {this.props.text}
            </div>
        );
    }
}

// PROPS: options - an array of two possible options visible to the user
//        startingPosition - either a 0 or 1 indicating the index of the default option
//        toggleFunction - a function to run when the toggle is clicked
//        disabled - whether or not the toggle is disabled (if disabled, the toggle will present as a simple field with the startingPosition value)
//        style - an object with styling to be applied to the toggle
export class Toggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: this.props.startingPosition,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.position !== this.state.position && this.state.position !== this.props.startingPosition) {
            this.props.toggleFunction();
        }
        if (prevProps.startingPosition !== this.props.startingPosition && this.state.position !== this.props.startingPosition) {
            this.setState({position: this.props.startingPosition});
        }
    }

    render() {
        const idCtx = this.props.idCtx + "-toggle"
        if (this.props.disabled) {
            return (
                <div title={this.props.title || ""}
                     id={idCtx}
                     style={{...this.props.style, ...{color: "rgb(116, 117, 117)", fontSize: "85%"}}}>
                    {this.props.options[this.props.startingPosition]}
                </div>
            );
        } else {
            return (
                <button title={this.props.title || ""} className="toggle"
                id={idCtx+"-button"} onClick={() => {
                    if (this.props.locked) return;
                    this.setState({position: this.state.position ? 0 : 1});
                }} style={this.props.style}>
                    <div
                        id="first"
                        className={this.state.position ? "tglRight" : "tglLeft"}>
                    </div>
                    <div
                        id="second"
                        style={{left: this.state.position ? 0 : 22, textAlign: this.state.position ? "start" : "end"}}
                    >
                        {this.props.options[this.state.position]}
                    </div>
                </button>
            );
        }
    }
}

// PROPS: options (Array) - the list of options to display; each option should have a text and value
//        onSelect (Function) - the function that is run when a selection is made; it should take as an argument the click event on the list of options
export class FauxAutofill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            input: this.props.text,
            madeSelection: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.text !== this.props.text) {
            this.setState({input: this.props.text});
        }
        if (prevProps.text !== this.state.input && prevState.madeSelection !== this.state.madeSelection) {
            this.setState({madeSelection: false});
        }
    }

    focus = () => {
        this.setState({focused: true});
    };

    blur = () => {
        this.setState({focused: false});
        if (this.props.allowCustom && !this.state.madeSelection) {
            this.props.onSelect(this.state.input || "");
        }
    };

    clearSearch = () => {
      this.setState({ input: "" });
    }

    makeSelection = e => {
        this.setState({focused: false, madeSelection: true});
        this.props.onSelect(e.target.id);
    };

    generateOptions() {
        return this.props.options.map((option, idx) => {
            if (this.state.input === "" || option.text.toLowerCase().includes(this.state.input.toLowerCase())) {
                return <li onMouseDown={this.makeSelection} id={option.value} key={idx}>{option.deprecated ? <img src={deprecatedSrc} style={{"height" : "20px"}}/> : null}{option.text}</li>;
            }
        }).filter(el => !!el);
    }

    render() {
        const idCtx = (this.props.id || this.props.idCtx+"-faux-auto-fill")
        return (
            <div ref={this.props.fauxRef} style={this.props.style}>
                <CadetInput
                    idCtx={idCtx}
                    style={{width: this.props.inputWidth, ...this.props.inputStyle}}
                    onChange={this.props.options.length > 0 ? (e) => this.setState({input: e.target.value}) : null}
                    onFocus={this.focus}
                    onBlur={this.blur}
                    text={this.props.options.length > 0 ? this.state.input : "No Possible Options"}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                />
                <button className="faux-clear" style={this.props.clearStyle} onClick={() => {
                    this.setState({input: ""});
                }}
                id={idCtx+"-clear-button"}>x
                </button>
                <ul
                    className="faux-autofill"
                    style={{...{visibility: this.state.focused ? "visible" : "hidden"}, ...this.props.listStyle}}
                >
                    {this.generateOptions()}
                </ul>
            </div>
        );
    }
}

// yes, these ones are NOT stateless, but come on! it is pretty much for one toggle. that doesn't even count
export class ImpactListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    render() {
        const current = this.props.listings[0].old_path;
        const proposed = this.props.listings[0].new_path;
        const idCtx = this.props.idCtx+"impact-listing"
        return (
            <tbody id={idCtx+"-wrapper"}>
            <tr>
                <td>{this.props.idx + 1}</td>
                <td className="hover-for-detail-cell">
                    {current.substring(0, 60) + (current.length > 60 ? "..." : "")}
                    {current.length <= 60 || <div className="hidden-detail">{current}</div>}
                </td>
                <td className="hover-for-detail-cell">
                    {proposed.substring(0, 60) + (proposed.length > 60 ? "..." : "")}
                    {proposed.length <= 60 || <div className="hidden-detail">{proposed}</div>}
                </td>
                <td>impacts {this.props.listings.length} View Characteristics</td>
                <td>
                    <button onClick={() => this.setState({expanded: !this.state.expanded})}
                            className={"form-button " + (this.state.expanded ? "collapse-up" : "expand-down")}/>
                </td>
            </tr>
            {!this.state.expanded || <tr className="fake-header">
                <td/>
                <td>View</td>
                <td>Characteristic</td>
                <td/>
                <td/>
            </tr>}
            {!this.state.expanded || this.props.listings.map((listing, idx, arr) => {
                const padding = idx === arr.length - 1 ? {paddingBottom: 10} : {};
                return (
                    <tr key={idx}>
                        <td style={padding}/>
                        <td style={padding}>
                            <NavLink className="cadet-anchor"
                                     to={`/edit/details/view/${listing.parent_guid}/`}
                                     id={idCtx+"-parent-link"}>{listing.parent_name}</NavLink>
                        </td>
                        <td style={padding}>
                            <NavLink className="cadet-anchor"
                                     to={`/edit/details/characteristic/${listing.guid}/`}
                                     id={idCtx+"-characteristic-link"}>{listing.name}</NavLink>
                        </td>
                        <td style={padding}/>
                        <td style={padding}/>
                    </tr>
                );
            })}
            </tbody>
        );
    }
}

// Note that the contentId given refers to a <table id=xxx> containing the ColorCollapsable that is outside and encasing the component itself

// props = [content, color, heading, contentId, vMargin (no longer sure what this does...), default (the default position of collapse or not), hasCheckBox, onCheck, checkBoxValue]
/**
 * ColorCollapsable component provides a collapsible panel with customizable header and content.
 *
 * @extends React.Component
 */
export class ColorCollapsable extends React.Component {
  /**
     * Constructs a ColorCollapsable component.
     *
     * @param {Object} props - The properties passed to the component.
     * @param {boolean} [props.default=false] - Indicates if the panel should be collapsed by default.
     * @param {string} props.contentId - The ID of the content element to measure height.
     * @param {number} props.vMargin - Vertical margin applied to adjust content height calculation.
     * @param {string} props.idCtx - The context ID used to uniquely identify the ColorCollapsable component instance.
     * @param {string} props.color - The background color of the header.
     * @param {boolean} props.hasCheckBox - Indicates if a checkbox should be displayed in the header.
     * @param {boolean} props.checkBoxValue - The current value of the checkbox.
     * @param {function} props.onCheck - Callback function invoked when the checkbox value changes.
     * @param {string} props.heading - The text to display as the header content.
     * @param {boolean} props.noToggle - Indicates if the toggle button should be hidden.
     * @param {Object} props.collapsableStyle - Additional CSS styles applied to the collapsable content.
     * @param {JSX.Element} props.content - The JSX element to render inside the collapsable panel.
     * @param {JSX.Element} props.headingComponent - An alternate heading which will render a js element as the heading
     */
    constructor(props) {
        super(props);
        this.state = {
            collapsed: this.props.default || false,
            contentHeight: "auto",
        };
    }

    componentDidMount() {
        if (document.getElementById(this.props.contentId)) {
            const realHeight = document.getElementById(this.props.contentId).offsetHeight + this.props.vMargin * 2;
            this.setState({contentHeight: realHeight > 500 ? 500 : realHeight});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.content !== prevProps.content) {
            if (document.getElementById(this.props.contentId)) {
                const realHeight = document.getElementById(this.props.contentId).offsetHeight + this.props.vMargin * 2;
                this.setState({
                    contentHeight: realHeight > 500 ? 500 : realHeight,
                    collapsed: prevState.collapsed,
                });
            }
        }
        if (this.props.default !== prevProps.default) {
            this.setState({collapsed: this.props.default});
        }
    }

    render() {
        const idCtx = this.props.idCtx + "-color-collapsable";
        return (
            <div style={{width: "100%", cursor: "pointer"}} id={idCtx+"-toggle-wrapper"}>
                <div
                    id={idCtx+"-toggle-div"}
                    className='impact-header'
                    onDoubleClick={() => !this.props.noToggle && this.setState({collapsed: !this.state.collapsed})}
                    style={{backgroundColor: this.props.color || "white", marginTop: 0, color: !this.props.color && "black"}}>
                    <div>
                      {this.props.hasCheckBox &&
                          <input
                            style={{margin: "0 10px 0 0"}}
                            type="checkbox"
                            onChange={(e) => this.props.onCheck(e.target.checked)}
                            checked={this.props.checkBoxValue}
                            />}
                      {this.props.headingComponent ? this.props.headingComponent : this.props.heading}
                    </div>
                    
                    {!this.props.noToggle && <button
                        className={"form-button " + (this.state.collapsed ? "expand-down" : "collapse-up") + "-white"}
                        onClick={() => this.setState({collapsed: !this.state.collapsed})}
                        id={idCtx+"-toggle-button"}>
                    </button>}
                </div>
                <div className="collapsable" style={{
                    ...{
                        height: (this.state.collapsed || !this.props.content ? 0 : this.state.contentHeight + (this.props.hasBtnActions ? 32 : 0)),
                        backgroundColor: "white",
                        paddingRight: "40px",
                        cursor: "default"
                    }, ...this.props.collapsableStyle
                }}
                id={idCtx+"-content-wrapper"}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}

// props = [content, color, heading, contentId, vMargin (no longer sure what this does...), default (the default position of collapse or not)]
export class ColorCollapsable2 extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          collapsed: this.props.default || false,
          contentHeight: "auto",
      };
  }

  componentDidMount() {
      if (document.getElementById(this.props.contentId)) {
          const realHeight = document.getElementById(this.props.contentId).offsetHeight + this.props.vMargin * 2;
          this.setState({contentHeight: realHeight > 500 ? 500 : realHeight});
      }
  }

  componentDidUpdate(prevProps, prevState) {
      if (this.props.content !== prevProps.content) {
          if (document.getElementById(this.props.contentId)) {
              const realHeight = document.getElementById(this.props.contentId).offsetHeight + this.props.vMargin * 2;
              this.setState({
                  contentHeight: realHeight > 500 ? 500 : realHeight,
                  collapsed: prevState.collapsed,
              });
          }
      }
      if (this.props.default !== prevProps.default) {
          this.setState({collapsed: this.props.default});
      }
  }

  render() {
      const idCtx = this.props.idCtx + "-color-collapsable";
      return (
        <div style={{width: "100%", cursor: "pointer"}} id={idCtx+"-toggle-wrapper"}
        className="color-collapsable2-wrapper">
            <div
                id={idCtx+"-toggle-div"}
                onClick={() => this.setState({collapsed: !this.state.collapsed})}
                style={{backgroundColor: this.props.color || "white", color: !this.props.color && "black", paddingRight:5, paddingLeft: 1}}
                className="color-collapsable2">
                {!this.props.noToggle && <button
                    className={"fa-icon-button " + (this.state.collapsed ? "fa-solid fa-caret-right" : "fa-solid fa-caret-down")}
                    onClick={() => this.setState({collapsed: !this.state.collapsed})}
                    id={idCtx+"-toggle-button"}>
                </button>}
                {this.props.heading}
            </div>
            <div className="collapsable" style={{
                ...{
                    height: (this.state.collapsed || !this.props.content ? 0 : this.state.contentHeight + (this.props.hasBtnActions ? 32 : 0)),
                    backgroundColor: "white",
                    paddingRight: "40px",
                    cursor: "default"
                }, ...this.props.collapsableStyle
            }}
            id={idCtx+"-content-wrapper"}>
              {this.props.content}
            </div>
        </div>
    );
}
}

/**
 * CollapseHeader component provides a collapsible header with toggle button.
 *
 * @extends React.Component
 */

export class CollapseHeader extends React.Component {
  /**
     * Constructs a CollapseHeader component.
     *
     * @param {Object} props - The properties passed to the component.
     * @param {string} props.text - The text to display in the header.
     * @param {string} props.idCtx - The context ID used to uniquely identify the CollapseHeader component instance.
     */
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
        this.btnStyle = {position: "absolute", bottom: 5, right: 0};
    }

    render() {
        const idCtx = this.props.idCtx + "-collapse-header";
        return (
            <header className="collapse-header collapsable" style={{height: (this.state.collapsed ? 22 : 100)}} id={idCtx+"-wrapper"}>
                <h1 style={{visibility: this.state.collapsed ? "hidden" : "visible"}}>{this.props.text}</h1>
                <button style={this.btnStyle}
                        className={"form-button " + (this.state.collapsed ? "expand-down" : "collapse-up")}
                        onClick={() => this.setState({collapsed: !this.state.collapsed})}
                        id={idCtx+"-button"}/>
            </header>
        );
    }
}

/**
 * PasswordStrengthIndicator component provides visual feedback and validation messages
 * for the strength of a password.
 *
 * @extends React.Component
 */

export class PasswordStrengthIndicator extends React.Component {
  /**
     * Constructs a PasswordStrengthIndicator component.
     *
     * @param {Object} props - The properties passed to the component.
     * @param {string} props.pswd - The password to evaluate and display strength for.
     * @param {function} props.updateCheckStatus - Callback function to update the check status.
     * @param {string} props.idCtx - The context ID used to uniquely identify the PasswordStrengthIndicator component instance.
     */
    constructor(props) {
        super(props);
    }

    render() {
        const pswd = this.props.pswd;

        const upperCaseCount = 1;
        const lowerCaseCount = 1;
        const numberCount = 1;
        const specialCount = 1;

        const descriptiveMatches = [
            ["Weak", 1, "(?=.*[a-z])"],
            ["Okay", 2, "(?=.*[a-z])(?=.*[A-Z]).{6,}|(?=.*[a-z])(?=.*[0-9]).{6,}"],
            ["Strong", 3, "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{10,}"],
            ["Very strong", 4, "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-z0-9]).{12,}"],
        ];

        let strengthMsg = [];

        descriptiveMatches.forEach(entry => {
            const rgx = entry[2];
            if (new RegExp(rgx).test(pswd)) {
                strengthMsg = entry;
            }
        });

        const upperCaseMatch = pswd.match(new RegExp(`[A-Z]{${upperCaseCount}}`));
        const lowerCaseMatch = pswd.match(new RegExp(`[a-z]{${lowerCaseCount}}`));
        const numberMatch = pswd.match(new RegExp(`[0-9]{${numberCount}}`));
        const specialMatch = pswd.match(new RegExp(`[-!$%^&*()_+|~=\`{}[:;<>?,.@#\\]]{${specialCount}}`));
        const passwordLength = pswd.match(new RegExp(`.{7,}`));
        let errorMsgs = [];

        if (!(upperCaseMatch && upperCaseMatch.length)) errorMsgs.push(`Password must contain at least ${upperCaseCount} upper case letter.`);
        if (!(lowerCaseMatch && lowerCaseMatch.length)) errorMsgs.push(`Password must contain at least ${lowerCaseCount} lower case letter.`);
        if (!(numberMatch && numberMatch.length)) errorMsgs.push(`Password must contain at least ${numberCount} number.`);
        if (!(specialMatch && specialMatch.length)) errorMsgs.push(`Password must contain at least ${specialCount} special character.`);
        if (!(passwordLength && passwordLength.length)) errorMsgs.push(`Password must contain at least 7 characters.`);

        errorMsgs = errorMsgs.map((msg, idx) => <li key={idx}>{msg}</li>);

        this.props.updateCheckStatus(!errorMsgs.length);

        const idCtx = this.props.idCtx + "-password-strength-indicator";

        return (pswd ?
            <div id={idCtx+"-wrapper"}>
        <span style={{display: "flex", alignItems: "center"}}>
          <meter value={strengthMsg ? strengthMsg[1] : 0} max="4" className="pswdStrengthMeter" id={idCtx+"-meter"}/>
            {strengthMsg ? strengthMsg[0] : ""}
        </span>
                <ul style={{fontSize: "75%"}}>
                    {errorMsgs}
                </ul>
            </div>
            : "");
    }
}

/**
 * TypeAutoFill component provides auto-fill functionality for selecting types from a dropdown.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.data - The array of data items to populate the dropdown.
 * @param {function} props.onChange - The callback function invoked when the selection changes.
 * @param {string} props.value - The current selected value.
 * @param {string} props.placeholder - The placeholder text for the input.
 * @param {string} props.textField - The name of the field in the data object to display as text.
 * @param {Object} props.style - The inline styles to apply to the component.
 * @param {boolean} props.disabled - Indicates if the component should be disabled.
 * @param {string} props.idCtx - The context ID used to uniquely identify the TypeAutoFill component instance.
 * @param {boolean} props.showAll - Flag to indicate if all options should be initially shown.
 * @param {boolean} props.addTypeIdent - Flag to indicate if type identifiers should be added to displayed items.
 *
 * @returns {JSX.Element} The rendered TypeAutoFill component.
 */

export const TypeAutoFill = ({data, onChange, value, placeholder, textField, style, disabled, idCtx, showAll = false, addTypeIdent = false}) => {
    const [fltData, setFltData] = useState([]);
    const [subData, setSubData] = useState(data);
    const [expanded, setExpanded] = useState(false);
    const [pageSkip, setPageSkip] = useState(0);
    const pageSize = 12;
    const dispTypeMap = {
        "conceptual:Entity": "(ENT)",
        "conceptual:Association": "(ASC)",
        "conceptual:Observable": "(OBS)"
    };
    const onFilterChange = (event) => {
        let sortData;
        if (event) {
            const val = showAll ? "" : event.filter.value;
            sortData = data.sort((node1, node2) => sortNodesByType(node1, node2))
                           .filter(opt => new RegExp(val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
                           .replace(/\s/g, ".*"), "i").test(`(${dispTypeMap[opt.xmiType]}) ${opt.name}`));

        } else {
            sortData = data;
        }
        const cutData = sortData.slice(0, pageSize);

        if (!sortData.length) return;

        setFltData(sortData);
        setSubData(cutData);
        setPageSkip(0);
    };


    if (!fltData.length && data) {
        onFilterChange();
    }

    const pageChange = (event) => {
        const skip = event.page.skip;
        const take = event.page.take;
        const newSubsetData = fltData.slice(skip, skip + take);

        setSubData(newSubsetData);
        setPageSkip(skip);
    };

    useEffect(() => {
      setSubData(data);
    }, [data]);
    idCtx += "-type-auto-fill";
    let idIndex = 0;

    return <KComboBox
        id={idCtx+"-k-combo-box"}
        disabled={disabled}
        placeholder={placeholder}
        opened={expanded}
        onFocus={() => setExpanded(true)}
        onClose={() => setExpanded(false)}
        data={subData}
        onChange={onChange}
        value={value}
        allowCustom={false}
        filterable={true}
        onFilterChange={e => {
            setExpanded(true);
            onFilterChange(e);
        }}
        onPageChange={pageChange}
        className="phenom-combo-box"
        textField={textField}
        style={style}
        virtual={{
            total: fltData.length,
            pageSize: pageSize,
            skip: pageSkip
        }}
        itemRender={(li, itemProps) => {
            const {dataItem} = itemProps;
            const {xmiType} = dataItem;
            const itemChildren = <span id={idCtx+"-k-combo-"+idIndex++}>{dataItem.deprecated === "true" ? <img src={deprecatedSrc} style={{"height" : "20px"}}/> : null}{(addTypeIdent && dispTypeMap[xmiType]) && dispTypeMap[xmiType] + " "}{li.props.children}</span>;
            return React.cloneElement(li, li.props, itemChildren);
        }}
    />;
};

export const SpecializationAutoFill = ({data, onChange, value, placeholder, textField, idCtx}) => {
    const [fltData, setFltData] = useState([]);
    const [subData, setSubData] = useState(data);
    const [expanded, setExpanded] = useState(false);
    const [pageSkip, setPageSkip] = useState(0);
    const pageSize = 12;

    const onFilterChange = (event) => {
        let sortData;
        if (event) {
            sortData = data.filter(opt => new RegExp(event.filter.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").replace(/\s/g, ".*"), "i").test(opt.name));
        } else {
            sortData = data;
        }
        const cutData = sortData.slice(0, pageSize);

        if (!sortData.length) return;

        setFltData(sortData);
        setSubData(cutData);
        setPageSkip(0);
    };


    if (!fltData.length && data) {
        onFilterChange();
    }

    const pageChange = (event) => {
        const skip = event.page.skip;
        const take = event.page.take;
        const newSubsetData = fltData.slice(skip, skip + take);

        setSubData(newSubsetData);
        setPageSkip(skip);
    };

    idCtx += "-spec-auto-fill";

    return <KComboBox
        id={idCtx+"-k-kombo-box"}
        placeholder={placeholder}
        opened={expanded}
        onFocus={() => setExpanded(true)}
        onClose={() => setExpanded(false)}
        data={subData}
        onChange={onChange}
        value={value}
        allowCustom={false}
        filterable={true}
        onFilterChange={e => {
            setExpanded(true);
            onFilterChange(e);
        }}
        onPageChange={pageChange}
        className="phenom-combo-box"
        textField={textField}
        style={{width: "calc(100% - 25px)"}}
        virtual={{
            total: fltData.length,
            pageSize: pageSize,
            skip: pageSkip
        }}
    />;
};

export const InlinePathEditor = ({data, selectHop, opened = false, value, placeholder, textField, dataItemKey, onFilterChange, style, idCtx}) => {
    const [expanded, setExpanded] = useState(opened);
    const [someValue, setValue] = useState(null);
    idCtx += "-inline-path-editor";
    let idIndex = 0;
    return <KComboBox
        id={idCtx+"-k-combo-box"}
        onFocus={() => setExpanded(true)}
        onBlur={() => setExpanded(false)}
        opened={expanded}
        onClose={(e) => {
            selectHop(e);
            setValue(null);
        }}
        onChange={(e) => setValue(e.target._valueDuringChange)}
        placeholder={placeholder}
        data={data}
        value={someValue}
        allowCustom={false}
        filterable={true}
        onFilterChange={(e) => {
            setExpanded(true);
            onFilterChange(e);
        }}
        className="phenom-combo-box"
        textField={textField}
        dataItemKey={dataItemKey}
        style={style}
        itemRender={(li, itemProps) => {
            const {dataItem} = itemProps;
            const {xmiType} = dataItem;
            const itemChildren = <span id={idCtx+"-k-combo-"+idIndex++}>{dataItem.deprecated === "true" ? <img src={deprecatedSrc} style={{"height" : "20px"}}/> : null}{li.props.children}</span>;
            return React.cloneElement(li, li.props, itemChildren);
        }}
    />;
};

export const TagInput = ({style, data, value, onAdd, idCtx}) => {
    const [lastVal, setLastVal] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    idCtx += "-tag-input";

    return (<div onKeyUp={(e) => {
        setLastVal(e.target.value);
        if (e.keyCode === 13 && isFocused) {
            onAdd(lastVal);
        }

        if (e.keyCode === 89 && isFocused) {
            e.preventDefault();
        }

    }}>
        <KComboBox
            id={idCtx+"-k-combo-box"}
            style={style}
            data={data}
            value={value}
            onFocus={() => {
                setIsFocused(true);
            }}
            onBlur={() => {
                setIsFocused(false);
            }}
            onChange={(e) => {
                const val = e.target.value;
                if (val !== null) {
                    onAdd(val);
                }
            }}
        />
    </div>);
};

export const FormatMenuHeaderCell = ({text, useTruncation, onSort, children, idCtx}) => {
    const [popupOffset, setPopupOffSet] = useState({left: 0, top: 0});
    const [popupIsOpen, setPopupIsOpen] = useState(false);

    const handleOutsideClick = () => setPopupIsOpen(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });

    idCtx += "-format-menu-header-cell";

    return (<>
        <a className="k-link" style={{widht:"100%"}} onClick={(e) => {
            if(onSort && !popupIsOpen) { onSort(e) };
            setPopupIsOpen(false);
        }} onContextMenu={(e) => {
            e.preventDefault();
            setPopupOffSet({left: e.pageX, top: e.pageY});
            setPopupIsOpen(true);
        }}
        id={idCtx+"-link"}>
            {text}
            {children ? children[0] : null}
        </a>
        <Popup offset={popupOffset} show={popupIsOpen} id={idCtx+"-popup"}>
            <Menu vertical={true} style={{display: "inline-block"}} id={idCtx+"-menu"} onSelect={(e) => {
                switch (e.item.text) {
                    case "Truncate":
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useTruncation(true);
                        break;
                    case "Wrap":
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useTruncation(false);
                        break;
                }
                setPopupIsOpen(false);
            }}>
                <MenuItem text="Truncate" id={idCtx+"-menu-item-truncate"}/>
                <MenuItem text="Wrap" id={idCtx+"-menu-item-wrap"}/>
            </Menu>
        </Popup>
    </>);
};

export const BlockPageScroll = ({children,idCtx}) => {
    const scrollRef = useRef(null);
    const stopScroll = e => e.preventDefault();
    useEffect(() => {
        const scrollEl = scrollRef.current;
        scrollEl.addEventListener("wheel", stopScroll);
        return () => scrollEl.removeEventListener("wheel", stopScroll);
    }, []);
    idCtx += "-block-page-scroll";
    return (
        <div ref={scrollRef} id={idCtx+"-wrapper"}>
            {children}
        </div>
    );
};

/**
 * DeprecatedIcon component renders an icon indicating deprecated status.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.deprecated - Indicates if the item is deprecated. Default is "false".
 *
 * @returns {JSX.Element | null} The rendered icon if deprecated, otherwise null.
 */

export const DeprecatedIcon = ({deprecated="false"}) => {
    if (deprecated === "true") {
        return <img src={deprecatedSrc} style={{"height" : "20px"}}/>;
    } else {
        return null
    }
}

/**
 * DeprecatedBanner component displays a banner warning for deprecated items.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.deprecated - Indicates if the item is deprecated. Default is "false".
 * @param {Object} restProps - Additional props to be spread to the div element.
 *
 * @returns {JSX.Element | null} The rendered banner if deprecated, otherwise null.
 */


export const DeprecatedBanner = ({deprecated="false", ...restProps}) => {
    if (deprecated === "true") {
        return <div className="edit-deprecated" {...restProps}>WARNING: This node has been DEPRECATED</div>;
    } else {
        return null;
    }
}

export const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
        <Wrapped ref={forwardRef} {...otherProps} />
    ))
    return React.forwardRef((props, ref) => (
        <WithRouter {...props} forwardRef={ref} />
    ))
}


// ================================================
// DEPRECATED - Delete when detail pages are refactored
// ================================================
export const Card = (props) => {
    let {node, idCtx} = props;
    if (!node || !node.xmiType) return null;
    let type = node.xmiType.split(":")[1].replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    let description = node.description || "";

    idCtx+="-card";

    return <div id={idCtx+="-wrapper"} className="node-box" style={props.style}>
              <header id={idCtx+="-type"}>
                <i>{type}</i>
              </header>
              <CadetLink idCtx={idCtx} node={node} look="bare" style={{ wordWrap:"wrap" }} />
              <p>{description}</p>
              {props.children}
           </div>
}




const StyledList = styled.div`
  position: relative;
  width: ${p => p.width ? p.width + 'px' : null};
  height: ${p => p.height ? p.height + 'px' : '200px'};
  overflow-x: hidden;
  overflow-y: auto;

  table {
    width: 100%;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;

    thead {
      th {
        font-weight: 600;
        position: sticky;
        top: 0;
        padding: 0 5px;
        background: #fff;
        border-top: 1px solid #d2d2d2;
        border-bottom: 1px solid #d2d2d2;

        &:not(:first-of-type) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0 5px;
          border-bottom: 1px solid #e0e0e0;

          &:not(:first-of-type) {
            text-align: center;
          }
        }

        &:hover,
        &[data-active="true"] {
          color: rgb(16, 16, 16);
          background-color: rgb(206, 206, 206);
        }
      }
    }
  }
`

export class CadetListSelect extends React.Component {
  state = {
    selections: {},
  }

  handleClick = (option) => {
    const { idField, checkboxes } = this.props;
    const selections = {...this.state.selections};
    const id = option[idField] || option;

    if(selections[id]) {
      delete selections[id];
    } else {
      selections[id] = idField ? { [idField]: id } : true;
      if(idField && checkboxes) checkboxes.forEach((cb) => selections[id][cb.value] = true)
    }

    this.setState({ selections });
  }

  handleCheckboxClick = (e, selected_id, checkbox_key) => {
    const selection = this.state.selections[selected_id];
    if(!selection) return;
    selection[checkbox_key] = e.target.checked;
    this.forceUpdate();
  }

  getSelections = () => {
    if(this.props.idField) {
      return Object.values(this.state.selections)
    } else {
      return Object.keys(this.state.selections);
    }
  }

  render() {
    const { data, title, textField, idField, width, height, checkboxes, style, blankSelectionMessage } = this.props;
    const { selections } = this.state;
    return (
      <StyledList style={style} width={width} height={height}>
        <table>
          <thead>
            <tr>
              <th>{title}</th>
              {checkboxes && checkboxes.map(cb => (
                <th>
                  {cb.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((option) => {
              const id = option[idField] || option;
              const text = option[textField] || option;
              const selected = selections[id];

              return (
                <tr key={`selection-${id}`}
                    data-active={!!selected}
                    onClick={() => this.handleClick(option)}>
                  <td>{text}</td>
                  {checkboxes && checkboxes.map(cb => {
                    return <td key={`selection-${id}-${cb.value}`}>
                              {selected &&
                              <input type="checkbox"
                                          checked={selected[cb.value]}
                                          onChange={(e) => this.handleCheckboxClick(e, id, cb.value)}
                                          onClick={(e) => e.stopPropagation()} />}
                          </td>
                  })}
              </tr>
            )})}
          </tbody>
        </table>
      </StyledList>
    )
  }
}



const SelectBox = styled.select`
    height: ${props => props.collapse ? "0 !important" : "200px !important"};
    visibility: ${props => props.collapse ? "hidden" : "visible"};
    margin-bottom: ${props => props.collapse ? "0" : "10px"};
    text-align: center;
    color: #409b93de;
    max-width: 600px;
    transition: 300ms ease-out;
    transition-property: height, visibility;
    border: ${props => props.collapse ? "none" : props.error ? "1px solid red" : "1px solid #999"};
    & option {
        padding: 5px 0;
        &:checked,
        &:hover {
            color: #f2f2f2;
	        background-color: #1E90FF !important;
        }
        &:focus { outline: none; }
    }

    // firefox
    scrollbar-color: #409b93de #e0e0e0;
    scrollbar-width: thin;

    // chrome
    &::-webkit-scrollbar {
        width: 20px;
    }
    &::-webkit-scrollbar-track {
        background: #e0e0e0;
    }
    &::-webkit-scrollbar-thumb {
        background: #409b93de;
        &:hover { background: #4bc5bade; }
    }
`


export class SingleSelect extends React.Component {
    state = {
        // data: {},
        // selectedGuid: "",
        // editable: true,
        collapse: true,
    }

    componentDidMount() {
        this.setState({
            collapse: this.props.collapse,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.collapse !== this.props.collapse) {
            this.setState({collapse: this.props.collapse});
        }
    }

    gridStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(3, 33%)",
    }

    toggleCollapse = () => {
        this.setState((prevState) => ({collapse: !prevState.collapse}));
    }

    // TODO: REMOVE
    generateGuid = () => {
        return this.state.selectedGuid;
    }

    render() {
        const idCtx = this.props.idCtx+"-single-select";
        return (<SelectBox collapse={this.state.collapse}
                           value={this.props.selectedGuid}
                           size={6}
                           required={this.props.required}
                           disabled={!this.props.editable}
                           id={idCtx+"-box"}
                           onChange={this.props.onChange}>
                                {!this.props.data ?
                                    <option value="" disabled={true}>Loading...</option>
                                : <>
                                    <option value="" disabled={this.props.required}>
                                        {this.props.optionNoneText ? this.props.optionNoneText :
                                        this.props.required ? "Choose One" : "None"}
                                    </option>
                                {Object.values(this.props.data).sort((a,b) => sortNodesByName(a,b)).map(node => {
                                    return(<option value={node.guid}
                                                    onSelect={this.props.onClick}
                                                key={node.guid}>
                                                    {node.name}
                                            </option>)})}</>}
                </SelectBox>);
    }
}


export class MultiSelect extends React.Component {
    state = {
        data: {},
        selectedGuids: [],
        collapse: true,
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            selectedGuids: this.props.selectedGuids || [],
            collapse: this.props.collapse
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.data !== this.props.data) {
            this.setState({data: this.props.data});
        }

        if(prevProps.selectedGuids !== this.props.selectedGuids) {
            this.setState({selectedGuids: this.props.selectedGuids || []});
        }

        if(prevProps.collapse !== this.props.collapse) {
            this.setState({collapse: this.props.collapse});
        }
    }

    gridStyle = {
        display: "grid",
        gap: "10px",
        gridTemplateColumns: "repeat(3, 33%)",
    }

    generateGuids = () => {
        return this.state.selectedGuids.join(" ");
    }

    generateNodes = () => {
        return this.state.selectedGuids.reduce((arr, guid) => {
            if(this.state.data[guid]) arr.push(this.state.data[guid]);
            return arr;
        }, [])
    }

    toggleCollapse = () => {
        this.setState((prevState) => ({collapse: !prevState.collapse}));
    }

    selectionToggle = (e) => {
        e.preventDefault();

        if(e.target.value) {
            // remove if it exists
            if (this.state.selectedGuids.indexOf(e.target.value) > -1) {
                var newSelectedGuids = this.state.selectedGuids.filter(guid => guid !== e.target.value);

            // push if it doesn't exist (this does not work if there's only one item remaining)
            } else {
                var newSelectedGuids = [...this.state.selectedGuids, e.target.value];
            }

            e.target.blur();

            this.setState({selectedGuids: newSelectedGuids, error: false});
            if(this.props.onChange) this.props.onChange(newSelectedGuids);
        }
    }

    removeLastSelection = (e) => {
        if (this.state.selectedGuids.length === 1 && this.state.selectedGuids.indexOf(e.target.value) > -1) {
            e.preventDefault();
            this.setState({selectedGuids: []});
            if(this.props.onChange) this.props.onChange([]);
        }
    }

    render() {
        const idCtx = this.props.idCtx + "-multi-select";
        const isNodes = this.props?.data ? Object.values(this.props.data).every(ele => ele.name) : [];
        return (<SelectBox collapse={this.state.collapse}
                        id={this.props.idCtx+"-box"}
                        value={this.state.selectedGuids || []}
                        multiple={true}
                        size={6}
                        required={this.props.required}
                        disabled={!this.props.editable}
                        onChange={this.selectionToggle}>
                            {!this.props.data ?
                                <option value="" disabled={true}>Loading...</option>
                                : <>
                                    {Object.values(this.props.data).sort((a,b) => isNodes ? sortNodesByName(a,b) : a < b).map(node => {
                                    return(<option value={node.guid}
                                                key={node.guid}
                                                onMouseDown={this.removeLastSelection}>
                                        {node.name}
                                    </option>)})}
                            </>}
                </SelectBox>);
    }
}



export const DropdownSelect2 = (props) => {
    let style = { width:"100%", marginBottom:10, ...props.style };
    let errorStyle = {};

    if (props.error) {
        errorStyle = {border: "1px solid red"};
    }
    const idCtx = props.idCtx + "dropdown-select-v2";
    return (
        <select className="cadet-select" style={{...style, ...errorStyle}}
                id={idCtx}
                value={props.selectedGuid || ""}
                disabled={!props.editable}
                onChange={props.onChange}>
                    {!props.data ?
                        <option value="" disabled={true}>Loading...</option>
                    : <>
                        <option value="" disabled={props.required}>
                            {props.noValueName ? props.noValueName :
                                props.required ? "Choose One" : "None"
                            }
                        </option>
                        {Object.values(props.data).sort((a,b) => sortNodesByName(a,b)).map((node, idx) => {
                            return (<option value={node.guid} key={node.guid} id={idCtx+`${idx}-option`}>
                                        {node.name}
                                    </option>); })}
                    </>}
        </select>
    )
}

/**
 * CommaLinks component renders a list of comma-separated links.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.data - Array of data items to render as links.
 * @param {string} props.idCtx - Identifier context for unique IDs.
 * @param {Object} [props.style] - Additional CSS styles for the wrapper div.
 *
 * @returns {JSX.Element | null} The rendered comma-separated links if data is provided, otherwise null.
 */

export const CommaLinks = (props) => {
    if(!props.data) return null;
    let style = { marginBottom:15, ...props.style };
    const idCtx = props.idCtx + "-comma-links"
    return(
        <div style={style} id={idCtx+"-wrapper"}>
            {props.data.map((ele, idx, arr) => {
                return (<>
                    <CadetLink node={ele} style={{fontSize:"100%"}} idCtx={idCtx+`-${idx}`}/>
                    {idx < arr.length - 1 ? <span>, </span> : null}
                </>)
            })}
        </div>
    );
}

/**
 * BoundsPicker component for selecting and displaying lower and upper bounds.
 *
 * @class BoundsPicker
 * @extends React.Component
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.lowerSelector="lowerBound"] - The selector for the lower bound property.
 * @param {string} [props.upperSelector="upperBound"] - The selector for the upper bound property.
 * @param {boolean} props.editableBounds - Flag indicating if bounds are editable.
 * @param {Object} props.ele - The element containing bound properties.
 * @param {Function} props.setBound - Callback function to set bounds.
 * @param {boolean} props.disabled - Flag indicating if bounds are disabled.
 * @param {string} props.idCtx - Identifier context for unique IDs.
 *
 * @returns {JSX.Element} Rendered BoundsPicker component.
 */

export class BoundsPicker extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
         lower:null,
         upper:null,
         lowerSelector: "lowerBound",
         upperSelector: "upperBound",
         editBound: false,
      }
  }

  componentDidMount() {
      const lowerSelector = this.props.lowerSelector || "lowerBound";
      const upperSelector = this.props.upperSelector || "upperBound";
      this.setState({
          lowerSelector: lowerSelector,
          upperSelector: upperSelector,
          lower: this.convertBoundValue(this.props.ele[lowerSelector]),
          upper: this.convertBoundValue(this.props.ele[upperSelector]),
      })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.ele[this.state.lowerSelector] !== this.props.ele[this.state.lowerSelector]) {
      this.setState({lower: this.convertBoundValue(this.props.ele[this.state.lowerSelector])});
    }
    if(prevProps.ele[this.state.upperSelector] !== this.props.ele[this.state.upperSelector]) {
      this.setState({upper: this.convertBoundValue(this.props.ele[this.state.upperSelector])});
    }
    if(prevProps.editableBounds !== this.props.editableBounds && !this.props.editableBounds) {
      ReactTooltip.rebuild();
    }
  }
  /**
     * Converts bound value for display.
     *
     * @param {string|number|null} val - The bound value to convert.
     * @returns {string} The converted bound value for display.
     */
  convertBoundView = (val) => {
    switch (val) {
      case -1:
        return "*";
        break;
      case 0:
      case NaN:
        return "0";
        break;
      case null:
      case "":
        return "";
        break;
      default:
        return val;
    }
  }
  /**
     * Converts bound value for internal use.
     *
     * @param {string|number|null} val - The bound value to convert.
     * @returns {number|null} The converted bound value for internal use.
     */
  convertBoundValue = (val) => {
    switch (val) {
      case "*":
        return -1;
        break;
      case "0":
      case NaN:
        return 0;
        break;
      case null:
      case "":
        return null;
        break;
      default:
        return Number(val);
    }
  }


  // needs 0 checks and *
  /**
     * Checks and sets the bound value.
     *
     * @param {string} val - The bound value to set.
     * @param {string} target - The target bound ("lower" or "upper").
     */
  checkAndSet = (val, target) => {
    val = this.convertBoundValue(val);

    let lower = this.convertBoundValue(target === "lower" ? val : this.state.lower);
    let upper = this.convertBoundValue(target === "upper" ? val : this.state.upper);
    if (val || val === 0 || val === null) {
      if (val === null || lower === -1){
        lower = null;
        upper = null;
      } else if(target === "lower" && lower > upper && upper != -1) {
        upper = lower;
      } else if (target === "upper" && lower > upper && upper != -1) {
        lower = upper;
      } else if (lower === null) {
        lower = 0;
      }

      this.setState({lower: lower, upper: upper},
          () => this.props.setBound(lower, upper, this.state.lowerSelector, this.state.upperSelector))
    }
  }
  /**
     * Handles change event for input fields.
     *
     * @param {Event} e - The change event.
     * @param {string} target - The target field ("lower" or "upper").
     */
  onChange = (e, target) => {
    const val = e.target.value

    this.setState({ [target]:val });
  }
  /**
     * Handles focus event for input fields.
     *
     * @param {string} target - The target field ("lower" or "upper").
     */
  onFocus = (target) => {
    const val = ""

    this.setState({ [target]:val });
  }

    render() {
      const { editableBounds } = this.props;

      const idCtx = this.props.idCtx+"-bounds-picker";
      return(
        <div className="flex-h" id={idCtx+"-wrapper"} style={{padding:"0 5px"}}>
          {this.state.editBound ? <>
            <div className="flex-h" style={{padding:"0 5px"}}>
            <div>↓</div>
            <CadetInput
                      idCtx={idCtx+"-lower-bound"}
                      text={this.convertBoundView(this.state.lower)}
                      onChange={(e) => this.onChange(e,"lower")}
                      onFocus={(e) => this.onFocus("lower")}
                      onBlur={(e) => this.checkAndSet(e.target.value, "lower")}
                      disabled={this.props.disabled}/>
          </div>
          <div> .. </div>
          <div className="flex-h" style={{padding:"0 5px"}}>
            <div>↑</div>
            <CadetInput
                      idCtx={idCtx+"-upper-bound"}
                      text={this.convertBoundView(this.state.upper)}
                      onChange={(e) => this.onChange(e,"upper")}
                      onFocus={(e) => this.onFocus("upper")}
                      onBlur={(e) => this.checkAndSet(e.target.value, "upper")}
                      disabled={this.props.disabled}/>
          </div></>
          : <div style={{flex:1, textAlign:"center"}}><span>{this.convertBoundView(this.state.lower)} .. {this.convertBoundView(this.state.upper)}</span></div>}
          {this.props.disabled || <span style={{cursor:"pointer"}}
              className={this.state.editBound ? "k-icon k-i-check" : "k-icon k-i-pencil"}
              onClick={() => this.setState({editBound: !this.state.editBound})}
              id={idCtx+"-display-toggle-span"}/>}
          {!editableBounds && (this.state.upper || this.state.lower) && <>
            <span className="fas fa-warning"
                  style={{paddingLeft: 3, color: "red", margin: "2px 0 0 0"}}
                  data-tip
                  data-for="boundsError"
                  data-place="left"/>
            <ReactTooltip id='boundsError'>
                        <span>This field cannot be bound.</span>                      
            </ReactTooltip>
          </>}
        </div>
      )
    }
}

/**
 * ConstraintTable component for displaying constraint details in a table format.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.constraint - The constraint object containing details to display.
 * @param {string} props.idCtx - Identifier context for unique IDs.
 *
 * @returns {JSX.Element|null} Rendered ConstraintTable component or null if constraint is not provided.
 */

export const ConstraintTable = (props) => {
    const constraint = props.constraint || null;
    const idCtx = props.idCtx+"-constraint-table"
    if (constraint) {
        return (<table className="block-constraint-table" id={idCtx+"-wrapper"}>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td style={{fontSize: "90%"}}>{constraint.name}</td>
                    </tr>
                    {!constraint.description || <tr>
                            <td>Description</td>
                            <td style={{fontSize: "90%"}} id={idCtx+"-description"}>{constraint.description}</td>
                        </tr>
                    }
                    {!constraint.expression || <tr>
                        <td>RegEx Expression</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-expression"}>{constraint.expression}</td>
                    </tr>}
                    {(constraint.upperBound || constraint.upperBound === 0) && <tr>
                        <td>Upper Bound</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-upper-bound"}>{constraint.upperBound}</td>
                    </tr>}
                    {(constraint.lowerBound || constraint.lowerBound === 0) && <tr>
                        <td>Lower Bound</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-lower-bound"}>{constraint.lowerBound}</td>
                    </tr>}
                    {constraint.upperBoundInclusive && <tr>
                        <td>Upper Bound Inclusive</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-inclusive-upper-bound"}>{constraint.upperBoundInclusive}</td>
                    </tr>}
                    {constraint.lowerBoundInclusive && <tr>
                        <td>Lower Bound Inclusive</td>
                        <td style={{fontSize: "90%"}} id={idCtx+"-inclusive-lower-bound"}>{constraint.lowerBoundInclusive}</td>
                    </tr>}
                </tbody>
            </table>)
    } else {
        return null;
    }
}

export class ProjectSelection extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      selectedProject: null,
      newProjectOpt: "ws", // either "perms" or "ws"
      perms: "awr",
    }
  }

  generateProjectData() {
    const { selectedProject, newProjectOpt, perms } = this.state;
    return {
      selectedProject,
      personalWs: newProjectOpt === "ws",
      perms,
    }
  }
  
  permissionOpts = [
    {val: "awr", txt: "Admin / Write / Read"},
    {val: "ar", txt: "Admin / Read"},
    {val: "wr", txt: "Write / Read"},
    {val: "r", txt: "Read"}
  ];
  publishedPermissionOpts = [
    {val: "ar", txt: "Admin / Read"},
    {val: "r", txt: "Read"}
  ];

  render() {
    const { models } = this.props;
    const { selectedProject, newProjectOpt, perms } = this.state;
    const phenomId = new PhenomId(this.props.id, "");

    return <>
      <PhenomComboBox data={models}
                      id={phenomId.gen("")}
                      dataItemKey="name"
                      value={selectedProject}
                      textField="name"
                      onChange={(model) => this.setState({selectedProject: model})} />
      <ul style={{ padding: 0, margin:0, listStyle:"none"}}>
        <li style={{marginBottom:10}}>
          <RadioButton id={phenomId.gen("personal", "copy")}
                       checked={newProjectOpt === "ws"}
                       onChange={() => this.setState({newProjectOpt: "ws"})} />
          <label htmlFor={phenomId.gen("personal", "copy")}
                 className="k-radio-label"
                 style={{ 
                   color: "#565656",
                   fontWeight: "normal"
                 }}>Create personal copy with Admin permissions</label>
        </li>
        <li>
          <RadioButton id={phenomId.gen("share", )}
                       checked={newProjectOpt === "perms"}
                       onChange={() => this.setState({newProjectOpt: "perms"})} />
          <label htmlFor={phenomId.gen("share", )}
                 className="k-radio-label"
                 style={{ 
                   color: "#565656",
                   fontWeight: "normal"
                 }}>Share with</label>
          <DropDownList id={phenomId.gen("permission", "select")}
                        style={{margin:10, width: 175}}
                        data={selectedProject?.created ? this.publishedPermissionOpts : this.permissionOpts}
                        textField="txt"
                        dataItemKey="val"
                        value={this.permissionOpts.find((opt) => perms === opt.val)}
                        onChange={(e) => {
                          this.setState({ 
                            perms: e.value?.val,
                            newProjectOpt: "perms",
                          });
                        }} />
          <label htmlFor={phenomId.gen("share", )}
                 style={{ 
                   color: "#565656",
                   fontWeight: "normal"
                 }}>permissions</label>
        </li>
      </ul>
    </>
  }
}
