import {AnchoredPath, FauxAutofill, LineLabel} from "./stateless";
import React from "react";
import $ from "jquery";
import {modelTypesOfNode} from "../../requests/sml-requests";
import {deGuidify, isPhenomGuid} from "./util";
import {NavLink} from "react-router-dom";
import deprecated from "../../images/deprecated_hover.png";
import {clone, cloneDeep} from "lodash";
import PhenomId from "../../requests/phenom-id";
import { _ajax } from "../../requests/sml-requests";



export class Path extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            builder: "",
            path: "",
            pathPairs: [],
            hopOptions: {},
            measurement: {},    // charPath
            pathHead: this.props.pathHead, // charPath
            parentTypeGuid: null,  // aePath
            parentAEGuid: null,     // aePath
        };

        this.fauxRef = React.createRef();
    }

    componentDidMount() {
        this.setState({builder: this.props.builder || "charPath", ...this.props})
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.builder !== prevProps.builder) {
            this.setState({builder: this.props.builder || "charPath"});
        }

        switch(this.state.builder) {
            case "charPath":
                // measurement guid can change to phenom-guid then the backend fails to find it
                //      non-phenom guid measurement is stored in the state.
                if (this.props.measurement?.guid && !isPhenomGuid(this.props.measurement.guid) && this.props.measurement !== prevProps.measurement) {
                    this.setState({ measurement: this.props.measurement });
                }
                
                if (this.props.measurement.realizes !== prevProps.measurement.realizes) {
                    if (!this.props.measurement.realizes) {
                        this.setState({ pathPairs: [], hopOptions: {} });
                    } else if (!(!prevProps.pathPairs.length && this.props.pathPairs.length) || (!this.props.pathPairs.length)) {
                        this.setState({ pathPairs: [], hopOptions: {} }, () => {
                            modelTypesOfNode([this.props.measurement.guid]).then((res) => {
                                const matches = res.data.matches;
                                this.setState({
                                    hopOptions: !!matches ? deGuidify(matches) : {},
                                });
                            });
                        });
                    }
                }
                if (this.state.pathPairs !== prevState.pathPairs && this.state.measurement?.guid && (this.state.measurement.realizes || this.state.pathPairs[0])) {
                    const pathGuids = this.state.pathPairs.length ? this.state.pathPairs.map(pair => pair.guid) : [this.state.measurement.guid];
                    modelTypesOfNode(pathGuids).then((res) => {
                        const matches = res.data.matches;
                        this.setState({hopOptions: !!matches ? deGuidify(matches) : {},});
                    });
                }
                if (this.props.pathHead && !this.state.pathHead && prevProps.pathHead !== this.props.pathHead && this.props.pathHead !== undefined) {
                    this.setState({
                        pathHead: this.props.pathHead,
                        pathIsValid: this.props.pathIsValid
                    });
                }
            break;

            case "aePath":
                if (this.props.pathPairs !== prevProps.pathPairs ||
                    this.props.parentTypeGuid !== prevProps.parentTypeGuid ||
                    this.props.parentAEGuid !== prevProps.parentAEGuid) {
                        this.setState({
                            pathPairs: cloneDeep(this.props.pathPairs),
                            parentTypeGuid: this.props.parentTypeGuid ,
                            parentAEGuid: this.props.parentAEGuid
                        });
                }

                if (this.state.pathPairs !== prevState.pathPairs) {
                    this.fetchHopOptions();
                }
            break;
        }


        if (this.props.pathPairs !== prevProps.pathPairs) {
            this.setState({ pathPairs: Array.from(this.props.pathPairs) });
        }

        if (this.state.pathPairs !== prevState.pathPairs) {
            // reset FauxAutofill's search text
            this.fauxRef.current && this.fauxRef.current.clearSearch();
        }

    }

    // Used by aePath
    fetchHopOptions() {
        this.setState({hopOptions: []});
        const lastHop = this.state.pathPairs[this.state.pathPairs.length - 1];

        if (lastHop && lastHop.type.xmiType === "conceptual:Observable") {
            this.setState({loadingHops: false});
            return;
        }

        const assocGuid = this.state.parentTypeGuid;
        const pathedAE = this.state.parentAEGuid;
        const pathGuids = this.state.pathPairs.map(pathPair => pathPair.guid);

        _ajax({
            url: "/index.php?r=/compassoc/get-assoc-ent-path-options/",
            method: "get",
            data: {assocGuid, pathGuids, pathedAE},
        }).then(response => {
            // response.hopOptions.forEach(hopOption => {
            //     if (hopOption.connector === ".") {
            //         hopOption.pathedName = "." + hopOption.rolename;
            //     } else if (hopOption.connector === "->") {
            //         hopOption.pathedName = "->" + hopOption.rolename + "[" + hopOption.parent.name + "]";
            //     } else {
            //         throw "One of the hop options connectors is corrupted.";
            //     }
            // });
            this.setState({hopOptions: deGuidify(response.data.hopOptions), loadingHops: false});
        });
    }



    generateHopOptions() {

        let deprecated = "false"
        let allOptions = {0: [], 1: [], 2: []};
        // {0: comps 1: hopIns 2: hopOuts}
        let text = {
            0: (hop) => `${hop.parent.name}:${hop.rolename}`,
            1: (hop) => `${hop.rolename} ← ${hop.parent.name}`,
            2: (hop) => `${hop.type.name} → ${hop.rolename}[${hop.parent.name}]`
        };
        Object.values(this.state.hopOptions).forEach((hop) => {
            let category;

            if (hop.xmiType === "conceptual:Composition" || this.state.pathPairs.length === 0) {
                category = 0;
            } else {
                const prospectivePathPairs = Array.from(this.state.pathPairs);
                switch(this.state.builder) {
                    case "charPath":
                        prospectivePathPairs.unshift(hop);
                        break;
                    case "aePath":
                        prospectivePathPairs.push(hop);
                        break;
                }
                category = (this.goingUp(0, prospectivePathPairs)) ? 2 : 1;
            }

            switch (category) {
              case 0:
              case 1:
                deprecated = (hop.parent.deprecated === "true" || hop.deprecated === "true");
                break;
              case 2:
                 deprecated = (hop.type.deprecated === "true" || hop.parent.deprecated === "true" || hop.deprecated === "true");
                break;
              default:
                deprecated = false;
            }
            allOptions[category].push({text: text[category](hop), value: hop.guid, deprecated: deprecated});
        });
        let res = [];
        Object.values(allOptions).forEach((optionList) => {
            res = res.concat(optionList.sort((a, b) => a.text.localeCompare(b.text)));
        });
        return res;
    }

    // used by charPath
    goingUp(startingIdx, pathGuids = this.state.pathPairs) {
        const latestHop = pathGuids[startingIdx];
        const sLatestHop = pathGuids[startingIdx + 1];

        if (!sLatestHop) return false;

        let latestTyperGuid;
        let sLatestTyperGuid;

        try {
            latestTyperGuid = latestHop.type.guid;
            sLatestTyperGuid = sLatestHop.type.guid;
        } catch (err) {
            throw err;
        }

        if (latestTyperGuid !== sLatestTyperGuid) {
            if (sLatestHop.xmiType === "conceptual:Composition") {
                return sLatestHop.parent.guid === latestHop.parent.guid;
            } else {
                if (latestHop.parent.guid === sLatestHop.parent.guid) {
                    return !(this.goingUp(startingIdx + 1, pathGuids));
                } else {
                    return (this.goingUp(startingIdx + 1, pathGuids));
                }
            }
        } else {
            if (sLatestHop.xmiType === "conceptual:Composition") {
                return true;
            } else {
                return !(this.goingUp(startingIdx + 1, pathGuids));
            }
        }
    }

    selectHop = hopGuid => {
        const newHop = this.state.hopOptions[hopGuid];
        let pathPairs = [];

        switch (this.state.builder) {
            case "charPath":
                pathPairs = [ newHop, ...this.state.pathPairs ];
                break;
            case "aePath":
                pathPairs = [ ...this.state.pathPairs, newHop ];
                break;
            default:
        }

        this.setState({
            pathPairs,
            pathHead: null,
            pathIsValid: true,
        });
    };

    popHop = () => {
        const newPathPairs = Array.from(this.state.pathPairs);
        switch(this.state.builder) {
            case "charPath":
                newPathPairs.shift();
                break;
            case "aePath":
                newPathPairs.pop();
                break;
        }
        this.setState({
            pathPairs: newPathPairs,
            hopOptions: {},
            pathHead: undefined,
            pathIsValid: true,
        });
    };

    clearPath = () => {
        this.setState({pathPairs: [], hopOptions: {}, pathHead: undefined});
    };

    resetPath = () => {
        this.setState({pathPairs: this.props.pathPairs, pathHead: this.props.pathHead});
    };

    generatePath() {
        let response = "";
        this.state.pathPairs.forEach((pair, idx, pairs) => {
            if (idx === 0) {
                if (!pairs[1] || !this.goingUp(idx)) {
                    response = pair.parent.name;
                } else {
                    response = pair.type.name;
                }
            }
            if (!pairs[idx + 1] || pair.xmiType === "conceptual:Composition" || !this.goingUp(idx)) {
                response += `.${pair.rolename}`;
            } else {
                response += `->${pair.rolename}[${pair.parent.name}]`;
            }
        });
        return response;
    }

    reportPathHead() {
        if(this.state.pathPairs.length === 0) {
            return {};
        } else if(this.state.pathPairs[1] && this.goingUp(0)){
            return this.state.pathPairs[0].type;
        } else {
            return this.state.pathPairs[0].parent;
        }
    }

    reportPath() {
        return this.state.pathPairs.map(pair => pair.guid).join(" ");
    }

    render() {
        const phenomId = new PhenomId("path",this.props.idCtx);
        // charPath goes UP
        // aePath goes DOWN
        let pathPairs = [];
        switch(this.state.builder) {
            case "charPath":
                pathPairs = this.state.pathPairs;
                break;
            case "aePath":
                pathPairs = cloneDeep(this.state.pathPairs).reverse();
                break;
        }


        return (
            <div style={{marginTop: 25, marginBottom: 15}} id={phenomId.gen("","wrapper")}>
                <LineLabel text={this.props.pathLabel || 'Path'} idCtx={phenomId.gen("","")}/>
                <div style={{padding: "4px 0px 19px 3px"}}><AnchoredPath pathHead={this.state.pathHead}
                                                                         pathPairs={this.state.pathPairs}
                                                                         pathIsValid={this.state.pathIsValid}
                                                                         fullError={true}
                                                                         idCtx={phenomId.gen("","")}/></div>

                {pathPairs.map((pathPair, idx) => {
                    return (<div className="path-detail" style={{position: "relative"}} key={idx} id={phenomId.gen(["init",`path-pair-${idx}`],"wrapper")}>
                        <div className="column40">
                            <div className="path-component" id={phenomId.gen("","name-div")}>{pathPair.rolename}</div>
                            <div className="path-comp-desc" id={phenomId.gen("","description-div")}>{pathPair.description}</div>
                        </div>
                        <div className="column20"><p style={{fontSize: "80%", textAlign: "center"}} id={phenomId.gen("in","wrapper")}>IN</p></div>
                        <div className="column40">
                            <div className="path-component">
                                {pathPair.parent.deprecated === "true" ? <img src={deprecated} style={{"height" : "20px"}}/> : null}
                                <NavLink
                                    className="cadet-anchor"
                                    style={{fontSize: "100%"}}
                                    to={`/edit/details/entity/${pathPair.parent.guid}/`}
                                    id={phenomId.gen("","parent-link")}>
                                    {pathPair.parent.name}
                                </NavLink>
                            </div>
                            <div className="path-comp-desc" id={phenomId.gen("","parent-description")}>{pathPair.parent.description}</div>
                        </div>
                        {!idx && !this.props.disabled &&
                        <button className="filled-button" className="delete-path-hop" onClick={this.popHop}
                          id={phenomId.gen("","delete-path-hop-button")}><span>×</span>
                        </button>
                        }
                    </div>);
                })}
                {!this.props.disabled && <FauxAutofill
                    style={{position: "relative"}}
                    listStyle={{bottom: 53, height: "200px", width: "100%", left: -1}}
                    clearStyle={{bottom: 25, right: 3}}
                    options={this.generateHopOptions()}
                    onSelect={this.selectHop}
                    text=""
                    ref={this.fauxRef}
                    idCtx={phenomId.gen("init","")}
                />}
                {!this.props.disabled && <div className="flex-h" style={{justifyContent: "flex-end", alignItems: "center"}}>
                    <button onClick={this.resetPath} title="Reset Path" className="reset2" id={phenomId.gen("init","reset-path-button")}></button>
                    <button onClick={this.clearPath} title="Clear Path" className="clear2" id={phenomId.gen("init","clear-path-button")}></button>
                </div>}
                <div style={{ fontSize: 14, fontStyle: "italic", padding: "5px 10px", border: "1px solid lightgray", marginTop: 12 }}>
                    It is no longer necessary to click the checkbox to add a path hop to the path. Once the hop is selected, it is automatically added to the path. If you make a selection in error, you may remove the selected path hop by clicking the x in the top right corner of that path hop box.
                </div>
            </div>
        );
    }
}




export class InlinePathBuilder extends React.Component {
  phenomId = new PhenomId("inline-path-builder", this.props.idCtx);
  state = {
    isForwardBuilder: false,
    pathHead: null,
    pathPairs: [],
    hopOptions: [],
    measurementGuid: null,    // backend uses measurement to fetch observable -> can be refactored to take observable guid instead

  }

  componentDidMount() {
    this.modifyPathPairs( cloneDeep(this.props.pathPairs || [] ));
  }

  componentDidUpdate(prevProps, prevState) {
    const { isForwardBuilder, pathPairs, measurementGuid } = this.props;

    if (pathPairs !== prevProps.pathPairs) {
      this.modifyPathPairs( cloneDeep(pathPairs || [] ));
    }

    if (this.state.pathPairs !== prevState.pathPairs) {
      if (isForwardBuilder) {
        this.fetchForwardHopOptions();
      } else {
        this.fetchBackwardHopOptions();
      }
    }
  }

  /**
   * Sets up "going up" status for each hop
   * 
   * @param {array} pathPairs 
   */
  modifyPathPairs = (pathPairs=[]) => {
    pathPairs.forEach((pair, idx) => {
      const isGoingUp = this.goingUp(idx, pathPairs);
      pair["goingUp"] = isGoingUp;
      pair["connector"] = isGoingUp ? "->" : ".";
    })

    const firstHop = pathPairs[0];
    const pathHead = firstHop?.goingUp ? firstHop.type : firstHop?.parent;
    console.log(firstHop)
    this.setState({ pathHead, pathPairs });
  }

  goingUp = (idx, pathPairs=[]) => {
    const latestHop = pathPairs[idx];
    const sLatestHop = pathPairs[idx + 1];

    if (!latestHop || !sLatestHop || latestHop.xmiType === "conceptual:Composition") {
      return false;
    }

    const latestParentGuid = latestHop.type?.guid;
    const sLatestParentGuid = sLatestHop.type?.guid;

    if (!latestParentGuid || !sLatestParentGuid) {
      console.error("invalid path pair format");
      return false;
    }

    const latestTyperGuid = typeof latestHop.type === 'string' ? latestHop.type : latestHop.type?.guid;
    const sLatestTyperGuid = typeof sLatestHop.type === 'string' ? sLatestHop.type : sLatestHop.type?.guid;

    if (latestTyperGuid !== sLatestTyperGuid) {
      if (sLatestHop.xmiType === "conceptual:Composition") {  
        return latestParentGuid === sLatestParentGuid;
      } else {
        if (latestParentGuid === sLatestParentGuid) {
          return !this.goingUp(idx + 1, pathPairs);
        } else {
          return this.goingUp(idx + 1, pathPairs);
        }
      }
    } else {
      if (sLatestHop.xmiType === "conceptual:Composition") {
        return true;
      } else {
        return !this.goingUp(idx + 1, pathPairs);
      }
    }
  }

  /**
   * Fetch hop options in reverse order (starting with the observable)
   *    note: backend uses measurementGuid to fetch observable. 
   *          this can be refactored to receive the observable guid instead
   * 
   */
  fetchBackwardHopOptions = async () => {
    const { measurementGuid } = this.props;
    const { pathPairs } = this.state;
    let requestGuids = [];

    if (pathPairs.length) {
      requestGuids = pathPairs.map(pair => pair.guid);
    } else if (measurementGuid) {
      requestGuids = [measurementGuid];
    }

    // invalid request
    if (!requestGuids.length) {
      return this.setState({ hopOptions: [] });
    }

    modelTypesOfNode(requestGuids).then(res => {
      const hopOptions = res.data.matches || [];

      hopOptions.forEach(hop => {
        const prospectivePathPairs = [ hop, ...this.state.pathPairs ];
        const isGoingUp = this.goingUp(0, prospectivePathPairs);
        hop["goingUp"] = isGoingUp;
        hop["connector"] = isGoingUp ? "->" : ".";
      })

      this.setState({ hopOptions });
    })
  }

  fetchForwardHopOptions = () => {

  }

  reset = () => {
    this.setState({
      pathHead: null,
      pathPairs: [],
      hopOptions: [],
    })
  }

  render() {
    const { pathHead, pathPairs, hopOptions } = this.state;
    console.log( pathHead );
    console.log( pathPairs );
    console.log( hopOptions );

    return null;
  }
}